<template>
  <div class="success">
    <slot>
      <SuccessIcon class="successIcon" />
    </slot>
    <h2 v-if="!readOnlyMode">{{ t.successHeading ?? defaultMessages.heading }}</h2>
    <h2 v-else>{{ t[leadType]?.viewOnlyHeading ?? t[leadType]?.heading }}</h2>
    <p v-if="!readOnlyMode">{{ t.successMessage ?? defaultMessages.message }}</p>
    <p v-else>{{ t.viewOnlyMessage ?? defaultMessages.viewOnlyMessage }}</p>
  </div>
</template>

<script>
import SuccessIcon from '../../assets/check.svg'
export default {
  name: 'SuccessMessage',
  components: { SuccessIcon },
  props: {
    translationNode: { type: Object, default: () => {} },
    leadType: { type: String, default: '' },
    readOnlyMode: { type: Boolean, default: false }
  },
  computed: {
    t() {
      return this.translationNode ?? {}
    },
    defaultMessages() {
      return {
        heading: 'Thank you for your request',
        message: 'I wish to receive any marketing information or have my information shared with third parties for purposes of providing me with marketing information.',
        viewOnlyMessage: 'A new lead was collected and you should have received an email with the details.'
      }
    }
  }
}
</script>

<style>
.success {
  text-align: center;
  padding: 0px;
  border-radius: 10px;
  max-width: 400px;
  margin: 2rem auto;

  h2 {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    max-height: 22px;
  }

  p {
    font-weight: normal;
  }

  svg {
    width: 4rem;
  }
}
</style>
