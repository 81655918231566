<template>
  <form v-if="fields" ref="tradeInForm" class="tradeInForm" @submit.prevent="createTradeIn">
    <Loader v-if="loading || localLoading" />
    <h3>{{ t.tradeInModalTitle ?? 't.tradeInModalTitle' }}</h3>
    <p>{{ t.tradeInModalSubtitle ?? 't.tradeInModalSubtitle' }}</p>
    <div v-if="licensePlateLookupField">
      <div class="licensePlateField">
        <div class="seachField">
          <input type="text" v-model="tradeInInfoModel.licensePlate" />
          <button type="button" @click="lookupLicensePlate"><SearchIcon /></button>
        </div>
        <div class="note">
          <InfoIcon />
          {{ t.searchNote ?? 't.searchNote' }}
        </div>
      </div>
      <div v-if="licensePlateLookupField.fields">
        <FormTemplate :key="formKey" :translation-node="t" :preloaded-data="tradeInInfoModel" :fields="licensePlateLookupField.fields" @updated="updateTradeInInput" />
      </div>
    </div>
    <div v-else>
      <FormTemplate :translation-node="t" :preloaded-data="tradeInInfoModel" :fields="fields" @updated="updateTradeInInput" />
    </div>
    <div v-if="uploadField" class="tradeInPicturesContainer">
      <h4>{{ t.tradeInPicturesTitle ?? 't.tradeInPicturesTitle' }}</h4>
      <p>{{ t.tradeInPicturesSubtitle ?? 't.tradeInPicturesSubtitle' }}</p>
      <div class="tradeInPictures">
        <div class="uploadSection" @drop.prevent="dropFiles" @dragover.prevent>
          <label :for="uploadField.name">
            <input :id="uploadField.name" ref="fileSelect" type="file" multiple :accept="uploadField.acceptedFiles" @change.stop="selectFile" />
            <div class="inner-box">
              <div class="image" />
              <div class="title">
                {{ t.imageTitle ?? 't.imageTitle' }} <strong>{{ t.browse ?? 't.browse' }}</strong>
              </div>
              <div class="subtitle">{{ t.imageSubtile ?? 't.imageSubtile' }}</div>
            </div>
          </label>
        </div>
        <template v-if="tradeInInfoModel.pictures?.length">
          <div class="image-list-title">{{ t.uploadedImages ?? 't.uploadedImages' }}</div>
          <div class="image-list">
            <div class="image-item" v-for="(item, index) in tradeInInfoModel.pictures" :key="index">
              <img :src="imageURL + item.url" alt="car" />
              <CircleCloseIcon class="close-btn" @click.prevent="deleteImage(item.url)" />
            </div>
          </div>
        </template>
        <div v-if="errorPicture" class="errorPictures">{{ errorPicture }}</div>
      </div>
    </div>
    <div class="actionButtons">
      <button v-if="selected" @click="onClickEdit">{{ t.edit ?? 't.edit ' }}</button>
      <button v-else type="submit">{{ t.submit ?? 't.submit' }}</button>
    </div>
  </form>
</template>

<script>
import SeezSdk from '../../sdk.js'
import { langMixin } from '../lang'
import Loader from './../Loader.ce.vue'
import FormTemplate from './../FormTemplate/FormTemplate.ce.vue'
import { mergeObjects } from '../../logic.js'
import SearchIcon from '../../assets/light-search.svg'
import InfoIcon from '../../assets/info-circle.svg'
import CircleCloseIcon from '../../assets/circle-close.svg'

const STANDARD_PAYLOAD = ['customerId', 'orderId', 'vin', 'licensePlate', 'make', 'model', 'body', 'facelift', 'seats', 'engine', 'driveType', 'transmission', 'year', 'variant', 'registrationDate', 'kilometrage', 'outstandingFinanceAmount', 'deduction', 'customerComment', 'fuelType', 'carCondition', 'registrationType', 'color', 'dealerComment', 'numberOfKeys', 'accident', 'accidentType', 'numberOfAccidents', 'serviceType', 'hasFinancing', 'listingId', 'email', 'name', 'firstName', 'middleName', 'lastName', 'phone', 'pictures', 'documents', 'targetSiteId', 'postalCode', 'valuationKey', 'is2ndSetOfTires', 'isMaintenanceBookletAvailable', 'servicedInLast6Months', 'exteriorColor', 'seatColor', 'sellerType', 'seatMaterial', 'isBuying', 'isSelling', 'customAttributes']

export default {
  name: 'TradeInForm',
  components: { FormTemplate, Loader, SearchIcon, InfoIcon, CircleCloseIcon },
  mixins: [langMixin('TRADE_IN_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  props: {
    orderId: { type: String, default: null },
    listingId: { type: String, default: null },
    layoutTemplate: { type: Object, default: null },
    selected: { type: Object, default: null }, // Selected Trade in on edit mode
    loading: { type: Boolean, default: false }
  },
  emits: ['onSave', 'onEdit'],
  data() {
    return {
      formKey: 1,
      targetSiteId: null,
      localLoading: false,
      fields: null,
      licensePlateLookupField: null,
      uploadField: null,
      uploadFieldInvalid: false,
      tradeInInfoModel: {},
      errorPicture: null
    }
  },
  computed: {
    imageURL() {
      return import.meta.env.VITE_IMAGES_URL + '/image/0x0/'
    }
  },

  mounted() {
    if (this.selected) {
      this.setTradeInValues()
    }
    if (this.layoutTemplate) {
      this.setupFields(this.layoutTemplate?.fields)
    } else {
      this.loadTargetSite()
    }
  },
  methods: {
    onClickEdit() {
      this.$emit('onEdit', this.tradeInInfoModel)
    },
    setTradeInValues() {
      // Setting the values
      this.tradeInInfoModel.id = this.selected.id
      this.tradeInInfoModel.vin = this.selected.vin
      this.tradeInInfoModel.licensePlate = this.selected.licensePlate
      this.tradeInInfoModel.pictures = this.selected.pictures
      this.tradeInInfoModel.hasFinancing = this.selected.hasFinancing
      this.tradeInInfoModel.make = this.selected.make
      this.tradeInInfoModel.model = this.selected.model
      this.tradeInInfoModel.variant = this.selected.variant
      this.tradeInInfoModel.year = this.selected.year
      this.tradeInInfoModel.kilometrage = this.selected.kilometrage
      this.tradeInInfoModel.color = this.selected.color
      this.tradeInInfoModel.engine = this.selected.engine
      this.tradeInInfoModel.fuelType = this.selected.fuelType
      this.tradeInInfoModel.transmission = this.selected.transmission
      this.tradeInInfoModel.seats = this.selected.seats
      this.tradeInInfoModel.registrationType = this.selected.registrationType
      this.tradeInInfoModel.registrationDate = this.selected.registrationDate
      this.tradeInInfoModel.carCondition = this.selected.carCondition
      this.tradeInInfoModel.accident = this.selected.accident
      this.tradeInInfoModel.accidentType = this.selected.accidentType
      this.tradeInInfoModel.numberOfAccidents = this.selected.numberOfAccidents
      this.tradeInInfoModel.serviceType = this.selected.serviceType
      this.tradeInInfoModel.numberOfKeys = this.selected.numberOfKeys
      this.tradeInInfoModel.customerComment = this.selected.customerComment
      this.tradeInInfoModel.outstandingFinanceAmount = this.selected.outstandingFinanceAmount

      if (this.selected.customAttributes) {
        this.selected.customAttributes.forEach(attribute => {
          this.tradeInInfoModel[attribute.key] = attribute.value
        })
      }
    },
    async loadTargetSite() {
      try {
        this.localLoading = true
        const query = `{targetSiteByClientId(id: "${window.seezSdk.clientId ?? 'mp'}") {
            id
            layoutTemplates {
              name
              fields {
                name
                type
                area
                question
                values
                required
                requiredNumberOfFiles
                query
                fields {
                  name
                  type
                  area
                  question
                  values
                  required
                  requiredNumberOfFiles
                  query
                }
              }
            }
          }
        }`
        const results = await this.queryApi(query)
        this.targetSiteId = results.targetSiteByClientId?.id

        await this.setupFields(results.targetSiteByClientId?.layoutTemplates.find(sc => sc.name === 'tradeIn').fields)
      } catch (e) {
        console.error(e)
      } finally {
        this.localLoading = false
      }
    },
    async setupFields(fields) {
      // Setup license plate lookup field if any
      this.licensePlateLookupField = fields.find(f => f.type === 'licensePlateLookup')

      this.fields = this.licensePlateLookupField?.fields ?? fields
      this.uploadField = this.fields.find(f => f.type === 'imageUpload')

      // Might loop over fields directly or over subFields of the licensePlateLookup field
      for (const field of this.fields) {
        if (field.query) {
          const fieldQueryResults = await this.queryApi(field.query)
          field.values = fieldQueryResults.values.map(r => r.key)
        }
      }
    },
    async lookupLicensePlate() {
      try {
        this.localLoading = true
        const query = `query vehicleLookup {
          vehicleLookup(licensePlate: "${this.tradeInInfoModel?.licensePlate}") {
            registered
            brand
            model
            variant
            vin
            enginePower
            registrationDate
            fuelType
            fuelEfficiency
            inspectionDate
            weightTotal
            licensePlate
            color
            serviceType
            modelYear
            registrationType
          }
        }
        `
        const results = await this.queryApi(query)

        this.tradeInInfoModel.licensePlate ??= results.vehicleLookup.licensePlate
        this.tradeInInfoModel.make ??= results.vehicleLookup.brand
        this.tradeInInfoModel.model ??= results.vehicleLookup.model
        this.tradeInInfoModel.variant ??= results.vehicleLookup.variant
        this.tradeInInfoModel.year ??= results.vehicleLookup.modelYear
        this.tradeInInfoModel.registrationDate ??= results.vehicleLookup.registrationDate
        this.tradeInInfoModel.registrationType ??= results.vehicleLookup.registrationType
        this.tradeInInfoModel.color ??= results.vehicleLookup.color
        this.tradeInInfoModel.fuelType ??= results.vehicleLookup.fuelType
        this.tradeInInfoModel.serviceType ??= results.vehicleLookup.serviceType
        this.tradeInInfoModel.vin ??= results.vehicleLookup.vin

        // force FormTemplate component to re-render
        this.formKey += 1
      } catch (e) {
        console.error(e)
      } finally {
        this.localLoading = false
      }
    },
    async selectFile() {
      const files = [...this.$refs.fileSelect.files]

      if (!files || files.length === 0) return
      if (!files.every(this.isValidFile)) {
        this.$refs.fileSelect.setCustomValidity('t.pictureError')
        this.uploadFieldInvalid = true
        this.$refs.tradeInForm.reportValidity()
        return
      }

      this.localLoading = true

      await Promise.all(
        files.map(file => {
          return this.imageUpload(file)
        })
      )
      this.$refs.fileSelect.value = null
      this.localLoading = false
    },
    isValidFile(f) {
      if (!f.name.match(/\.(jpg|jpeg|png|heic|heif)$/i)) return false
      if (f.size > 10 * 1024 * 1024) return false // 10mb
      return true
    },
    async dropFiles(event) {
      this.localLoading = true
      const files = [...event.dataTransfer.files]
      const uploadPromises = files?.map(file => {
        return this.imageUpload(file)
      })
      await Promise.all(uploadPromises)
      this.localLoading = false
    },
    async imageUpload(file) {
      this.$refs.fileSelect.setCustomValidity('')
      this.uploadFieldInvalid = false
      this.errorPicture = null
      const result = await window.seezSdk.uploadFile('trade-in/' + (this.tradeInInfoModel?.licensePlate ?? this.orderId ?? this.listingId), file, true)
      const uploadedFile = {
        type: 'other',
        url: result
      }

      const isImageExisted = this.tradeInInfoModel.pictures?.some(item => item.url === uploadedFile.url)
      if (this.tradeInInfoModel.pictures?.length) {
        if (!isImageExisted) {
          this.tradeInInfoModel.pictures.push(uploadedFile)
        }
      } else {
        this.tradeInInfoModel.pictures = [uploadedFile]
      }
    },
    updateTradeInInput(formInput) {
      this.tradeInInfoModel = mergeObjects(this.tradeInInfoModel, { ...formInput })
    },
    deleteImage(url) {
      this.errorPicture = null
      this.tradeInInfoModel.pictures = this.tradeInInfoModel.pictures?.filter(item => item.url !== url)
    },
    async createTradeIn() {
      if (this.selected) return

      if (this.tradeInInfoModel) {
        const requiredNumberOfPictures = this.fields.find(f => f.name === 'pictures' && f.type === 'imageUpload')?.requiredNumberOfFiles
        if (requiredNumberOfPictures && (!this.tradeInInfoModel.pictures || this.tradeInInfoModel.pictures?.length === 0 || this.tradeInInfoModel.pictures?.length < requiredNumberOfPictures)) {
          this.uploadFieldInvalid = true
          this.errorPicture = `${this.t.missingTradeInPictures} ${requiredNumberOfPictures}`
          this.$refs.tradeInForm.reportValidity()
          return
        }
      }

      const input = {}
      let customAttributes
      for (const key of Object.keys(this.tradeInInfoModel)) {
        if (STANDARD_PAYLOAD.includes(key)) {
          input[key] = this.tradeInInfoModel[key]
        } else {
          if (!customAttributes) {
            customAttributes = []
          }
          customAttributes.push({ key: key, value: this.tradeInInfoModel[key].toString() })
        }
      }

      if (customAttributes) {
        input.customAttributes = customAttributes.map(ca => {
          return { key: ca.key, value: ca.value }
        })
      }

      try {
        this.submitting = true
        const mutation = `
          mutation createTradeIn($input: TradeInInput) {
            createTradeIn(input: $input) {
              id
              state
              converted
              pictures {
                url
                type
              }
              listingId
              orderId
              licensePlate
              vin
              make
              model
              variant
              year
              kilometrage
              color
              fuelType
              registrationType
              registrationDate
              carCondition
              accident
              serviceType
              numberOfKeys
              currency
              offerAmount
              totalAmount
              outstandingFinanceAmount
              customerComment
              customer {
                id
              }
            }
          }`

        const currentUser = await window.seezSdk.getCurrentUser()
        const { createTradeIn } = await this.queryApi(mutation, {
          input: {
            orderId: this.orderId,
            listingId: this.listingId,
            targetSiteId: this.targetSiteId,
            customerId: currentUser?.id,
            ...input
          }
        })

        if (createTradeIn) {
          this.$emit('onSave', createTradeIn)
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.submitting = false
      }
    }
  }
}
</script>

<style lang="scss">
.tradeInForm {
  position: relative;
  .licensePlateField {
    svg {
      width: 1rem;
    }

    .seachField {
      display: flex;
    }

    .note {
      display: flex;
      align-items: center;
      gap: 0.375rem;
    }
  }
  .uploadSection {
    border: 1px solid;
    padding: 1rem;

    input {
      display: none;
    }
  }

  .image-list {
    display: flex;
    gap: 0.375rem;
    flex-wrap: wrap;

    .image-item {
      position: relative;
      > img {
        width: 85px;
        height: 52px;
        object-fit: fill;
        border-radius: 4px;
      }

      .close-btn {
        position: absolute;
        inset-block-start: 50%;
        inset-inline-start: 50%;
        cursor: pointer;
        transform: translate(-50%, -50%);
      }
    }
  }

  .loader {
    position: fixed;
    z-index: 999;
    overflow: hidden;
    @media screen and (min-width: 50rem) {
      position: absolute;
    }
  }

  .actionButtons {
    position: sticky;
    bottom: 0;
    background-color: white;
    padding: 1rem 0;
    border-top: 1px solid black;
  }
}
</style>
