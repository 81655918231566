<template>
  <div>
    <h2 class="title">{{ languageResources?.SAVED_SEARCHES_TRANSLATIONS?.saved_searches }}</h2>
    <p class="subtitle">{{ savedSearches.length }} {{ languageResources?.SAVED_SEARCHES_TRANSLATIONS?.saved }}</p>
    <ul v-if="!loading && !error && savedSearches.length > 0" class="searches">
      <SavedSearch v-for="s in savedSearches" :key="s.id" :search="s" :catalogs="catalogs" @select="$emit('select', s)" @remove="remove(s)" :lg="lg" />
    </ul>
    <template v-if="savedSearches.length === 0 && !loading">
      <slot v-if="replaceEmpty" name="empty" />
      <EmptySearch v-else :to="to" :description="t['you_have_not_saved_search']" :lg="lg" />
    </template>

    <p v-if="!loading && error" class="error">{{ error }}</p>
    <Loader v-if="loading" />
  </div>
</template>

<script>
import SeezSdk from '../../sdk.js'
import { langMixin } from '../lang'
import Loader from '../Loader.ce.vue'
import SavedSearch from './SavedSearch.ce.vue'
import EmptySearch from '../EmptySearch.ce.vue'
import { analyticsMixin } from '../../analytics.js'

export default {
  name: 'SeezSavedSearches',
  components: { Loader, EmptySearch, SavedSearch },
  mixins: [langMixin('EMPTY_SEARCH_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin, SeezSdk.vueSavedSearchesMixin, analyticsMixin],
  props: { to: { type: String, default: null }, replaceEmpty: { type: Boolean, default: false } },
  emits: ['select'],
  data: () => ({
    catalogs: {},
    loadingOptions: false,
    error: null
  }),
  computed: { 
    loading() {
      return this.loadingOptions
    }
  },
  mounted() {
    this.loadSearches()
  },
  methods: {
    async loadSearches() {
      this.loadingOptions = true
      this.error = null
      try {
        const query = '{brands{id,name},models{id,name},families{id,name},bodyTypes{id,name},transmissions{id,name},fuelTypes{id,name}}'
        const { brands, models, families, bodyTypes, transmissions, fuelTypes } = await this.queryApi(query)
        this.catalogs = {
          brands: brands.reduce((a, x) => ({ ...a, [x.id]: x.name }), {}),
          models: models.reduce((a, x) => ({ ...a, [x.id]: x.name }), {}),
          families,
          bodyTypes,
          transmissions,
          fuelTypes
        }
      } catch (e) {
        console.error(e)
        this.error = 'Error getting Saved Searches'
      }
      this.loadingOptions = false
    },
    remove(s) {
      this.track('asc_cta_interaction', { old_name: 'remove_saved_search', element_text: 'remove saved searches', name: s.name })
      this.removeSearch(s.id)
    }
  }
}
</script>

<style lang="scss">
.title {
  font-weight: 700;
  font-size: 2.25rem;
  margin-block-end: 8px;
}

.subtitle {
  font-weight: 700;
  font-size: 1.5rem;
  margin: 0;
  margin-block-end: 30px;
}

.searches {
  margin: 0;
  padding: 0;
  list-style-type: none;

  > .error {
    color: red;
    text-align: center;
    margin: 0;
    font-size: 2em;
  }
}
</style>
