<template>
  <div class="formContainer" :class="{ defaultStyles: loadDefaultStyles }">
    <SkeletonLoader v-if="loadingTemplate" />
    <form v-if="!onSuccess && !loadingTemplate" @submit.prevent="formSubmitted" class="dynamicInquiryForm" :class="{ defaultStyles: loadDefaultStyles }">
      <h2 v-if="t">{{ t[leadType]?.heading }}</h2>
      <ListingSelector v-if="selectedListingDetails" :listings-details="listingsDetails" :selected-listing-details="selectedListingDetails" @listing-selected="handleListingSelected" />
      <fieldset v-for="section in dynamicInquiryForm[0]?.fields" :key="section.fields" :class="`${section.name}Form`">
        <legend>
          {{ t[leadType]?.[section.name]?.legend }}
        </legend>
        <p v-if="section.errorMessage" class="leadTypeError">
          {{ t[section.errorMessage] || t.errorCreatingInquiry }}
        </p>
        <FormTemplate :translation-node="t" :preloaded-data="inquiryData" :fields="section.fields" @updated="updateInquiry" />
      </fieldset>
      <p class="error" v-if="errorMessage">{{ t[errorMessage] }}</p>
      <input data-test-id="inputSubmit" v-if="!onSuccess" type="submit" :value="t[leadType]?.submit" />
      <slot v-if="selectedListingDetails" name="contactInfo" class="contactInfo">
        <p class="contactInfo" v-html="handleContactInfo(t)" />
      </slot>
      <div v-if="loading || submitting" class="loaderWrapper">
        <Loader />
      </div>
    </form>
    <Success v-if="onSuccess" :translation-node="t" :lead-type="leadType" :read-only-mode="readOnlyMode" />
  </div>
</template>

<script>
import SeezSdk from '../../sdk'
import SkeletonLoader from './SkeletonLoader.ce.vue'
import { langMixin } from '../lang'
import FormTemplate from '../FormTemplate/FormTemplate.ce.vue'
import ListingSelector from './ListingSelector.ce.vue'
import Loader from '../Loader.ce.vue'
import Success from './Success.ce.vue'
import { analyticsMixin } from '@/analytics.js'
import { readOnlyModeMixin } from '../readOnlymode.js'
//This component like every component in SDK can receive styles by the mixin StylerMixin. Refer to main.js and check the logic around customCss field.
//If the styles applied are not what is shown on defaultStyles, that means that the styles are being applied by the target site config.
const errorTranslationKeys = {
  INQUIRY: 'errorCreatingInquiry',
  LISTING: 'errorListing',
  DEALERSHIP: 'errorDealershipLoad',
  HOLIDAYS: 'errorTestDriveHoliday',
  NO_LISTING: 'errorNoListing',
  SHOWROOM_CLOSED: 'errorShowroomClosed',
  SELECT_SHOWROOM: 'errorSelectLocation',
  NO_TIME_SLOTS: 'noTimeSlots'
}

// Implemented leadtypes are: generalInquiry, testDriveInquiry, partsInquiry, serviceInquiry

export default {
  name: 'DynamicInquiryForm',
  components: { FormTemplate, ListingSelector, Loader, Success, SkeletonLoader },
  mixins: [SeezSdk.vueQueryMixin, langMixin('DYNAMIC_INQUIRY_FORM_TRANSLATIONS'), analyticsMixin, readOnlyModeMixin],
  props: {
    id: { type: String, default: '' },
    dealershipId: { type: String, default: '' },
    listing: { type: String, default: '' },
    leadType: { type: String, default: '' }
  },
  emits: ['update'],
  data() {
    return {
      targetSite: null,
      loadDefaultStyles: false,
      submitting: false,
      errorMessage: '',
      onSuccess: false,
      listingsDetails: [],
      selectedListingDetails: null,
      inquiryData: {},
      formFields: null,
      legalTerms: {},
      payload: null,
      locationOptions: [],
      timeSlotsOptions: [],
      leadTypeErrorMessage: '',
      openingHours: {},
      dynamicInquiryForm: [],
      fields: [],
      unavailableDatesArr: [],
      loadingTemplate: true, // Indicates initial data loading
      loading: false // Indicates form submission loading
    }
  },
  watch: {
    t() {}
  },
  mounted() {
    // readOnlyMode comes from readOnlyMixin and is set in the parent page or component that uses this component
    if (this.readOnlyMode) {
      this.loadingTemplate = false
      this.onSuccess = true
      return
    }

    this.fetchData()
    this.track('inquiry_form_shown', { type: this.leadType, listing: this.listing ?? null, externalId: this.id ?? null })
  },
  methods: {
    async fetchData() {
      this.loadingTemplate = true
      let listingId = null

      // Handle external id case.
      if (this.id) {
        const listingIdByExternalIdResult = await this.queryListingByExternalId()
        listingId = listingIdByExternalIdResult
      }

      try {
        await Promise.all([this.loadListings(listingId), this.loadTargetSite()])
      } catch (e) {
        // Errors are handled within loadListings and loadTargetSite
        console.log(e)
      } finally {
        this.loadingTemplate = false
      }
    },

    handleContactInfo(t) {
      const phone = this.selectedListingDetails?.dealership?.phone || this.targetSite?.customerSupport?.phoneNumber || ''
      const referenceNumber = this.selectedListingDetails?.referenceNumber || ''
      return t.disclaimer.replace('{phone_number}', phone).replace('{phone_number_label}', phone).replace('{reference_number}', referenceNumber)
    },

    handleLegalText(section) {
      return section.name === 'legal' ? this.t.legalText?.replace(/{dealershipName}/g, this.targetSite?.name) : ''
    },
    async queryListingByExternalId() {
      if (!this.id) return
      try {
        const { listingsByExternalId } = await this.queryApi(`{listingsByExternalId(externalId: "${this.id}")}`)
        if (listingsByExternalId.length === 0) {
          this.errorMessage = errorTranslationKeys.NO_LISTING
          this.loadingTemplate = false
          return
        }

        return listingsByExternalId[0]
      } catch (e) {
        console.error('Error queryListingByExternalId', e)
        this.errorMessage = errorTranslationKeys.LISTING
      }
    },
    //Arg listingId is passed to loadListings when the externalId is passed to the component
    async loadListings(listingId) {
      let ids = []
      if (listingId) {
        ids.push(listingId)
      } else {
        ids = this.listing.split(',').filter(i => i != '')
      }

      if (ids.length == 0) return

      try {
        const { listingsByIds } = await this.queryApi(
          `query listings($ids: [ID!]!) {
            listingsByIds(ids: $ids) {
              id
              name
              variant
              year
              images
              kilometrage
              kmUnit
              externalId
              referenceNumber
              vehicle {
                locatedAt {
                  id
                  plainAddress
                  openingHours {
                    testDriveTimeSlotSize
                    holidays
                    week {
                      from
                      to
                    }
                  }
                }
              }
              dealership {
                id
                plainAddress
                phone
                workingHours {
                  dayOfWeek
                  startTime
                  endTime
                }
              }
              locatedAt {
                openingHours {
                  testDriveTimeSlotSize
                  holidays
                  week {
                    from
                    to
                  }
                }
              }
            }
          }`,
          { ids }
        )
        this.listingsDetails = listingsByIds
        this.selectedListingDetails = this.listingsDetails[0] ?? null
        this.inquiryData.location = this.listingsDetails[0]?.vehicle?.locatedAt?.id || ''

        this.openingHours = this.listingsDetails[0]?.vehicle?.locatedAt?.openingHours || {}

        if (ids.length) {
          this.handleListingLocationOptions()
        }
      } catch (e) {
        console.error('Error loadListing', e)
        this.errorMessage = errorTranslationKeys.LISTING
      }
    },
    async loadTargetSite() {
      const query = `{
        currentTargetSite  {
          id
          name
          dealerships {
            id
            name
            plainAddress
            openingHours { testDriveTimeSlotSize holidays week { from to additionalSlots { from to } }}
            descendants(type: showroom) { id plainAddress customAttributes { key value }}
          }
          customerSupport { email phoneNumber }
          urlConfig { privacyPolicyUrl termsAndConditionsUrl }
          layoutTemplates(name: "${this.leadType}") {
            name
            fields {
              name
              fields {
                name
                type
                values
                area
                regex
                question
                infoLabel
                placeholder
                maxLength
                query
                required
                visible
                enabled
                startDateDelay
                default
                translationNode
                acceptedFiles
                lookupGeneratorFunction
              }
            }
          }
        }
      }`

      try {
        const { currentTargetSite } = await this.queryApi(query)
        if (currentTargetSite.dealerships.length > 0 && currentTargetSite.dealerships[0]?.openingHours) {
          const timeArray = this.generateTimeSlots(currentTargetSite.dealerships[0].openingHours?.testDriveTimeSlotSize ?? 60)
          this.timeSlotsOptions = timeArray
          this.setDropdownOptions(this.fields)
        }

        if (!currentTargetSite) {
          this.errorMessage = errorTranslationKeys.DEALERSHIP
          return
        }
        this.targetSite = currentTargetSite

        if (!this.selectedListingDetails) {
          this.locationOptions = this.handleLocation(currentTargetSite.dealerships)
        }
        if (currentTargetSite.layoutTemplates.length > 1) {
          this.dynamicInquiryForm = currentTargetSite.layoutTemplates.filter(template => template.name === 'generalInquiry')
          throw new Error('Type passed not valid')
        }

        const unavailableDatesArr = currentTargetSite?.dealerships[0]?.openingHours?.holidays || []
        if (unavailableDatesArr?.length) this.unavailableDatesArr = unavailableDatesArr

        this.dynamicInquiryForm = currentTargetSite.layoutTemplates
        this.fields = this.dynamicInquiryForm[0]?.fields
        this.setDropdownOptions(this.dynamicInquiryForm[0].fields)
      } catch (e) {
        console.error('Error loadTargetSite', e)
        this.errorMessage = errorTranslationKeys.DEALERSHIP
      }
    },
    setDropdownOptions(fields) {
      if (fields.length === 0) return
      fields.forEach(field => {
        if (field.name === 'location' && this.locationOptions.length) {
          field.values = this.locationOptions
        }
        if (field.fields && Array.isArray(field.fields)) {
          this.setDropdownOptions(field.fields)
        }
        if (field.name === 'time' && this.timeSlotsOptions.length) {
          field.values = this.timeSlotsOptions
        }
        if (field.name === 'date' && this.unavailableDatesArr.length) {
          field.disabledDates = this.unavailableDatesArr
          field.disabledDatesLabel = this.t.closedDueHolidayDate
        }
      })
    },
    // To detect errors on the disabled dates fields.
    getInvalidDateFields(formStructure) {
      const invalidDateFields = []

      formStructure.forEach(section => {
        const { name: sectionName, fields } = section
        if (Array.isArray(fields)) {
          fields.forEach(field => {
            if (field.type === 'date' && field.invalid === true) {
              invalidDateFields.push({
                section: sectionName,
                fieldName: field.name,
                invalid: field.invalid,
                disabledDates: field.disabledDates || [],
                disabledDatesLabel: field.disabledDatesLabel || ''
              })
            }
          })
        }
      })

      return invalidDateFields
    },
    async formSubmitted() {
      const invalidFields = this.getInvalidDateFields(this.dynamicInquiryForm[0].fields)
      if (invalidFields.length) return
      this.loading = true
      this.errorMessage = ''
      this.onSuccess = false

      if (this.inquiryData.date) {
        this.inquiryData.date = this.inquiryData.date.split('T')[0]
      }

      const formattedFields = this.formatPayload(this.inquiryData)

      const payload = {
        inquiryType: this.leadType,
        dealershipId: +this.dealershipId || +this.targetSite?.dealerships[0]?.id,
        inquiryData: formattedFields
      }

      if (this.selectedListingDetails) {
        payload.listingId = this.selectedListingDetails.id
      }

      if (typeof this.$parent?.getAdditionalTrackingProps === 'function') {
        const { chatReferenceId } = this.$parent.getAdditionalTrackingProps()

        if (chatReferenceId) {
          payload.chatReference = chatReferenceId
        }
      }

      const query = `
        mutation createInquiry($inquiryType: String!, $dealershipId: ID, $listingId: ID, $inquiryData: [CustomAttributeInput!]!, $chatReference: String) {
          createInquiry(
            inquiryType: $inquiryType
            dealershipId: $dealershipId
            listingId: $listingId
            inquiryData: $inquiryData
            chatReference: $chatReference
          ) {
            id
            inquiryData {
              key
              value
            }
            inquiryType
          }
        }`

      try {
        const { createInquiry } = await this.queryApi(query, payload)

        if (!createInquiry) {
          this.errorMessage = errorTranslationKeys.INQUIRY
        } else {
          this.onSuccess = true
          this.inquiryData = {}
          this.track('inquiry_form_submitted', { type: this.leadType, listing: this.listing ?? null, externalId: this.id ?? null })
        }
      } catch (e) {
        console.log(e)
        this.errorMessage = errorTranslationKeys.INQUIRY
        this.track('inquiry_form_error_sending', { type: this.leadType, listing: this.listing ?? null, externalId: this.id ?? null })
      } finally {
        this.loading = false
      }
    },
    handleTimeSlotByConfig(newData) {
      const { date } = newData
      if (!date) return
      const dayOfWeekIndex = new Date(date).getDay()
      const openingHoursOnThisDay = this.targetSite?.dealerships[0]?.openingHours?.week[dayOfWeekIndex] || []
      const slotSizeInMinutes = this.targetSite?.dealerships[0]?.openingHours?.testDriveTimeSlotSize

      const { from, to } = openingHoursOnThisDay
      const additionalSlots = openingHoursOnThisDay?.additionalSlots || []
      const activeTimeRanges = []

      if (from && to) {
        activeTimeRanges.push({ from, to })
      } else {
        //cover case where dealership does not declare the from to under config.
        activeTimeRanges.push({ from: '09:00', to: '17:30' })
        this.unavailableDatesArr = [...this.unavailableDatesArr, date]
        this.setDropdownOptions(this.dynamicInquiryForm[0].fields)
      }

      if (Array.isArray(additionalSlots)) {
        additionalSlots.forEach((slot, index) => {
          if (slot.from && slot.to) {
            activeTimeRanges.push({ from: slot.from, to: slot.to })
          } else {
            console.warn(`Invalid additional slot at index ${index}:`, slot)
          }
        })
      }

      let combinedTimeSlots = []

      activeTimeRanges.forEach(range => {
        const slots = this.generateTimeSlots(slotSizeInMinutes, range.from, range.to)
        combinedTimeSlots = combinedTimeSlots.concat(slots)
      })

      combinedTimeSlots = [...new Set(combinedTimeSlots)]
      this.timeSlotsOptions = combinedTimeSlots
      this.setDropdownOptions(this.dynamicInquiryForm[0].fields)
    },
    updateInquiry(newData) {
      const key = Object.keys(newData)

      if (key.includes('date')) {
        this.handleTimeSlotByConfig(newData)
      }
      this.leadTypeErrorMessage = ''
      this.inquiryData = { ...this.inquiryData, ...newData }
    },
    handleListingSelected(listing) {
      this.selectedListingDetails = listing
      this.inquiryData.date = ''
      this.handleListingLocationOptions()
      this.inquiryData = { ...this.inquiryData, location: listing?.vehicle?.locatedAt?.id || '' }
      this.openingHours = listing?.vehicle?.locatedAt?.openingHours || {}
    },
    handleListingLocationOptions() {
      this.locationOptions = [
        {
          id: this.selectedListingDetails?.vehicle?.locatedAt?.id,
          value: this.selectedListingDetails?.vehicle?.locatedAt?.plainAddress
        }
      ]
    },
    handleLocation(dealershipArr = []) {
      this.locationOptions = dealershipArr
        .flatMap(d => d.descendants)
        .filter(s => s.plainAddress && s.plainAddress.trim() !== '')
        .filter(s => !['serviceInquiry', 'partsInquiry'].includes(this.leadType) || s.customAttributes.some(a => a.key === 'roles' && a.value?.split(',').includes('service')))
        .map(s => ({ id: s.id, value: s.plainAddress.trim() }))
        .sort((a, b) => a.value.localeCompare(b.value))

      this.setDropdownOptions(this.fields)
      return this.locationOptions
    },
    formatPayload(obj) {
      const keyValueArray = []
      for (const [key, value] of Object.entries(obj)) {
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
          const nestedArray = this.formatPayload(value)
          nestedArray.forEach(item => {
            keyValueArray.push({ key: `${key}.${item.key}`, value: String(item.value) })
            keyValueArray.push({
              key: `${key}.${item.key}`,
              value: this.sanitize(String(item.value))
            })
          })
        } else {
          keyValueArray.push({ key, value: String(value) })
          keyValueArray.push({
            key,
            value: this.sanitize(String(value))
          })
        }
      }
      return keyValueArray
    },
    sanitize(str) {
      // Create a temporary DOM element to leverage the browser's parser
      const tempDiv = document.createElement('div')
      tempDiv.textContent = str
      return tempDiv.innerHTML
    },
    generateTimeSlots(slotSizeInMinutes, startTime = '09:00', endTime = '17:30') {
      if (typeof slotSizeInMinutes !== 'number' || slotSizeInMinutes < 15 || slotSizeInMinutes > 90 || slotSizeInMinutes % 15 !== 0) {
        throw new Error('slotSizeInMinutes must be a number between 15 and 90, in 15-minute increments.')
      }

      const timeToMinutes = timeStr => {
        const ampmRegex = /^(1[0-2]|0?[1-9]):([0-5]\d)\s*([AaPp][Mm])$/
        const ampmMatch = timeStr.match(ampmRegex)

        if (ampmMatch) {
          let hours = parseInt(ampmMatch[1], 10)
          const minutes = parseInt(ampmMatch[2], 10)
          const period = ampmMatch[3].toLowerCase()

          if (period === 'pm' && hours !== 12) {
            hours += 12
          }
          if (period === 'am' && hours === 12) {
            hours = 0
          }

          return hours * 60 + minutes
        }

        const twentyFourHourRegex = /^([01]?\d|2[0-3]):([0-5]\d)$/
        const twentyFourMatch = timeStr.match(twentyFourHourRegex)

        if (twentyFourMatch) {
          const hours = parseInt(twentyFourMatch[1], 10)
          const minutes = parseInt(twentyFourMatch[2], 10)
          return hours * 60 + minutes
        }

        console.error(`Invalid time format: "${timeStr}". Use "HH:MM" or "HH:MMam/pm".`)
      }

      const minutesToTime = totalMinutes => {
        let hours = Math.floor(totalMinutes / 60)
        const minutes = totalMinutes % 60
        hours = hours % 12
        if (hours === 0) hours = 12

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
      }

      const startMinutes = timeToMinutes(startTime)
      const endMinutes = timeToMinutes(endTime)

      const slots = []
      let current = startMinutes

      while (current <= endMinutes) {
        slots.push(minutesToTime(current))
        current += slotSizeInMinutes
      }

      if (slots.length > 0 && timeToMinutes(slots[slots.length - 1]) > endMinutes) {
        slots.pop()
      }

      return slots
    }
  }
}
</script>

<style>
.formContainer {
  position: relative;
}

.dynamicInquiryForm {
  height: auto;
  background-color: var(--chat-bg-color, white);

  --highlight: #0068ff;
  --text-color: black;
  --background: white;
  --accented-background: color-mix(in oklab, var(--highlight) 5%, var(--background));
  --base-font: Biennale, Verdana, Geneva, Tahoma, sans-serif;
  --base-shadow: 0 0 0.25em rgba(0, 0, 0, 0.1);
  --border-color: #ccc;

  .formTemplate input[type='checkbox'] {
    transform: scale(1) !important;
    @media screen and (max-width: 50rem) {
      transform: scale(0.9) !important;
    }
  }
}

.leadTypeError {
  color: red;
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 0.7rem;
}

.defaultPrivacy {
  margin-bottom: 1rem;
}

.defaultPrivacy p {
  font-size: 0.875rem;
}

.success {
  border-radius: 52px;
  color: black;
  padding: 12px 0;

  width: 100%;
  margin: auto;
  text-align: center;
  border: none;
  border-radius: 2rem;
  font-size: 1rem;
  font-weight: 600;
}

.success p {
  margin: 0;
}

.checkboxWrapper {
  display: flex;
  flex-direction: row !important;
  align-items: baseline;
  justify-content: start;

  h2 {
    margin: 0 !important;
  }
}

.loaderWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  backdrop-filter: blur(1px);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* remove styles from dropdown multilselect */
.multiselect ul > li span {
  font-size: 0.875rem;
  background-color: transparent !important;
  border-radius: none !important;
}

.error {
  color: red;
  font-size: 0.8rem;
  text-align: center;
}

.dynamicInquiryForm,
.SkeletonLoader {
  position: relative;
}

.SkeletonLoader {
  z-index: 5;
}

.loaderWrapper {
  z-index: 10;
}

.defaultStyles {
  .formContainer {
    gap: 0.75rem;
  }

  h2 {
    margin: 0;
    max-height: 2rem;
    padding-inline-start: 0.2rem;
    margin-bottom: 0.5rem;
  }

  .contactInfo {
    display: none;
  }

  *:not(h1, h2) {
    font-size: 1rem !important;
    @media screen and (min-width: 50rem) {
      font-size: 0.8rem !important;
    }
  }

  .listingSelector {
    position: relative;
    color: black;

    img {
      width: 6.5rem;
      border-top-left-radius: var(--chat-border-radius, 6px);
      border-bottom-left-radius: var(--chat-border-radius, 6px);
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: url(<path-to-image>) lightgray -18.713px -10.289px / 139.13% 120.229% no-repeat;
    }

    span:first-of-type {
      font-size: 1rem;
      font-weight: 700;

      @media screen and (min-width: 50rem) {
        font-size: 1.25rem;
      }
    }

    span:last-of-type {
      font-size: 0.7rem;
      font-weight: 600;

      @media screen and (min-width: 50rem) {
        font-size: 0.875rem;
      }
    }

    article {
      border-radius: var(--chat-border-radius, 6px);
      border: 1px solid var(--border-color);

      .chevronIcon {
        padding-inline-end: 0.5rem;
      }

      &:not(.expansible) {
        pointer-events: none;

        img {
          border-top-left-radius: var(--chat-border-radius, 6px);
          border-bottom-left-radius: var(--chat-border-radius, 6px);
        }
      }
    }

    .expansible {
      box-shadow: 0px 0px 20px 2px rgba(226, 226, 226, 0.5);
      background: #f6f6f6;

      img {
        width: 5rem;
        border-top-left-radius: var(--chat-border-radius, 6px);
        border-bottom-left-radius: var(--chat-border-radius, 6px);
        background: url(<path-to-image>) lightgray -18.713px -10.289px / 139.13% 120.229% no-repeat;

        @media screen and (min-width: 50rem) {
          width: 6.5rem;
        }
      }

      span:first-of-type {
        font-size: 0.8rem;
        font-weight: 700;

        @media screen and (min-width: 50rem) {
          font-size: 1.25rem;
        }
      }

      span:last-of-type {
        font-size: 0.675rem;
        font-weight: 600;

        @media screen and (min-width: 50rem) {
          font-size: 0.875rem;
        }
      }
    }

    ul {
      border-radius: 16px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      box-shadow: 0px 0px 20px 2px rgba(226, 226, 226, 0.5);
      list-style: none;
      width: -webkit-fill-available;
      height: 21rem;
      overflow: scroll;
      padding: 0.5rem;
      z-index: 2;

      li {
        width: 100%;

        article {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          display: grid;
          gap: 0.25rem 1rem;
          grid-template-columns: auto 1fr;
          grid-template-rows: 1fr 1fr;
          grid-template-areas: 'image title' 'image subtitle';

          input {
            right: v-bind('textDirection === "rtl" ? "auto" : "1rem"');
            left: v-bind('textDirection === "rtl"  ? "1rem" : "auto"');
          }

          img {
            grid-area: image;
            width: 5rem;
            height: 4.25rem;
            border-top-left-radius: var(--chat-border-radius, 6px);
            border-bottom-left-radius: var(--chat-border-radius, 6px);

            background: url(<path-to-image>) lightgray -18.713px -10.289px / 139.13% 120.229% no-repeat;
            object-fit: cover;

            @media screen and (min-width: 50rem) {
              width: 6.5rem;
            }
          }

          span:first-of-type {
            grid-area: title;
            align-self: flex-end;
            flex-wrap: wrap;
            font-size: 0.6rem;
            font-weight: 700;

            @media screen and (min-width: 50rem) {
              font-size: 1.25rem;
            }
          }

          span:last-of-type {
            grid-area: subtitle;
            align-self: flex-start;
            font-size: 0.6rem;
            font-weight: 600;

            @media screen and (min-width: 50rem) {
              font-size: 0.875rem;
            }
          }
        }

        .selected {
          opacity: 0.7;
          border: 1px solid var(--highlight);
          border-radius: 6px;
        }
      }
    }
  }

  .dynamicInquiryForm {
    padding: 0.8rem;
    border-radius: var(--chat-border-radius, 0.563rem);
    border: 1px solid #ccc;

    fieldset {
      border: none;
      padding: 0;
      display: flex;
      flex-direction: row-reverse;

      legend {
        font-weight: 500;
        /* margin: 0 0 0.75rem 0; */
        max-height: 18px;
        margin-bottom: 12px;
      }
    }

    fieldset:not(:first-of-type):not(:last-of-type) {
      margin-top: 1rem;
    }

    fieldset:last-of-type {
      margin-top: 0.5rem;
    }

    .search-select {
      position: relative;

      .options-list li {
        cursor: pointer;
        margin: 0.5rem 0;
      }

      .options-list li.highlighted {
        background: var(--highlight);
        color: white;
      }

      .not-found {
        color: red;
        font-size: 0.8rem;
        text-align: start;
        margin-top: 0.5rem;
      }

      .input-container {
        width: 100%;
        display: flex;

        .chevron-down {
          position: absolute;
          right: v-bind('textDirection === "rtl" ? "auto" : "0.5rem"');
          left: v-bind('textDirection === "rtl"  ? "0.5rem" : "auto"');
          top: 50%;
          transform: translateY(-50%);
          font-size: 1.5rem;
          color: #ccc;
          background-color: transparent;
          border: none;
          transition: transform 0.2s ease;
          cursor: pointer;
        }

        .rotate-up {
          transform: translateY(-40%) rotate(180deg);
        }

        .clear-button {
          position: absolute;
          top: 0.3rem;
          right: 0.5rem;
          width: 2rem;
          height: 2rem;
          padding: 0.3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background: transparent;
          border: none;
          cursor: pointer;
          color: #ccc;
          right: v-bind('textDirection === "rtl" ? "auto" : "0.5rem"');
          left: v-bind('textDirection === "rtl"  ? "0.5rem" : "auto"');

          &.error {
            color: red;
          }
        }

        .clear-button:hover {
          color: #000;
        }
      }

      input {
        font-size: 1rem;
        padding: 0.9rem 1rem;
        border-radius: 0.5rem;
        width: 100%;
        border: 1px solid #e2e2e2;
        text-align: start;

        &.error {
          border: 1px solid red;

          &:focus {
            outline: none;
          }
        }
      }
      ul {
        list-style: none;
        position: absolute;
        border-radius: var(--chat-border-radius, 0.563rem);
        top: 2.5rem;
        left: 0;
        right: 0;
        height: auto;
        max-height: 15rem;
        min-height: 1rem;
        overflow: auto;
        background-color: white;
        padding: 1rem;
        z-index: 999;
        border: 1px solid #e2e2e2;

        li {
          cursor: pointer;
          padding: 0.2rem 0;

          &:hover {
            opacity: 0.7;
            color: var(--accent);
          }
        }
      }
    }

    .dynamicInputField label > span,
    .dateTime > span {
      display: none;
    }

    .formTemplate {
      width: -webkit-fill-available;

      input,
      textarea {
        padding: 0.72rem 1rem;
        border: 1px solid #ccc;
        border-radius: var(--chat-border-radius, 0.563rem);
        display: flex;
        align-items: center;
        font-size: 1rem;
        width: -webkit-fill-available;
      }

      input.inputDateTimeSlot {
        padding: 0.657rem 1rem;
      }

      .dateTime svg {
        width: 1rem;
      }

      .description {
        margin-top: 0.5rem;
        font-size: 12px;
        display: flex;
        align-items: center;
        gap: 0.2rem;
        svg {
          width: 1rem;
          min-width: 1rem;
          margin-inline-end: 0.1rem;
        }
      }

      textarea {
        width: -webkit-fill-available !important;
      }

      input[type='checkbox'] {
        width: 10px;
        margin-inline-end: 0.5rem;
        margin-inline-start: 0;
        @media screen and (max-width: 50rem) {
          transform: scale(0.8); /* Adjust the scale as needed */
        }
      }

      input[name='privacyTerms'] {
        width: 10px;
        display: none;
        @media screen and (max-width: 50rem) {
          transform: scale(0.8); /* Adjust the scale as needed */
        }
      }

      .checkboxWrapper input[name='privacyTerms'] + div {
        font-weight: 700;
      }

      button {
        padding: 0.72rem 1rem;
        border: 1px solid #ccc;
        border-radius: var(--chat-border-radius, 0.563rem);
        display: flex;
        align-items: center;
        font-size: 1rem;

        span {
          display: contents;
        }
      }
    }

    input[type='submit'] {
      background-color: var(--primary-color, var(--highlight));
      color: var(--cta-text-color, --text-color);
      border-radius: var(--chat-border-radius, 0.563rem);
      width: -webkit-fill-available;
      margin-top: 0.563rem;
      padding: 0.75rem 0;
      border: none;
      cursor: pointer;
      font-weight: 600;
      white-space: normal;
    }
    input[type='submit']:hover {
      opacity: 0.9;
    }
  }
}
</style>
