<template>
  <div :class="{ leadForm: true, defaultStyles: loadDefaultStyles }">
    <div class="formWrapper" v-if="!onSuccess">
      <div class="headingWrapper">
        <slot name="heading">
          <h2 class="leadFormHeading">{{ t.heading }}</h2>
          <span class="headingHidden">{{ listingDetails }}</span>
          <span class="headingHidden">
            <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.94 12.22C2.72 10.7 0 7.06 0 5 0 2.52 1.99.5 4.5.5 6.98.5 9 2.52 9 5 9 7.06 6.26 10.7 5.04 12.22 4.76 12.57 4.22 12.57 3.94 12.22ZM4.5 6.5C5.32 6.5 6 5.84 6 5 6 4.18 5.32 3.5 4.5 3.5 3.66 3.5 3 4.18 3 5 3 5.84 3.66 6.5 4.5 6.5Z" fill="black" />
            </svg>
            <span v-if="details?.internalNote">{{ details?.internalNote }}</span>
            <span v-else>{{ details?.locatedAt?.plainAddress?.split(',')?.[1] ? details?.locatedAt?.plainAddress?.split(',')?.[1] : details?.locatedAt?.plainAddress?.split(',')?.[0] }}</span>
          </span>
        </slot>
      </div>
      <div class="detail-showroom" v-if="!loading && listing">
        <div class="image">
          <img :src="mainImageUrl" :alt="details?.name" loading="lazy" />
        </div>
        <div class="infotext">
          <span>{{ listingDetails }}</span>
          <span>
            <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.94 12.22C2.72 10.7 0 7.06 0 5 0 2.52 1.99.5 4.5.5 6.98.5 9 2.52 9 5 9 7.06 6.26 10.7 5.04 12.22 4.76 12.57 4.22 12.57 3.94 12.22ZM4.5 6.5C5.32 6.5 6 5.84 6 5 6 4.18 5.32 3.5 4.5 3.5 3.66 3.5 3 4.18 3 5 3 5.84 3.66 6.5 4.5 6.5Z" fill="black" />
            </svg>
            {{ languageResources?.TestDriveModal?.vehicleLocation }}
            <span v-if="details?.internalNote">{{ details?.internalNote }}</span>
            <span v-else>{{ details?.locatedAt?.plainAddress?.split(',')?.[1] ? details?.locatedAt?.plainAddress?.split(',')?.[1] : details?.locatedAt?.plainAddress?.split(',')?.[0] }}</span>
          </span>
        </div>
      </div>
      <slot name="intro">
        <p>{{ t.introText }}</p>
      </slot>
      <form @submit.prevent="submitLead">
        <label>
          <span>{{ t.firstName }}</span>
          <input data-test-id="firstName" name="firstName" :placeholder="getPlaceholder('firstName')" v-model="leadData.firstName" :required="isRequired('firstName')" type="text" autocomplete="given-name" />
        </label>
        <label>
          <span>{{ t.middleName }}</span>
          <input data-test-id="middleName" name="middleName" :placeholder="t.middleName" v-model="leadData.middleName" :required="isRequired('middleName')" type="text" autocomplete="additional-name" />
        </label>
        <label>
          <span>{{ t.lastName }}</span>
          <input data-test-id="lastName" name="lastName" :placeholder="getPlaceholder('lastName')" v-model="leadData.lastName" :required="isRequired('lastName')" type="text" autocomplete="family-name" />
        </label>
        <label for="qid">
          <span>{{ t.qid }}</span>
          <input data-test-id="qid" v-model="leadData.qid" :required="isRequired('qid')" :class="{ inputError: errorQID }" name="qid" id="qid" :placeholder="getPlaceholder('qid')" type="text" autocomplete="qid" maxlength="11" />
          <p v-if="errorQID" class="qidError"><InfoCircle /> {{ t.errorQid }}</p>
        </label>
        <label>
          <span>{{ t.email }}</span>
          <input data-test-id="email" name="email" :placeholder="getPlaceholder('email')" v-model="leadData.email" :required="isRequired('email')" type="email" autocomplete="email" />
        </label>
        <label>
          <span>{{ t.postalCode }}</span>
          <input data-test-id="postalCode" name="postalCode" :placeholder="t.postalCode" v-model="leadData.address.postalCode" :required="isRequired('postalCode')" type="text" autocomplete="postal-code" />
        </label>
        <label>
          <span>{{ t.phone }}</span>
          <input data-test-id="phone" name="phone" :placeholder="getPlaceholder('phone')" v-model="leadData.phone" :required="isRequired('phone')" type="text" pattern="[\d\+\-\s\(\)]{7,}" autocomplete="tel" />
        </label>
        <label class="multiselectLabel" for="region" v-if="source === sourceAam">
          <span>{{ t.region }}</span>
          <div class="multiselect">
            <multi-select id="region" :options="regions" :multi="false" v-model="leadData.region" :required="isRequired('region')" :placeholder="t.region" data-test-id="timeSlot" :other-region="otherRegion" />
          </div>
          <p v-if="errorRegion" class="qidError"><InfoCircle /> {{ t.errorRegion ?? 't.errorRegion' }}</p>
        </label>
        <slot name="comments" />
        <label>
          <span>{{ t.comments }}</span>
          <textarea data-test-id="comments" v-model="leadData.comments" name="comments" :placeholder="t.comments" :required="isRequired('comments')" cols="40" rows="10" />
        </label>
        <label class="check">
          <input data-test-id="terms" name="policy" type="checkbox" :required="isRequired('policy')" />
          <slot name="policy">
            <span v-html="policyHtml" />
          </slot>
        </label>
        <label class="check">
          <input data-test-id="marketing" name="marketing" type="checkbox" :required="isRequired('marketing')" v-model="leadData.marketingConsent" />
          <slot name="marketing">
            <span>{{ t.marketing }}</span>
          </slot>
        </label>
        <p class="error" v-if="error">{{ error.message }}</p>
        <input class="leadBtnSubmit" type="submit" :value="t.submit" />
      </form>
      <div class="info" v-if="contactPhone">
        <p>
          <span>
            {{ t.forMoreInfo }}
            <a :href="'tel:+' + contactPhone">{{ contactPhone }}</a>
          </span>
          <br />
          <span v-if="details.referenceNumber">
            {{ t.referenceNumber }}: <b>{{ details.referenceNumber }}</b>
          </span>
        </p>
        <slot name="info" />
      </div>
    </div>
    <div v-if="onSuccess" class="success">
      <div class="iconWrapper">
        <svg width="46" height="46" viewBox="0 0 46 46">
          <path d="M0 23C0 10.33 10.24 0 23 0 35.67 0 46 10.33 46 23 46 35.76 35.67 46 23 46 10.24 46 0 35.76 0 23ZM33.33 19.05C34.32 18.06 34.32 16.53 33.33 15.54 32.34 14.55 30.82 14.55 29.83 15.54L20.13 25.25 16.08 21.29C15.09 20.3 13.57 20.3 12.58 21.29 11.59 22.28 11.59 23.81 12.58 24.8L18.33 30.55C19.32 31.54 20.84 31.54 21.83 30.55L33.33 19.05Z" fill="#33AE5C" />
        </svg>
      </div>
      <h1>{{ t.thankYouTitle }}</h1>
      <p>{{ t.thankYouText }}</p>
    </div>
    <Loader v-if="loading" />
  </div>
</template>

<script>
import Loader from '../Loader.ce.vue'
import SeezSdk from '../../sdk'
import { langMixin } from '../lang'
import { analyticsMixin } from '../../analytics.js'
import InfoCircle from '../../assets/info-circle.svg'
import { validateQid } from '../../logic.js'
import MultiSelect from '../MultiSelect.ce.vue'

const sourceAam = 'aam'

export default {
  name: 'LeadForm',
  components: { MultiSelect, Loader, InfoCircle },
  mixins: [langMixin('LeadForm'), SeezSdk.vueQueryMixin, analyticsMixin],
  props: {
    listing: { type: String, default: null },
    source: { type: String, default: '' },
    requiredFields: { type: String, default: 'firstName,lastName,email,phone,region,policy,marketing' }
  },
  emits: ['sent'],
  data() {
    return {
      loading: false,
      error: null,
      onSuccess: false,
      errorRegion: null,
      sourceAam,
      hasMarketingAccepted: false,
      leadData: {
        firstName: null,
        middleName: null,
        lastName: null,
        comments: null,
        email: null,
        marketingConsent: false,
        address: {
          postalCode: null
        },
        phone: null,
        region: null
      },
      details: null,
      targetSiteConfig: null,
      loadDefaultStyles: false //FIXME: this is a workaround given the ATG rush. Default styles should come from the backend.
    }
  },
  computed: {
    requiredFieldsSet() {
      return new Set(this.requiredFields.split(',').map(field => field.trim()))
    },
    listingDetails() {
      if (this.details == null) return ''
      return `${this.details.year} ${this.details.name} `
    },
    contactPhone() {
      return this.details?.locatedAt.phone
    },
    policyHtml() {
      return this.t.policy?.replace('{terms}', `<a href="${this.targetSiteConfig?.termsAndConditionsUrl}" target="_blank" rel="noreferrer">${this.t.terms}</a>`).replace('{policy}', `<a href="${this.targetSiteConfig?.privacyPolicyUrl}" target="_blank" rel="noreferrer">${this.t.privacy}</a>`)
    },
    mainImageUrl() {
      return this.details?.images ? this.imageUrl(this.details?.images?.[0], 200) : ''
    },
    errorQID() {
      return validateQid(this.source, this.leadData?.qid)
    },
    regions() {
      return window.getRegionOnAAM()?.map(region => {
        return {
          id: region,
          name: region
        }
      })
    },
    otherRegion() {
      return this.source === this.sourceAam ? (this.t.otherRegion ?? 't.otherRegion') : ''
    }
  },
  mounted() {
    this.loadDetails()
    this.getUserData()
  },
  methods: {
    rgbToHex(rgb) {
      const [r, g, b] = rgb.match(/\d+/g).map(Number)
      return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`
    },
    getEventAttributes(event){
      const rgb = window.getComputedStyle(event.target).color
      const color = this.rgbToHex(rgb)
      const order = Array.from(event.currentTarget.parentNode.children).indexOf(event.currentTarget)
      const details = {
        element_color: color, 
        element_order: order, 
        event_action: event.type
      }
      return details
    },
    imageUrl(path, size) {
      return `${import.meta.env.VITE_IMAGES_URL}/image/${size}x0/${path}.webp`
    },
    isRequired(field) {
      return this.requiredFieldsSet.has(field)
    },
    getPlaceholder(field) {
      const basePlaceholder = this.t[field] || field
      return this.isRequired(field) ? `${basePlaceholder} *` : basePlaceholder
    },
    async loadDetails() {
      this.loading = true
      this.error = false

      try {
        const queries = ['currentTargetSite { urlConfig { termsAndConditionsUrl privacyPolicyUrl }}']
        if (this.listing) {
          queries.push(`
              listing(id: ${this.listing}) {
                id
                name
                year
                referenceNumber
                targetSiteId
                images
                typeName
                internalNote
                locatedAt { plainAddress phone }
              }
          `)
        }

        const result = await this.queryApi('{ ' + queries.join('\n') + ' }')
        if (this.listing && !result.listing) throw new Error(this.t.errorLoading)
        this.details = result.listing ?? null
        this.targetSiteConfig = result.currentTargetSite.urlConfig
      } catch (error) {
        console.error(error)
        this.error = { message: this.t.errorLoading }
      }
      this.loading = false
    },
    async submitLead(e) {
      if (this.source === this.sourceAam && this.isRequired('region') && !this.leadData.region) {
        this.errorRegion = true
        return
      }
      if (this.errorQID) return
      if (this.loading) return
      this.error = null
      this.errorRegion = false
      const formData = Object.fromEntries(new FormData(e.target))

      const payload = {
        listingId: this.listing ? parseInt(this.listing) : null,
        type: this.details?.typeName ?? 'retail',
        when: new Date().toISOString(),
        ...formData,
        ...(formData.qid && { nationalID: formData.qid })
      }
      if (this.source === this.sourceAam && this.isRequired('region')) {
        payload.region = this.leadData.region
      }
      delete payload.qid
      delete payload.policy
      delete payload.marketing

      this.loading = true
      try {
        await this.queryApi('mutation createLead($payload:LeadInput!) { createLead(input:$payload) { listingId }}', { payload })
        this.$emit('sent', this.leadData)
        this.track('asc_cta_interaction', { old_name: 'lead_sent', element_text: 'Submit lead form', element_subtype: 'cta_button', element_type: 'button', leadData: this.leadData, details: this.details })
        window.dispatchEvent(
          new CustomEvent('sentLead', {
            detail: {
              leadData: this.leadData,
              details: this.details
            }
          })
        )

        this.onSuccess = true
        if (this.leadData.marketingConsent !== this.hasMarketingAccepted) {
          this.onChangeMarketingConsent()
        }
      } catch (error) {
        this.error = { message: this.t.errorSubmiting, type: 'mutation', error }
      }
      this.loading = false
    },
    async onChangeMarketingConsent() {
      try {
        await this.queryApi(`mutation { changeMarketingPreferences(accepted:${this.leadData.marketingConsent}) }`)
      } catch (e) {
        console.log('Error updating marketing consent', e)
      }
    },
    async getUserData() {
      const user = await window.seezSdk.getCurrentUser(null, false)
      if (user) {
        const { me } = await this.queryApi('{ me { firstName middleName lastName acceptedMarketingTerms email phone nationalID address { postalCode }}}')
        this.hasMarketingAccepted = Boolean(me.acceptedMarketingTerms)
        this.leadData = {
          ...me,
          address: me.address ?? { postalCode: null },
          ...(me.nationalID && { qid: me.nationalID })
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../base.scss';
@import '../../defaultStyles';

.leadForm {
  @include theme;
  background-color: var(--background);
  position: relative;

  .detail-showroom {
    display: flex;

    .image {
      width: 7.5rem;
      height: 5rem;
      border-radius: 0.375rem;
      margin-inline-end: 1rem;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: lightgray 50% / cover no-repeat;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .infotext {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      gap: 1rem;
    }
  }

  > .formWrapper {
    text-align: start;
    margin: 0;
    padding: 1rem;

    > .headingWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-block-start: 1.5rem;
      padding-block-end: 1.5rem;
      margin-block-end: 1rem;
      border-bottom: 1px solid black;

      h2 {
        margin: 0;
      }

      span > svg {
        padding-inline-start: 1rem;
      }
      .headingHidden {
        display: none;
      }
    }

    > form {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: 1fr;
      gap: 1rem;

      label[for='qid'] {
        display: none;
      }

      .inputError {
        border-color: red;
      }
      .qidError {
        display: flex;
        align-items: center;
        margin: 0.5rem 0 0 0;
        font-size: 0.8rem;
        color: red;
        > svg {
          margin-inline-end: 0.5rem;
          path {
            fill: red;
          }
        }
      }

      & > * {
        grid-column: span 6 / span 6;
      }

      @media screen and (min-width: 40rem) {
        :nth-child(1),
        :nth-child(2),
        :nth-child(3) {
          grid-column: span 2 / span 2;
        }

        :nth-child(5),
        :nth-child(6) {
          grid-column: span 3 / span 3;
        }
      }

      > label {
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: start;
        align-items: flex-start;

        input,
        textarea {
          width: inherit;
          place-self: center;
        }

        &:has([required]) > span::after {
          content: '*';
          vertical-align: sup;
        }

        &.check {
          flex-direction: row;

          > input {
            width: min-content;
          }
        }
      }

      > .leadBtnSubmit {
        cursor: pointer;
        width: 100%;
        place-self: center;
      }

      > .error {
        color: red;
        text-align: center;
        padding: 0;
        margin: 0;
        font-size: 11px;
      }
    }

    > .info {
      text-align: start;
      border-top: 1px solid black;
      margin: 2rem 0 0 0;

      > p > a {
        color: black;
        font-family: inherit;
      }
    }
  }

  > .success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > .iconWrapper {
      padding: 0.5rem;
      background-color: #b3e1c2;
      border-radius: 999px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1rem 0;
    }

    > p {
      max-width: 511px;
      text-align: center;
    }

    > button {
      margin: 1rem 0;
    }
  }

  .loader {
    position: absolute;
    inset: 0;
  }
}

// start default styles
.leadForm.defaultStyles {
  @include leadForm;
}
// end default styles
</style>
