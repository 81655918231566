<template>
  <div :class="['orderCard', order.state]">
    <img :src="imageUrl" @error="imageError = true" loading="lazy" />
    <!-- <div class="details" :class="{ pb: !cancelable() }"> -->
    <div class="details pb">
      <div>
        <h3>
          <a v-if="toUrl" :href="toUrl">
            {{ order.listing.name }}
            <svg class="titleIcon" width="10" height="11" viewBox="0 0 10 11">
              <path d="M6.25.25C5.9.25 5.63.54 5.63.88 5.63 1.23 5.9 1.5 6.25 1.5H7.85L3.93 5.45C3.67 5.68 3.67 6.09 3.93 6.32 4.16 6.58 4.57 6.58 4.8 6.32L8.75 2.4V4C8.75 4.35 9.02 4.63 9.38 4.63 9.71 4.63 10 4.35 10 4V.88C10 .54 9.71.25 9.38.25H6.25ZM1.56.88C.68.88 0 1.58 0 2.44V8.69C0 9.57.68 10.25 1.56 10.25H7.81C8.67 10.25 9.38 9.57 9.38 8.69V6.5C9.38 6.17 9.08 5.88 8.75 5.88 8.4 5.88 8.13 6.17 8.13 6.5V8.69C8.13 8.86 7.97 9 7.81 9H1.56C1.39 9 1.25 8.86 1.25 8.69V2.44C1.25 2.28 1.39 2.13 1.56 2.13H3.75C4.08 2.13 4.38 1.85 4.38 1.5 4.38 1.17 4.08.88 3.75.88H1.56Z" fill="black" />
            </svg>
          </a>
          <template v-else>{{ order.listing.name }}</template>
        </h3>
        <span>{{ order.listing.variant }} &middot; {{ order.listing.year }} &middot; {{ order.listing.kilometrage.toLocaleString(language) }} {{ t['km'] }}</span>
      </div>
      <div class="price">
        <span>{{ t['price'] }}</span>
        <span v-if="order.listing.retailPrice" class="big">{{ formatPrice(order.listing.retailPrice) }}</span>
      </div>
      <span v-if="order.state !== 'ongoing'" class="status--absolute" :class="['status', order.state]">{{ t[order.state] || order.state }}</span>
      <!-- <a :href="'/checkout?order=' + order.id + '#summary'" class="summary" v-if="['completed', 'ongoing'].includes(order.state)">{{ t['order_summary'] }}</a> -->
      <button v-if="order.state === 'expired'">
        <img src="data:image/svg+xml,%3Csvg width='11' height='10' viewBox='0 0 11 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.1 5.16C10.1 7.64 8.08 9.64 5.62 9.64C4.64 9.64 3.7 9.34 2.92 8.76C2.64 8.54 2.58 8.14 2.8 7.86C3.02 7.58 3.42 7.52 3.7 7.72C4.26 8.14 4.92 8.36 5.62 8.36C7.38 8.36 8.82 6.94 8.82 5.16C8.82 3.4 7.38 1.98 5.62 1.98C4.86 1.98 4.16 2.24 3.58 2.7L4.5 3.6C4.84 3.94 4.58 4.54 4.1 4.54H1.2C0.98 4.54 0.82 4.36 0.82 4.14V1.24C0.82 0.74 1.4 0.5 1.74 0.84L2.68 1.8C3.48 1.1 4.52 0.7 5.62 0.7C8.08 0.7 10.1 2.7 10.1 5.16Z' fill='%230068FF'/%3E%3C/svg%3E%0A" />
        {{ t['restart_order'] }}
      </button>
      <div class="actionButtons">
        <a v-if="order.state === 'ongoing'" :href="checkoutUrl" class="button" @click="trackStartPJ">{{ t['resume_purchase'] }}</a>
        <button v-if="viewable()" class="button" @click="checkout">{{ t['view_order'] }}</button>
        <button v-if="cancelable()" @click="askConfirmation = true" class="button button--cancel">
          <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 10.75C7.78 10.75 9.42 9.81 10.32 8.25 11.21 6.71 11.21 4.81 10.32 3.25 9.42 1.71 7.78.75 6 .75 4.2.75 2.56 1.71 1.66 3.25.77 4.81.77 6.71 1.66 8.25 2.56 9.81 4.2 10.75 6 10.75ZM4.42 4.17C4.59 3.99 4.89 3.99 5.06 4.17L5.98 5.09 6.9 4.17C7.09 3.99 7.39 3.99 7.56 4.17 7.76 4.36 7.76 4.66 7.56 4.83L6.64 5.75 7.56 6.67C7.76 6.86 7.76 7.16 7.56 7.33 7.39 7.53 7.09 7.53 6.9 7.33L5.98 6.41 5.06 7.33C4.89 7.53 4.59 7.53 4.42 7.33 4.22 7.16 4.22 6.86 4.42 6.67L5.34 5.75 4.42 4.83C4.22 4.66 4.22 4.36 4.42 4.17Z" fill="currentColor" />
          </svg>
          {{ _t['cancel_order'] }}
        </button>
      </div>
    </div>

    <div v-if="askConfirmation" class="cancelConfirmation">
      <dialog open>
        <h1>{{ _t['cancel_order_title'] }}</h1>
        <p v-html="_t['cancel_order_description']" />
        <button class="red" @click="confirmCancelation">{{ _t['cancel_order_confirm'] }}</button>
        <button @click="askConfirmation = false">{{ _t['cancel_order_abort'] }}</button>
      </dialog>
    </div>
  </div>
</template>

<script>
import { analyticsMixin, analyticsEvents } from '../../analytics.js'
import { langMixin } from '../lang'
import { slugify } from '../../logic.js'

export default {
  name: 'SeezOrderCard',
  mixins: [langMixin('ORDER_CARD_COMPONENT_TRANSLATIONS'), analyticsMixin],
  props: {
    order: { type: Object, required: true },
    to: { type: String, required: true },
    placeholder: { type: String, default: null },
    listingDetailsUrl: { type: String, default: null }
  },
  emits: ['confirmCancel'],
  data() {
    return {
      events: analyticsEvents,
      imageError: false,
      askConfirmation: false,
      loading: false
    }
  },
  computed: {
    toUrl() {
      let path = ''
      if (this.to == null || this.to === '' || this.order.listing == null) return null
      if (this.listingDetailsUrl) {
        const { model, variant } = this.order.listing
        const family = `${slugify(model.name)}-${slugify(variant)}`
        const brandName = slugify(model.family.brand.name)
        path = this.listingDetailsUrl.replace('{brand}', brandName).replace('{model}-{variant}', family).replace('{id}', this.order.listing.id)
      }
      return path
    },
    _t() {
      return this.languageResources?.ORDERS_COMPONENT_TRANSLATIONS ?? {}
    },
    checkoutUrl() {
      return this.to.replace('{id}', this.order.id)
    },
    summaryAvailable() {
      const states = ['done', 'ongoing', 'confirmed']
      return states.includes(this.order?.state)
    },
    imageUrl() {
      if (this.imageError) return this.placeholder ?? `${import.meta.env.VITE_SEEZ_BASE_URL}/sdk/assets/car-placeholder.webp`
      const d = this.order.listing
      if (d.images && d.images.length > 0) return `${import.meta.env.VITE_IMAGES_URL}/image/400x0/${d.images[0]}.webp`
      return this.placeholder ?? `${import.meta.env.VITE_SEEZ_BASE_URL}/sdk/assets/car-placeholder.webp`
    }
  },
  methods: {
    viewable() {
      return ['confirmed', 'done', 'preparing', 'prepared', 'delivered', 'submitted'].includes(this.order.state)
    },
    cancelable() {
      return ['ongoing', 'preparing', 'submitted', 'confirmed'].includes(this.order.state)
    },
    savePageReferrer() {
      localStorage.setItem('page_referrer', window.location.href)
    },
    trackStartPJ() {
      this.savePageReferrer()
      this.track('asc_cta_interaction', { old_name: 'start_pj', event: 'start_pj', element_text: 'start pj', element_subtype: 'cta_button', element_type: 'button', listingId: this.order.listing.id })
    },
    checkout() {
      this.savePageReferrer()
      window.location = this.checkoutUrl
    },
    confirmCancelation() {
      this.askConfirmation = false
      this.$emit('confirmCancel', this.order.id)
    }
  }
}
</script>

<style lang="scss">
@import '../../base';

.orderCard {
  @include theme;
  --cardHue: 235;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.07);
  border-radius: 0.75rem;
  position: relative;
  transition: transform 0.15s ease-in-out 0s;

  &:hover {
    box-shadow: 0 0 10px #b3b3b399;
  }

  &.cancelled,
  &.expired {
    --cardHue: 0;
  }

  &.completed {
    --cardHue: 180;
  }

  &.onhold {
    --cardHue: 45;
  }

  > img {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    object-fit: cover;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    border-top: 6px solid var(--highlight);
  }

  &.cancelled > img {
    border-color: #ff333a;
  }

  h3 {
    margin: 0 0 1px;
    color: #000000;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.375rem;

    a {
      color: inherit;
      text-decoration: none;
    }

    .titleIcon {
      margin-inline-start: 0.313rem;
    }
  }

  .price {
    margin-block-start: 1.625rem;
    margin-block-end: 0.75rem;
    display: flex;
    flex-direction: row;
    gap: 0.438rem;
    align-items: center;
  }

  span {
    color: #333333;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.063rem;

    &.big {
      color: #000000;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1.375rem;
    }

    &.status {
      border-radius: 2em;
      padding: 0.188rem 0.625rem;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 1.125rem;
      align-self: start;
      color: hsl(var(--cardHue), 100%, 50%);
      background-color: hsl(var(--cardHue), 100%, 90%);
      text-transform: capitalize;

      &.ongoing {
        display: none;
      }

      &.status--absolute {
        position: absolute;
        inset-inline-end: 1rem;
        inset-block-start: 1.25rem;
        z-index: 10;
        pointer-events: none;
      }
    }

    .confirmed {
      background-color: #b3e1c2;
      color: #5ca346;
      border: #5ca346;
    }
  }

  > .summary {
    color: var(--highlight);
    cursor: pointer;
    grid-area: 2 / 2;
    align-self: center;
  }

  > .details {
    padding: 0.875rem 1.063rem 0.313rem;
    &.pb {
      padding-block-end: 1.063rem;
    }

    .actionButtons {
      width: 100%;
      text-align: center;

      > .button {
        text-decoration: none;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-flow: wrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0.532rem 0.5rem;
        border: none;
        border-radius: 2.75rem;
        background-color: var(--highlight);
        color: var(--background);
        cursor: pointer;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.188rem;

        &.button--cancel {
          margin-block-start: 0.188rem;
          font-size: 0.75rem;
          background-color: #ffffff;
          color: #545454;

          &:hover {
            background-color: #f2f2f2;
          }

          svg {
            margin-inline-end: 0.25rem;
            margin-block-start: -2px;
            display: block;
          }
        }
      }
    }
  }

  button {
    grid-area: 2 / 3 / 2 / span 2;
    justify-self: end;
    padding: 0.5em 1.5em;
    border: 1px solid var(--highlight);
    border-radius: 2em;
    background-color: var(--background);
    color: var(--highlight);
    cursor: pointer;
    font-family: 'Biennale';
  }

  .cancelConfirmation {
    position: fixed;
    inset: 0;
    z-index: 11;
    backdrop-filter: blur(2px) brightness(60%);
    display: grid;
    place-items: center;

    > dialog {
      font-size: 1.25em;
      background: var(--background);
      border-radius: 2em;
      display: flex;
      flex-direction: column;
      gap: 0.75em;
      border: none;
      @media screen and (max-width: 50rem) {
        box-sizing: border-box;
        padding: 1rem;
        width: calc(100% - 2rem);
      }
      @media screen and (min-width: 50rem) {
        padding: 2rem 4rem;
        width: 20rem;
      }

      > * {
        margin: 0 auto;
      }

      > p {
        max-width: 35ch;
        text-align: center;
      }

      > button {
        border: none;
        background: none;
        padding: 0.5em;
        width: 100%;
        box-sizing: border-box;
        border-radius: 2em;
        cursor: pointer;
        font-size: 1em;
        font-family: 'Biennale', sans-serif;

        &:hover {
          background-color: #f0f0f0;
        }

        &.red {
          border: 1px solid red;
          color: red;
        }
      }
    }
  }
}
</style>
