<template>
  <div :class="{ carousel: true, defaultStyles: loadDefaultStyles }">
    <div ref="listings" :class="['listings', flowMode]" @scroll="debouncedScroll">
      <listing-card :current-filters="internalFilter" @track="e => $emit('track', e)" :placeholder="placeholder" v-for="l in listings" :key="l.id" :details="l" :to="to" :target="target" @click="listingClicked" @click-payment="e => $emit('click-payment', e)" @compare="e => $emit('compare', e)" :compare-ids="compareIds">
        <template #financingTerms>
          <slot name="financingTerms" />
        </template>
        <template #extra v-if="isExtended">
          <div class="actions">
            <label class="selectorLabel" @click.stop><input type="checkbox" v-model="selectedListings" :value="l.id" /> {{ t.select }}</label>
            <span>{{ t.viewDetails }}</span>
          </div>
        </template>
      </listing-card>
    </div>
    <button v-if="scrollable" @click="movePrev" :class="{ prev: true, flipped: textDirection !== 'rtl' }" :disabled="disabledPrev"><RightIcon /></button>
    <button v-if="scrollable" @click="moveNext" :class="{ next: true, flipped: textDirection !== 'ltr' }" :disabled="disabledNext"><RightIcon /></button>
    <div :class="['actions', flowMode]" v-if="isExtended && !loading">
      <!-- <button v-if="selectedListings.length === 1" @click="drawer = 'test'">{{ t.testDrive }}</button> -->
      <div>
        <button v-if="!scrollable && flowMode == 'row'" @click="movePrev" :class="{ prev: true, flipped: textDirection !== 'rtl' }" :disabled="disabledPrev"><RightIcon /></button>
        <button v-if="!scrollable && flowMode == 'row'" @click="moveNext" :class="{ next: true, flipped: textDirection !== 'ltr' }" :disabled="disabledNext"><RightIcon /></button>
      </div>
      <button v-if="selectedListings.length === 1 && featureLeads" @click="drawer = 'lead'">{{ t.inquire }}</button>
      <button v-if="selectedListings.length > 0" @click="drawer = 'related'">{{ t.viewSimilar }}</button>
      <button v-if="[2, 3].includes(selectedListings.length)" @click="clickOnCompare">{{ t.compare }}</button>
      <!-- <button @click="toggleMode">{{ t.viewAll }}</button> -->
    </div>
    <Loader v-if="loading" />
    <div v-if="drawer" class="drawer">
      <seez-sdk-lead-form v-if="drawer === 'lead'" :listing="selectedListings?.[0]" />
      <seez-sdk-test-drive-form v-if="drawer === 'test'" :listing="selectedListings?.[0]" />
      <seez-sdk-comparison-table v-if="drawer === 'compare'" :ids="selectedListings?.join(',')" />
      <seez-sdk-carousel v-if="drawer === 'related'" extended="true" :filters="`related-to=${selectedListings?.join(',')}`" />
    </div>
  </div>
</template>

<script>
import { langMixin } from '@/components/lang.js'
import { readOnlyModeMixin } from '../readOnlymode.js'
import { parseUrlFilters, formatFilterForApi, debounce } from '@/logic.js'
import SeezSdk from '@/sdk.js'
import { analyticsMixin, analyticsEvents } from '@/analytics.js'
import Loader from '../Loader.ce.vue'
import ListingCard from '../ListingCard2.ce.vue'
import RightIcon from '@/assets/right.svg'

export default {
  name: 'SeezCarousel',
  components: { Loader, ListingCard, RightIcon },
  mixins: [SeezSdk.vueQueryMixin, langMixin('Carousel'), analyticsMixin, readOnlyModeMixin],
  props: {
    filters: { type: String, default: null },
    to: { type: String, default: null },
    target: { type: String, default: null },
    placeholder: { type: String, default: null },
    compareIds: { type: String, default: '' },
    ids: { type: String, default: null },
    extended: { type: [Boolean, String], default: false },
    mode: { type: String, default: 'row' }
  },
  emits: ['loaded', 'select', 'click-payment', 'track', 'compare'],
  data() {
    return {
      listings: [],
      flowMode: this.mode,
      loading: false,
      scrollable: false,
      resizeObserver: new ResizeObserver(this.checkScrollbars),
      disabledPrev: false,
      disabledNext: false,
      selectedListings: [],
      drawer: null,
      loadDefaultStyles: false,
      internalFilter: null
      //FIXME: this is a workaround given the ATG rush. Default styles should come from the backend.
    }
  },
  computed: {
    featureLeads() {
      return import.meta.env.VITE_LEADS_ENABLE
    },
    isExtended() {
      if (typeof this.extended === 'boolean') return this.extended
      if (typeof this.extended === 'string') return ['true', '1'].includes(this.extended.toLowerCase())
      return false
    }
  },
  watch: {
    filters() {
      this.loadListings()
    },
    mode(v) {
      this.flowMode = v
    },
    selectedListings() {
      this.drawer = null
    }
  },
  mounted() {
    this.resizeObserver.observe(this.$refs.listings)
    this.loadListings()
  },
  beforeUnmount() {
    this.resizeObserver.disconnect()
  },
  methods: {
    clickOnCompare(e) {
      this.drawer = 'compare'
      const props = this.getEventAttributes(e)
      this.track('asc_cta_interaction', {  old_name: 'compare', element_text: 'compare cars', element_subtype: 'content', element_type: 'button', ...props })
    },
    async loadListings() {
      this.loading = true
      let result = []

      if (this.ids) {
        const parsedIds = this.ids
          .split(',')
          .filter(x => x !== 'null')
          .map(x => parseInt(x, 10))

        const query = 'query listings($ids: [ID!]) {listingsByIds(ids: $ids) ' + ListingCard.requiredFields + '}'
        result = {
          listings: {
            nodes: (await this.queryApi(query, { ids: parsedIds })).listingsByIds,
            pageInfo: { total: parsedIds.length }
          }
        }
      } else {
        const query = `
          query listings($filter: ListingFiltersInput) {
            listings(filter: $filter) {
              nodes
              ${ListingCard.requiredFields}
              pageInfo {
                total page perPage pages
              }
            }
          }
        `
        const internalFilter = parseUrlFilters(this.filters)
        const apiFilter = formatFilterForApi(internalFilter)
        result = await this.queryApi(query, { filter: apiFilter })
        this.internalFilter = internalFilter
      }

      this.listings = this.filterListings(result.listings.nodes)

      this.$emit('loaded', result.listings.pageInfo)
      this.loading = false
      this.$nextTick(() => {
        this.checkDisabled()
      })
    },
    filterListings(listingsArr) {
      return listingsArr?.filter(listing => listing !== null && listing.state !== 'unavailable')
    },
    checkScrollbars() {
      this.scrollable = this.flowMode === 'row' && this.$el.clientWidth > 440 && this.$refs.listings.clientWidth < this.$refs.listings.scrollWidth
    },
    rgbToHex(rgb) {
      const [r, g, b] = rgb.match(/\d+/g).map(Number)
      return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`
    },
    getEventAttributes(event) {
      let color = null
      let order = null

      if (event.target instanceof Element) {
        const rgb = window.getComputedStyle(event.target).color
        color = this.rgbToHex(rgb)

        if (event.currentTarget instanceof Element && event.currentTarget.parentNode instanceof Element) {
          order = Array.from(event.currentTarget.parentNode.children).indexOf(event.currentTarget)
        }
      }

      const details = {
        element_color: color,
        element_order: order,
        event_action: event.type
      }

      return details
    },
    moveNext(event) {
      const details = this.getEventAttributes(event)
      this.track('asc_cta_interaction', {...details, old_name: analyticsEvents.CTA_CLICK, element_text: 'carousel next', element_subtype: 'cta_button', element_type: 'button', event_action_result: 'slideout'})
      this.$refs.listings.scroll({ top: 0, left: this.$refs.listings.scrollLeft + this.stepSize(), behavior: 'smooth' })
    },
    movePrev(event) {
      const details = this.getEventAttributes(event)
      this.track('asc_cta_interaction', {...details, old_name: analyticsEvents.CTA_CLICK, element_text: 'carousel prev', element_subtype: 'cta_button', element_type: 'button', event_action_result: 'slideout'})
      this.$refs.listings.scroll({ top: 0, left: this.$refs.listings.scrollLeft - this.stepSize(), behavior: 'smooth' })
    },
    stepSize() {
      return (this.$refs.listings?.children?.[0]?.clientWidth ?? 0) * (this.textDirection === 'rtl' ? -1 : 1)
    },
    debouncedScroll: debounce(function () {
      this.handleScroll()
    }, 200),
    handleScroll() {
      const left = this.$refs.listings.scrollLeft
      this.checkDisabled(left)
    },
    checkDisabled(left = 0) {
      const exactLeft = Math.abs(left)

      const scrollWidth = this.$refs.listings?.scrollWidth
      const clientWidth = this.$refs.listings?.clientWidth

      const isReachedLastElement = exactLeft + 1 >= scrollWidth - clientWidth
      const isOnFirstElement = exactLeft < Math.abs(this.stepSize() / 2)

      if (isOnFirstElement) {
        this.disabledPrev = true
        this.disabledNext = false
      } else if (isReachedLastElement) {
        this.disabledPrev = false
        this.disabledNext = true
      } else {
        this.disabledPrev = false
        this.disabledNext = false
      }
    },
    getItemAttributes(details){
      const item = {
        product_name: details?.item?.name,
        affiliation: details?.item?.targetSite?.name,
        item_id: details?.item?.id,
        item_number: details?.item?.licensePlate,
        item_price: details?.item?.retailPrice?.value,
        item_condition: details?.item?.usage,
        item_year: details?.item?.year,
        item_make: details?.item?.model?.family?.brand?.name,
        item_model: details?.item?.model?.name,
        item_variant: details?.item?.variant,
        item_color: details?.item?.color
      }
      return item
    },
    listingClicked(e, details) {
      const props = this.getItemAttributes(details)
      const detail = this.getEventAttributes(e)
      this.track('asc_cta_interaction', { old_name: 'carousel_car_click',  element_text: 'click on Listing', element_type: 'item_details', element_subtype: 'content', ...props, ...detail, event_label: { car_id: details.id }, ids: this.ids })
      this.$emit('select', e, details)
    },
    toggleMode() {
      this.flowMode = this.flowMode === 'row' ? 'grid' : 'row'
    }
  }
}
</script>

<style lang="scss">
@import '../../base';
@import '../../defaultStyles';

.carousel {
  @include theme;

  background-color: transparent;
  position: relative;
  min-height: 10em;
  display: grid;
  grid-template-columns: auto 1fr auto;
  min-width: 64px;

  > button {
    background: none;
    cursor: pointer;
    place-self: center;
    border-radius: 2rem;
    height: 2rem;
    width: 2rem;
    margin: 0.5rem;
    border: 2px solid var(--border-color);
    padding-block: 0.2rem 0;

    &.prev {
      grid-area: 1 / 1;
    }

    &.next {
      grid-area: 1 / 3;
    }

    &.flipped {
      transform: scaleX(-1);
    }

    > svg {
      stroke: var(--border-color);
      width: 0.5rem;
    }
  }

  > div > div > button {
    background: #fff;
    cursor: pointer;
    place-self: center;
    height: 2rem;
    width: 2rem;
    margin: 0.5rem 0.25rem;
    border: none;

    padding-block: 0.2rem 0;

    &.prev {
      grid-area: 1 / 1;
    }

    &.next {
      grid-area: 1 / 3;
    }

    &.flipped {
      transform: scaleX(-1);
    }

    > svg {
      stroke: #000;
      width: 0.5rem;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  > .listings {
    grid-area: 1 / 2;
    gap: 1em;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    scroll-padding-inline: 0.5em;
    overflow: auto;
    padding: 1em;
    scrollbar-width: none;

    &.row {
      display: flex;
    }

    &.grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(20em, 1fr));
    }

    &::-webkit-scrollbar {
      display: none;
    }

    > * {
      scroll-snap-align: start;
      min-width: 20em;
      position: relative;
    }
  }

  > .actions {
    grid-area: 2 / 2 / 2 / 3;
    display: flex;
    flex-wrap: wrap;
    white-space: nowrap;
    gap: 1em;

    > button {
      font-family: var(--font-family);
    }

    // > :last-child {
    //   margin-inline-start: auto;
    // }

    &.grid > :last-child {
      display: none;
    }
  }

  > .loader {
    position: absolute;
    inset: 0;
  }

  > .drawer {
    grid-area: 3 / 1 / 3 / 4;
    place-self: center;
    padding-block-start: 3rem;
    max-width: 100%;
  }
}

// start default styles
.carousel.defaultStyles {
  @include carousel;
}
// end default styles
</style>
