<template>
  <div ref="dateTimeSlot" :data-test-id="testId" class="dateTimeSlot" @click="handleClick">
    <div :class="[{ overflowScroll: !showSlot }, directionClass]">
      <div class="placeholder" v-if="!selectedDate && placeholder && !hidePlaceholder">{{ placeholder }}</div>
      <input ref="dateInput" class="inputDateTimeSlot" v-model="selectedDate" type="date" @input="handleSelection" :placeholder="t.date ?? 'Select a date'" :class="[{ 'has-value': selectedDate }, directionClass]" :min="startDate" :required="required" @keydown="handleKeyDown" @invalid="e => $emit('invalid', e)" :aria-invalid="isDateInvalid" />
      <svg v-if="largeView" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
        <line x1="16" y1="2" x2="16" y2="6" />
        <line x1="8" y1="2" x2="8" y2="6" />
        <line x1="3" y1="10" x2="21" y2="10" />
      </svg>
    </div>
    <span v-if="isDateInvalid" class="errorMessage">
      {{ t.invalidDateMessage || disabledDatesLabel || 'Date is unavailable' }}
    </span>
    <multi-select :class="{ isDateInvalid }" v-if="showSlot" v-model="selectedTimeSlot" :multi="false" :options="Object.values(timeSlots)" @update:model-value="handleSelection" :placeholder="t.selectOption ?? 'Select a time slot'" :required="required" :disabled="isDateInvalid || !selectedDate" />
  </div>
</template>
<script>
import MultiSelect from './MultiSelect.ce.vue'
import { formatDate } from '../logic'
import { langMixin } from './lang'

export default {
  name: 'DateTimeSlot',
  components: { MultiSelect },
  mixins: [langMixin('general')],
  props: {
    testId: { type: String, default: null },
    modelValue: { type: String, default: null },
    timeSlots: { type: Array, default: () => [] },
    startDateDelayInDays: { type: Number, default: null },
    showSlot: { type: Boolean, default: true },
    required: { type: Boolean, default: false },
    placeholder: { type: String, default: null },
    disabledDates: { type: Array, default: () => [] },
    disabledDatesLabel: { type: String, default: '' },
    invalidDateMessage: { type: String, default: null }
  },
  emits: ['update:modelValue', 'invalid', 'date-invalid'],
  data() {
    return {
      selectedDate: '',
      selectedTimeSlot: null,
      internalValue: null,
      largeView: false,
      resizeObserver: null,
      hidePlaceholder: false
    }
  },
  computed: {
    isDateInvalid() {
      return this.disabledDates?.includes(this.internalValue)
    },
    startDate() {
      if (this.startDateDelayInDays !== null) {
        let startDate = new Date()
        startDate.setDate(startDate.getDate() + this.startDateDelayInDays)
        return formatDate(startDate, 'yyyy-mm-dd')
      }
      return null
    },
    directionClass() {
      return document.dir === 'rtl' ? 'rtl' : 'ltr'
    }
  },
  watch: {
    internalValue(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('update:modelValue', newValue)
      }
    }
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        const width = entry.contentRect.width
        this.largeView = width > 155
      }
    })
    this.resizeObserver.observe(this.$refs.dateTimeSlot)

    if (this.modelValue) {
      const modelValueDate = new Date(this.modelValue)
      this.selectedDate = modelValueDate.toISOString().split('T')[0]
      if (this.timeSlots) {
        this.setTimeSlot(modelValueDate)
      }

      this.updateInternalValue()
    }
  },
  beforeUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  },
  methods: {
    handleClick() {
      this.hidePlaceholder = true
    },
    handleKeyDown(e) {
      if (e.key === 'Enter') {
        this.hidePlaceholder = true
      }
      this.hidePlaceholder = true
    },
    setTimeSlot(modelValueDate) {
      const localTime = modelValueDate.getHours()
      const formattedHour = localTime < 10 ? `${localTime}` : `${localTime}`
      if (this.timeSlots.find(ts => ts.startsWith(formattedHour))) {
        this.selectedTimeSlot = this.timeSlots.find(ts => ts.startsWith(formattedHour))
      }
    },
    handleSelection() {
      this.validateAndFixDate()
      this.updateInternalValue()
      this.$emit('update:modelValue', this.internalValue)
      this.$emit('date-invalid', this.isDateInvalid)
    },
    validateAndFixDate() {
      this.isDateInvalid = false
      if (this.selectedDate && this.disabledDates.includes(this.selectedDate)) {
        this.isDateInvalid = true
      }
      let date = new Date(this.selectedDate)

      if (date.getFullYear() > 9999) {
        const today = new Date()
        const currentYear = today.getFullYear()
        date.setFullYear(currentYear)
        this.selectedDate = date.toISOString().split('T')[0]
      }
    },
    updateInternalValue() {
      if (this.selectedDate && this.selectedTimeSlot && !this.isDateInvalid) {
        let dateTime = new Date(this.selectedDate)
        //Handling range like 09:00 - 10:00 or 09:00 only
        const startTime = this.selectedTimeSlot.split(' - ')[0]
        const [hours, minutes] = startTime.split(':').map(Number) // Parse hours and minutes

        dateTime.setHours(hours, minutes, 0, 0)

        this.internalValue = dateTime.toISOString()
      } else if (this.selectedDate && !this.isDateInvalid) {
        this.internalValue = new Date(this.selectedDate).toISOString()
      } else {
        this.internalValue = null
      }
    }
  }
}
</script>

<style>
.dateTimeSlot > div {
  .placeholder {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    z-index: 1;
    pointer-events: none;
    color: #00000061;
    background: transparent;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: white;
    min-width: 6rem;
    padding: 0.3rem 0.5rem;
    right: 1px;
    font-size: 0.8rem;
    max-width: 80%;

    @media screen and (max-width: 50rem) {
      font-size: 1rem;
    }
  }
}

.errorMessage {
  color: red;
  font-size: 0.5rem;
  margin-top: 0.25rem;
  display: block;
}

.dateTimeSlot .overflowScroll {
  overflow: auto;
  position: relative;
}
.dateTimeSlot > div > input[type='date'] {
  -webkit-appearance: none;
  width: -webkit-fill-available;
  appearance: none;
  padding-right: 30px;
  position: relative;
  background: white;
  text-align: start;
  color: black !important;

  @media screen and (max-width: 50rem) {
    min-height: 1.25rem;
  }

  &::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 0;

    width: 40%;
    height: 100%;
    opacity: 0;
    color: transparent;

    cursor: pointer;
    z-index: 999;
  }

  &[aria-invalid='true'] {
    border: 1px solid red;
  }
}

.dateTimeSlot > div {
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    z-index: 2;
    background: white;
  }
}

.dateTimeSlot > div.ltr svg {
  inset-inline-end: 1rem;
}

.dateTimeSlot > div.rtl svg {
  inset-inline-start: 1rem;
}
</style>
