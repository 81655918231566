<template>
  <div class="financingProvider">
    <div v-if="allFinancingOptions">
      <div v-if="allFinancingOptions.length >= 1">
        <span class="loanCardHeading">{{ t['loadCardHeading'] ?? 't.loadCardHeading' }}</span>
        <div v-for="option in allFinancingOptions" :key="option.config?.provider?.name" class="provider">
          <div class="loan-card">
            <div class="radio-circle">
              <input :disabled="loading && option?.config?.provider?.name !== selectedCalculation?.config?.provider?.name" type="radio" :checked="option?.config?.provider?.name === selectedCalculation?.config?.provider?.name" @click="selectLoan(option)" />
            </div>
            <div style="flex-grow: 1">
              <div class="provider-info">
                <h3 class="provider-name">{{ t[option.config?.provider?.name] ?? `t.${option.config?.provider?.name}` }}</h3>
                <div class="monthly-payment-calculation">
                  <div class="tooltipPaymentTitle">
                    <TooltipComponent :message="option.calculation?.disclaimer ?? ''">
                      <InfoIcon />
                    </TooltipComponent>
                  </div>
                  <h3>{{ t['from'] ?? 't.from' }} {{ option.calculation?.monthlyPayment ? formatNumber(option.calculation?.monthlyPayment, 2, currency) : '-' }} x {{ option.calculation?.paymentTerms ?? '-' }} {{ t.months ?? 't.months' }}</h3>
                </div>
              </div>
              <div class="monthly-payment">
                <div class="customize-personalize-rate" @click.stop="customizeLoan(option)">{{ t.personalRate ?? 't.personalRate' }}</div>
                <div class="rate">
                  <div v-for="(rate, key) in option.calculation?.rates" :key="key">
                    <div>{{ t[rate?.key] ?? rate?.key }} {{ Number(rate?.value) }}%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="divider" />
          <div class="loan-card-footer">
            <div class="loan-calculator">
              <div v-for="(loanOption, key) in loanCalculations" :key="key" class="option">
                <div>{{ t[loanOption.label] ?? loanOption.label }} {{ formatNumber(option.calculation ? option.calculation[loanOption.mapValue] : 0, 2, currency) }}</div>
                <div v-if="key !== 2" class="dot">•</div>
              </div>
            </div>
            <div class="disclaimer" v-html="t[`disclaimer_${option.config?.provider?.name}`] ?? `t.disclaimer_${option.config?.provider?.name}`" />
            <div class="financingLogo">
              <span>{{ t.financingFrom ?? 't.financingFrom' }}</span>
              <img :src="`${baseUrl}${providerLogo ?? option.config?.provider?.logo}`" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal v-if="showLoanCalculator" @close="showLoanCalculator = false" :clickable-outside="false">
      <LoanCalculator :order-id="orderId" :financing-option="selectedFinancingOption" :currency="currency" :rates="rates" :loading="loading" @save-calculation="onSaveCalculation" :provider-logo="providerLogo" />
    </Modal>
  </div>
</template>

<script>
import SeezSdk from '../../sdk.js'
import { analyticsMixin } from '../../analytics.js'
import Modal from '../Modal.ce.vue'
import LoanCalculator from './LoanCalculator.ce.vue'
import { langMixin } from '@/components/lang.js'
import TooltipComponent from '@/components/Tooltip.ce.vue'
import InfoIcon from '@/assets/information.svg'

export default {
  name: 'FinancingProvider',
  components: { InfoIcon, TooltipComponent, LoanCalculator, Modal },
  mixins: [SeezSdk.vueQueryMixin, analyticsMixin, langMixin('FINANCING_PROVIDER_COMPONENT_TRANSLATIONS')],
  props: {
    orderId: { type: String, required: true },
    selectedFinancingOption: { type: Object, required: true },
    allFinancingOptions: { type: Array, default: null },
    currency: { type: String, default: 'EUR' },
    loading: { type: Boolean, default: false },
    openLoanCalculator: { type: Boolean, default: false },
    rates: { type: Object, default: null },
    providerLogo: { type: String, default: null }
  },
  emits: ['saveCalculation'],
  data() {
    return {
      selectedCalculation: this.allFinancingOptions?.find(e => e.selected),
      showLoanCalculator: this.openLoanCalculator,
      calculations: null,
      loanCalculations: [
        { label: 'downPayment', mapValue: 'downPayment' },
        { label: 'totalCreditAmount', mapValue: 'loanAmount' },
        { label: 'totalLoadAmount', mapValue: 'totalLoanCost' }
      ]
    }
  },
  computed: {
    baseUrl() {
      return import.meta.env.VITE_SEEZ_BASE_URL
    }
  },
  watch: {
    allFinancingOptions(v) {
      if (v.length > 0) {
        this.selectedCalculation = this.allFinancingOptions?.find(e => e.selected)
      }
    }
  },
  methods: {
    selectLoan(option) {
      if (this.loading) return
      this.selectedCalculation = this.allFinancingOptions?.find(e => e?.config?.provider?.name === option?.config?.provider?.name)
      this.$emit('saveCalculation', option?.calculation, option?.config?.provider?.name)
    },
    customizeLoan(option) {
      if (this.loading) return
      if (this.selectedCalculation?.config?.provider?.name === option?.config?.provider?.name) {
        this.showLoanCalculator = true
      }
    },
    onSaveCalculation(e) {
      this.$emit('saveCalculation', e)
    }
  }
}
</script>

<style lang="scss">
.provider {
  border: 2px solid #1b2021;
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 20px;
  .loan-card {
    display: flex;
    align-items: center;
    cursor: pointer;

    .radio-circle {
      margin-inline-end: 10px;
    }

    .provider-info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      h3 {
        margin: 0.5rem 0;

        &:last-of-type {
          margin: 0.2rem 0;
        }

        @media screen and (min-width: 50rem) {
          margin: 0;

          &:last-of-type {
            margin: 0;
          }
        }
      }

      @media screen and (min-width: 50rem) {
        flex-direction: row;
        justify-content: unset;
        align-items: unset;
      }

      .provider-name {
        font-weight: bold;
        word-break: break-word;
      }
      .monthly-payment-calculation {
        display: flex;
        align-items: center;
        font-weight: bold;

        @media screen and (min-width: 50rem) {
          margin-inline-start: auto;
        }

        .tooltipPaymentTitle {
          font-weight: 400;
          margin: 4px 2px 0px 2px;
          svg > g > path {
            fill: black;
          }
        }
        @media (max-width: 405px) {
          h3 {
            flex-basis: 70px;
            margin-right: 14px;
          }
        }
      }
    }

    .monthly-payment {
      display: flex;
      align-items: center;
      .customize-personalize-rate {
        text-decoration: underline;
        cursor: pointer;
      }
      .rate {
        margin-inline-start: auto;
        display: flex;
        width: max-content;
        flex-wrap: wrap;
        font-size: 1.1rem;
        font-weight: 700;

        @media (max-width: 478px) {
          display: block;
        }

        > div {
          text-align: center;
          border-radius: 20px;
          border: 1px solid #c6c9ca;
          margin-right: 4px;
          margin-top: 4px;
          padding: 4px 12px;
        }
      }
    }
  }
  .divider {
    margin-top: 12px;
    border: 1px solid #c6c9ca;
  }
  .loan-card-footer {
    .loan-calculator {
      display: flex;
      flex-wrap: wrap;
      .option {
        display: flex;
        font-weight: 600;
        font-size: 16px;
        color: #3d4345;
        .dot {
          margin: 0px 20px;
        }
      }
    }
    .disclaimer {
      margin-top: 4px;
      font-size: 14px;
      font-weight: 300;
      color: #3d4345;
    }
    .financingLogo {
      margin-top: 24px;
      display: flex;
      align-items: center;
      span {
        font-weight: 700;
        font-size: 12px;
      }
      img {
        margin-left: 4px;
        height: 12px;
      }
    }
  }
}
</style>
