<template>
  <div v-if="order" class="buyingFlow">
    <div>
      <Loader v-if="isUpdating('summary')" />
      <div class="salesComponents">
        <div class="header">
          <div class="left">
            <div class="order">
              <div>{{ t.order ?? 't.order' }}</div>
              <div>{{ `#${order.id}` }}</div>
            </div>
          </div>
          <div :class="`${order.state === 'submitted' ? 'orange' : ''} ${order.state === 'confirmed' ? 'green' : ''} status`">
            <span />
            <span class="text">{{ t[order.state] }}</span>
          </div>
        </div>
        <div class="saveOrderInfo">
          <div class="infoIcon" />
          <p>
            {{ t.savedOrderMessage ?? 't.savedOrderMessage' }}
            <button data-test-id="buttonClickMyProfile" @click="onClickRouteToMyProfile">
              {{ t.myProfile ?? 't.myProfile' }}
              <div class="linkIcon" />
            </button>
          </p>
        </div>
        <div v-if="currentStep === 'summary' && order.state === 'ongoing'">
          <OrderReview :order="order" @on-edit-component="editComponent" />
        </div>
        <div v-else-if="isOrderSubmitted">
          <PostSubmitView
            :order="order"
            :order-loaded="loaded"
            @on-financing-updated="f => (order.payment.financing = f)"
            @on-trade-in-offer-updated="onTradeInOfferUpdate"
            @on-documents-updated="docs => (order.documents = docs)"
            @credit-card-transfer="$emit('creditCardTransfer', order)"
            @persist-customer-info="persistCustomerInfoInput"
            @on-copy-transfer-details="copiedText => $emit('onCopyTransferDetails', copiedText)"
            @on-customer-info-update="
              o => {
                order.customerInfo = o.customerInfo
                order.coOwnerInfo = o.coOwnerInfo
              }
            "
          />
        </div>
        <div v-else>
          <div v-for="salesComponent in order.appState.steps" :key="salesComponent.name">
            <details :ref="salesComponent.name" v-if="salesComponent.name === 'customerInfo'" :open="isCurrent(salesComponent.name)" @toggle="e => onToggle(e, salesComponent.name)">
              <summary :class="`${salesComponent.state}`">
                <div class="customerInfoIcon" />
                <div>
                  <h3>{{ t.customerInfoTitle ?? 't.customerInfoTitle' }}</h3>
                  <p>{{ t.customerInfoSubtitle ?? 't.customerInfoSubtitle' }}</p>
                </div>
              </summary>
              <div>
                <CustomerInfo :order="order" @persist-input="persistCustomerInfoInput" />
              </div>
              <div class="saveButton">
                <button v-if="!isUpdating(salesComponent.name)" @click="updateSalesComponent(salesComponent.name)" class="save">{{ t.save ?? 't.save' }}</button>
                <Loader v-if="isUpdating(salesComponent.name)" />
              </div>
            </details>

            <details :ref="salesComponent.name" v-else-if="salesComponent.name === 'tradeIn'" :open="isCurrent(salesComponent.name)" @toggle="e => onToggle(e, salesComponent.name)">
              <summary :class="`${salesComponent.state}`">
                <div class="tradeInIcon" />
                <div>
                  <div class="tradeInInfoTitle">
                    <h3>{{ t.tradeInInfoTitle ?? 't.tradeInInfoTitle' }}</h3>
                    <div class="tradeInInfoTitleTooltip">
                      <TooltipComponent :message="t.tradeInDes ?? 't.tradeInDes'">
                        <InfoIcon />
                      </TooltipComponent>
                    </div>
                  </div>
                  <p>{{ t.tradeInInfoTitleSubtitle ?? 't.tradeInInfoTitleSubtitle' }}</p>
                </div>
              </summary>
              <div class="tradeIn">
                <OrderTradeIns @click-go-to-trade-in="$emit('click-go-to-trade-in')" @edit-trade-in="onClickEditTradeIn" :show-trade-ins="willTradeIn" :order="order" :trade-ins="tradeIns" @persist-input="persistTradeInInput" @create-new-trade-in="showTradeInModal = true" @assigned-trade-in="tradeInAssigned" />
                <Modal v-if="showTradeInModal" @close="onTradeInModalClose" :closable="!loading">
                  <slot name="tradeInFlow">
                    <TradeInForm :loading="loading" :selected="selectedTradeIn" :order-id="order.id" :layout-template="order?.listing?.owner?.layoutTemplates?.find(t => t.name === 'tradeIn')" @on-save="onCreateTradeIn" @on-edit="onEditTradeIn" />
                  </slot>
                </Modal>
              </div>
              <div class="saveButton">
                <button v-if="!isUpdating(salesComponent.name)" @click="updateSalesComponent(salesComponent.name)" class="save">{{ t.save ?? 't.save' }}</button>
                <Loader v-if="isUpdating(salesComponent.name)" />
              </div>
            </details>

            <details :ref="salesComponent.name" v-else-if="salesComponent.name === 'customization'" :open="isCurrent(salesComponent.name)" @toggle="e => onToggle(e, salesComponent.name)">
              <summary :class="`${salesComponent.state}`">
                <div class="addonsIcon" />
                <div>
                  <h3>{{ t.addonsTitle ?? 't.addonsTitle' }}</h3>
                  <p>{{ t.addonsSubtitle ?? 't.addonsTitle' }}</p>
                </div>
              </summary>
              <div>
                <OrderAddons :order="order" :addons="addons" @persist-input="persistCustomizationInput" @addons-updated="onAddonsUpdated" />
              </div>
              <div class="saveButton">
                <button v-if="!isUpdating(salesComponent.name)" @click="updateSalesComponent(salesComponent.name)" class="save">{{ t.save ?? 't.save' }}</button>
                <Loader v-if="isUpdating(salesComponent.name)" />
              </div>
            </details>

            <details class="delivery" :ref="salesComponent.name" v-else-if="salesComponent.name === 'delivery'" :open="isCurrent(salesComponent.name)" @toggle="e => onToggle(e, salesComponent.name)">
              <summary :class="`${salesComponent.state}`">
                <div class="deliveryInfoIcon" />
                <div>
                  <h3>{{ t.deliveryInfoTitle ?? 't.deliveryInfoTitle' }}</h3>
                  <p>{{ t.deliveryInfoSubtitle ?? 't.deliveryInfoSubtitle' }}</p>
                </div>
              </summary>
              <div>
                <DeliveryInfo :order="order" @persist-input="persistDeliveryInput" :location-link="locationLink" />
              </div>
              <div class="saveButton">
                <button v-if="!isUpdating(salesComponent.name)" @click="updateSalesComponent(salesComponent.name)" class="save">{{ t.save ?? 't.save' }}</button>
                <Loader v-if="isUpdating(salesComponent.name)" />
              </div>
            </details>

            <details :ref="salesComponent.name" v-else-if="salesComponent.name === 'payment'" :open="isCurrent(salesComponent.name)" @toggle="e => onToggle(e, salesComponent.name)">
              <summary :class="`${salesComponent.state}`">
                <div class="paymentOptionsIcon" />
                <div>
                  <h3>{{ t.paymentOptionsTitle ?? 't.paymentOptionsTitle' }}</h3>
                  <p>{{ t.paymentOptionsSubtitle ?? 't.paymentOptionsSubtitle' }}</p>
                </div>
              </summary>
              <div>
                <PaymentOptions :order="order" @update-order="updateOrder" @payment-updated="paymentUpdated" @insurance-updated="insuranceUpdated" />
              </div>
              <div class="saveButton">
                <button v-if="!isUpdating(salesComponent.name)" @click="updateSalesComponent(salesComponent.name)" class="save">{{ t.save ?? 't.save' }}</button>
                <Loader v-if="isUpdating(salesComponent.name)" />
              </div>
            </details>
          </div>
        </div>

        <div class="actionButtons">
          <button v-if="order.state === 'ongoing' && currentStep === 'summary'" class="submit" @click="onClickSubmit">{{ t.submitOrder ?? 't.submitOrder' }}</button>
          <button v-if="order.state === 'ongoing' && currentStep !== 'summary'" :class="['review', orderStepsPending ? 'disabled' : '']" @click="reviewOrder" :disabled="orderStepsPending">{{ t.reviewOrder ?? 't.reviewOrder' }}</button>
          <button v-show="order.state !== 'confirmed' && order.state !== 'submitted'" @click="togglePauseOrderModal" class="pauseOrderBtn">
            <div />
            {{ t.pauseOffer ?? 't.pauseOffer' }}
          </button>
        </div>
      </div>
    </div>
    <PricingSummary :contact="parsedContact" :order="order" @toggle-cancel-modal="showCancelModal = true" @toggle-pause-order-modal="togglePauseOrderModal" @show-extension-modal="showExtensionModal = true" @reservation-warning="reservationWarning">
      <button v-show="order.state !== 'confirmed' && order.state !== 'submitted'" @click="togglePauseOrderModal" class="pauseOrderBtn">
        <div />
        {{ t.pauseOffer ?? 't.pauseOffer' }}
      </button>
      <button v-if="order.state === 'ongoing' && currentStep === 'summary'" class="submit" @click="onClickSubmit">{{ t.submitOrder ?? 't.submitOrder' }}</button>
      <button v-if="order.state === 'ongoing' && currentStep !== 'summary'" :class="['review', orderStepsPending ? 'disabled' : '']" @click="reviewOrder" :disabled="orderStepsPending">{{ t.reviewOrder ?? 't.reviewOrder' }}</button>
    </PricingSummary>
  </div>
  <Modal v-if="!loading && showExtensionModal && order.reservation" class="reservationExtensionModal" @close="showExtensionModal = false">
    <ReservationExtension :source-id="id" :redirect-url="getRedirectUrl" :reservation-price="order.listing?.reservationConfig?.orderReservationExtensionPrice" :end-date="order.reservation?.endDate" @redirect="redirect" :extendable="canExtendReservation" :missing-info="reservationMissingInfo" @close="showExtensionModal = false" />
  </Modal>

  <Modal v-if="pauseOrderModal" :closable="false" @close="togglePauseOrderModal" class="pauseOrderModal">
    <PauseOrderModal @toggle-pause-order-modal="togglePauseOrderModal" @pause-order="onPauseOrder" />
  </Modal>

  <Modal v-if="orderPausedModal" @close="toggleOrderPausedModal" class="orderPausedMOdal" :closable="false">
    <div class="pauseOrderModal">
      <div>
        <h3>{{ t.orderPaused ?? 't.orderPaused' }}</h3>

        <div class="cancelButtons">
          <button class="keepOfferBtn" @click="redirectToProfilePage">{{ t.goToMyProfile ?? 't.goToMyProfile' }}</button>
          <button class="pauseOfferBtn" @click="onResumeOffer">{{ t.resumeOffer ?? 't.resumeOffer' }}</button>
        </div>
      </div>
    </div>
  </Modal>

  <Modal v-if="!loading && showDepositModal" class="depositModal" @close="showDepositModal = false">
    <Deposit :source-id="id" :redirect-url="getRedirectUrl" @redirect="redirect" :deposit-config="order.depositConfig" />
  </Modal>
  <Modal v-if="!loading && (showCancelModal || order?.state === 'cancelled')" :closable="false" class="cancelModal" @close="showCancelModal = false">
    <div class="cancelModal">
      <div v-if="order.state !== 'cancelled'">
        <h3>{{ t.areYouSureYouWantToCancel ?? 't.areYouSureYouWantToCancel' }}</h3>
        <span>{{ t.youWillBeCanceling ?? 't.youWillBeCanceling' }}</span>
        <div class="cancelButtons">
          <button class="yes" @click="cancelOrder">{{ t.cancelOrder ?? 't.cancelOrder' }}</button>
          <button class="no" @click="showCancelModal = false">{{ t.keepOrder ?? 't.keepOrder' }}</button>
        </div>
      </div>
      <div v-if="order.state === 'cancelled'" class="orderCancelled">
        <h3>{{ t.orderCancelled ?? 't.orderCancelled' }}</h3>
        <button class="home" @click="goToHome">{{ t.goToHome ?? 't.goToHome' }}</button>
      </div>
      <Loader v-if="cancellingOrder" />
    </div>
  </Modal>
  <Modal v-if="externalPaymentId" :closable="false">
    <div class="externalPaymentModal">
      <SpinnerWheel />
      <h3>{{ t.confirmingPayment ?? 't.confirmingPayment' }}</h3>
    </div>
  </Modal>
  <Modal v-if="externalPaymentError">
    <div class="externalPaymentError">
      <ErrorIcon />
      <h3 v-if="externalPaymentResult === '#externalpending'">{{ t.errorConfirmingExtension ?? 't.errorConfirmingExtension' }}</h3>
      <h3 v-else>{{ t.errorConfirmingPayment ?? 't.errorConfirmingPayment' }}</h3>
      <button class="submit" @click="onClickTryAgain">{{ t.tryAgain ?? 't.tryAgain' }}</button>
    </div>
  </Modal>
  <Modal v-if="!loading && showExternalPaymentResultModal" class="externalPaymentResultModal" @close="setExternalPaymentResult(null)">
    <Deposit @click-extend-payment-again="clickExtendPayment" :source-id="id" :redirect-url="getRedirectUrl" @redirect="redirect" :deposit-config="order.depositConfig" :external-payment-result="externalPaymentResult" @submit-order="submitOrder" />
  </Modal>
  <Loader v-if="loading" />
  <ToastComponent :ref="instance => toastInstance().set(instance)" />
</template>

<script>
import Loader from '../Loader.ce.vue'
import Modal from '../Modal.ce.vue'
import PricingSummary from './PricingSummary.ce.vue'
import ReservationExtension from '../ExternalPayments/ReservationExtension.ce.vue'
import Deposit from '../ExternalPayments/Deposit.ce.vue'
import CustomerInfo from './Forms/CustomerInfo.ce.vue'
import OrderTradeIns from './Forms/TradeIn/OrderTradeIns.ce.vue'
import DeliveryInfo from './Forms/DeliveryInfo.ce.vue'
import PaymentOptions from './Forms/PaymentOptions.ce.vue'
import TradeInForm from '../TradeInForm/TradeInForm.ce.vue'
import OrderAddons from './Forms/OrderAddons.ce.vue'
import OrderReview from './OrderReview.ce.vue'
import SeezSdk from '../../sdk.js'
import { langMixin } from '../lang'
import { analyticsMixin } from '../../analytics.js'
import SpinnerWheel from '../../assets/spinner-wheel.svg'
import ErrorIcon from '../../assets/error-icon.svg'
import InfoIcon from '../../assets/information.svg'
import TooltipComponent from '../Tooltip.ce.vue'
import ToastComponent from '@/components/Toast.vue'
import toastInstance from '@/components/toastInstance.js'
import PauseOrderModal from './PauseOrderModal.ce.vue'
import PostSubmitView from './PostSubmitView.ce.vue'

export default {
  name: 'SeezBuyingFlow',
  components: {
    ToastComponent,
    Loader,
    SpinnerWheel,
    ErrorIcon,
    Modal,
    PricingSummary,
    ReservationExtension,
    Deposit,
    CustomerInfo,
    OrderTradeIns,
    DeliveryInfo,
    PaymentOptions,
    TradeInForm,
    OrderAddons,
    OrderReview,
    PostSubmitView,
    InfoIcon,
    TooltipComponent,
    PauseOrderModal
  },
  mixins: [langMixin('BUYING_FLOW_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin, analyticsMixin],
  props: {
    id: { type: Number, required: true },
    homeUrl: { type: String, default: '/' },
    baseUrl: { type: String, default: window.location.host },
    externalPaymentComplete: { type: Boolean, default: false },
    closeModal: { type: Boolean, default: false }, // used to close the Modal that holds a slot for the TradeIn form
    locationLink: { type: String, default: null }
  },
  emits: ['onSubmitOrder', 'creditCardTransfer', 'onCopyTransferDetails', 'onClickMyProfile', 'click-go-to-trade-in', 'toggle-pause-order-modal'],
  data() {
    return {
      loading: false,
      showCancelModal: null,
      showExtensionModal: null,
      disableReservationWarning: (this.order?.reservation?.externalPayment?.amount !== null && this.order?.reservation?.externalPayment?.amount !== undefined) ?? false,
      showDepositModal: null,
      showTradeInModal: false,
      showExternalPaymentResultModal: false,
      externalPaymentResult: null,
      newTradeIn: false,
      cancellingOrder: false,
      order: null,
      tradeIns: null,
      addons: null,
      showrooms: null,
      componentUnderReview: null,
      customerInfoInput: null,
      coOwnerInfoInput: null,
      tradeInInput: null,
      willTradeIn: null,
      deliveryInput: null,
      customizationInput: null,
      loaded: false,
      // interval handler
      externalPaymentStatusLoading: false,
      externalPaymentError: false,
      intervalId: null,
      externalPaymentId: '',
      timeoutId: null,
      selectedTradeIn: null,
      extendedPaymentError: false,
      pauseOrderModal: false,
      orderPausedModal: false
    }
  },
  computed: {
    env() {
      return import.meta.env.VITE_ENV_LABEL
    },
    paymentMethodQuery() {
      return 'key name breakdowns {key name products {key name price description} total}'
    },
    orderQuery() {
      return `
        id state
        createdOn
        externalPayments { id amount status provider providerId sourceType }
        appState { current steps { name state errorsObject { key value { message isRequired isValidated nestedErrors { key value { message isRequired isValidated }}}}}}
        customerInfo { firstName middleName lastName email phone insurance nationalID dateOfBirth customAttributes { key value } address { street street2 postalCode province city state country streetName streetNumber floor door zone buildingNumber }}
        coOwnerInfo { firstName middleName lastName email phone insurance nationalID dateOfBirth customAttributes { key value } address { street street2 postalCode province city state country streetName streetNumber floor door zone buildingNumber }}
        dealerSalesRep { name }
        pricing { financing {${this.paymentMethodQuery}} cashPayment {${this.paymentMethodQuery}} externalPaymentsTotal adminFeesTotal adminFees { name amount currency requiredFor } currency discount vehiclePrice addonsPrice valueAddedServicesPrice deliveryPrice licensePlatePrice tradeInAmount tradeInOffer tradeInAdminFee tradeInReserveAmount tradeInBalance tradeInOfferGross tradeInFinancing tradeInReturnToCustomer subtotal total final }
        spa { id state customerSigned coOwnerSigned salesRepSigned }
        insurance { id state price }
        listing {
          id name variant year images kilometrage hp
          model {family { brand { name }}}
          fuelType { name }
          targetSite {
            id
            brandingHighlightColor
            customerSupport { email phoneNumber image }
            insurance { providers }
          }
          owner {
            id
            currency
            phone
            email
            paymentOptions { name type enabled }
            financing { logo }
            layoutTemplates {
              name
              fields { name type required area regex values visible query placeholder infoLabel question startDateDelay function lookupGeneratorFunction enabled fieldEnablerFunction {args function} requiredNumberOfFiles fields {
                  name type required area regex values visible query placeholder infoLabel question startDateDelay function lookupGeneratorFunction enabled fieldEnablerFunction {args function} requiredNumberOfFiles fields {
                    name type required area regex values visible query placeholder infoLabel question startDateDelay function enabled lookupGeneratorFunction fieldEnablerFunction {args function} requiredNumberOfFiles
                  }
                }
              }
            }
            personalDocuments { name label documents { name type label requiredFor extraInfo downloadUrl }}
            tradeInDocuments { name label documents { name type label requiredFor extraInfo downloadUrl }}
            preDeliveryDocuments { name label documents { name type label requiredFor extraInfo downloadUrl }}
            financingDocuments { name label documents { name type label requiredFor extraInfo downloadUrl }}
            creditCardTransferOptions { merchantCode requestPaymentUrl }
          }
          locatedAt { id name phone bank { name account registration swiftCode iban  }}
          referenceNumber
          addons { id name description price currency financeable discountable category { id name }}
          reservationConfig { orderReservationExtensionPrice { value currency }}
        }
        tradeIn { ${this.tradeInQuery} }
        spa { id state documentId customerSigned coOwnerSigned salesRepSigned previewHTML }
        payment {
          type
          state
          bankTransfer { state }
          financing {
            state
            redirectUrl
            selected
            errors { field errorCode }
            userInput { selectedProvider downPayment interestType paymentTerms }
            config { interestTypes terms maxPaymentTerms downPayment { min max default } provider { name logo }}
            calculation {
              apr
              aopBeforeTax
              financedAmount
              loanAmount
              downPayment
              downPaymentPct
              totalPayable
              totalLoanCost
              paymentTerms
              monthlyPayment
              nominalInterestRate
              interestType
              interestRate
              disclaimer
              rates { key value }
              customAttributes {
                key
                value
              }
            }
          }
          financingOptions {
            config { interestTypes terms maxPaymentTerms downPayment { min max default } provider { name logo }}
            selected
            calculation {
              apr
              aopBeforeTax
              financedAmount
              loanAmount
              downPayment
              downPaymentPct
              totalPayable
              totalLoanCost
              paymentTerms
              monthlyPayment
              nominalInterestRate
              interestType
              interestRate
              disclaimer
              rates { key value }
            }
          }
        }
        customization { addons { id name price }}
        delivery { type deliveryTypes preferredDate showroomId location { id } address { street street2 streetName streetNumber postalCode city state country zone buildingNumber }}
        documents { name fileName fileSize fileType createdOn type }
        reservation { id isActive sourceId sourceType startDate endDate externalPayment { id amount currency status paymentInfo { scf { providerUrl }}}}
        reservations { id isActive sourceId sourceType startDate endDate externalPayment { id amount currency status paymentInfo { scf { providerUrl }}}}
        depositConfig { orderDepositAmount orderDepositCurrency }
        deposits { id amount sourceType externalPayment { id amount status }}
        insuranceData { provider multiple selectedProvider selectedOptionIds options { id name parentId description amount currency price { amount currency min max text } group link }}
      `
    },
    tradeInQuery() {
      return `id
        state
        orderId
        converted
        pictures { url type }
        listingId
        listing { state brand { name } model { name } variant }
        order { id state }
        make
        model
        variant
        year
        kilometrage
        color
        engine
        fuelType
        transmission
        seats
        registrationType
        registrationDate
        carCondition
        accident
        accidentType
        numberOfAccidents
        serviceType
        numberOfKeys
        customerComment
        currency
        offerAmount
        totalAmount
        deduction
        maxExternalValuationPrice
        outstandingFinanceAmount
        customer { id }
        customAttributes { key value }
        vin
        licensePlate
        hasFinancing
        driveType
        postalCode
        targetSite { id }
      `
    },
    currentStep() {
      return this.order.appState.current
    },
    isOrderSubmitted() {
      return ['submitted', 'confirmed', 'done', 'preparing', 'prepared', 'delivered'].includes(this.order.state)
    },
    orderStepsPending() {
      if (this.order.appState.steps?.find(s => s.name !== 'summary' && s.state !== 'complete')) {
        return true
      }

      return false
    },
    getRedirectUrl() {
      return `${this.baseUrl}/order/${this.id}`
    },
    reservationMissingInfo() {
      const firstName = this.order?.customerInfo?.firstName
      const lastName = this.order?.customerInfo?.lastName
      const email = this.order?.customerInfo?.email

      if (!firstName || !lastName || !email) {
        return true
      }
      return false
    },
    canExtendReservation() {
      // Array that contains externalPayment data such as order deposit or extend order deposit
      const externalPaymentsArr = this.order.externalPayments ?? []
      // Find the payments that are related to order extension and check which one of those is authorized. That means the fee has already been payed and this order is no longer extandable
      const hasPayedExtensionDeposit = externalPaymentsArr.filter(payment => payment.sourceType === 'orderVehicleReservationExtension').some(payment => payment.status === 'authorized')

      const reservationPrice = this.order?.listing?.reservationConfig?.orderReservationExtensionPrice
      return reservationPrice && this.order?.reservation?.externalPayment?.amount !== reservationPrice.value && !hasPayedExtensionDeposit
    }
  },
  watch: {
    async closeModal(v) {
      if (v === 'true') {
        this.onTradeInModalClose()
      }
    }
  },
  mounted() {
    this.loadOrder()
  },
  methods: {
    toggleOrderPausedModal() {
      this.orderPausedModal = !this.orderPausedModal
    },
    togglePauseOrderModal() {
      this.pauseOrderModal = !this.pauseOrderModal
    },
    redirectToProfilePage() {
      window.location = '/me/order?tab=paused'
    },
    onClickRouteToMyProfile() {
      this.$emit('onClickMyProfile', this.order.id)
    },
    clickExtendPayment() {
      this.setExternalPaymentResult(null)
      this.showExtensionModal = true
    },
    toastInstance() {
      return toastInstance
    },
    onToggle(e, salesComponentName) {
      if (salesComponentName !== this.currentStep) {
        if (e.newState === 'open') {
          this.updateSalesComponent(this.currentStep, salesComponentName, false)
        }
      }
    },
    checkAndSaveExternalPaymentId() {
      const url = new URL(window.location.href)
      const queryParams = url.searchParams

      if (queryParams.has('externalPaymentId')) {
        this.externalPaymentId = queryParams.get('externalPaymentId')
        queryParams.delete('externalPaymentId')

        window.history.pushState({}, '', url.toString())
      }
    },
    async loadOrder() {
      try {
        this.loading = true
        const query = `query getOrder($id: ID!) { order (id: $id) {${this.orderQuery}}}`
        const { order } = await this.queryApi(query, { id: this.id })
        this.order = order
        this.loaded = true
        this.willTradeIn = this.order.tradeIn !== null

        if (this.order.state === 'paused') {
          this.orderPausedModal = true
        }
        // Set external payment result to show deposit success popup
        this.setExternalPaymentResult(window.location.hash)

        if (this.order.appState.steps.find(s => s.name === 'tradeIn')) {
          await this.loadTradeIns()
        }

        if (this.order.appState.steps.find(s => s.name === 'customization')) {
          await this.loadAddons()
        }

        // Show reservation timer popup to indicate the car reservation has started
        this.reservationStarted()
        this.setStyles()
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    // START TRADE-IN REGION
    // method to display the modal and pass the trade-in
    onClickEditTradeIn(tradeInId) {
      const selected = this.tradeIns.find(i => i.id === tradeInId)
      if (selected) {
        this.selectedTradeIn = selected
        this.showTradeInModal = true
      } else {
        console.log('Error on finding the selected tradeIn')
      }
    },
    async onResumeOffer() {
      this.loading = true
      const query = `
        mutation reActivateOrder($orderId: ID!) {
          reActivateOrder(orderId: $orderId) {
            id
            createdOn
            state
          }
        }
      `

      const variables = {
        orderId: this.order.id
      }

      try {
        const res = await this.queryApi(query, variables)
        this.orderPausedModal = false
        location.reload(true)
      } catch (err) {
        this.orderPausedModal = false
        this.toastInstance().get().showToast({
          type: 'error',
          content: err.message
        })
      } finally {
        this.loading = false
      }
    },

    async onPauseOrder() {
      this.loading = true
      const query = `
        mutation pauseOrder($orderId: ID!) {
          pauseOrder(orderId: $orderId) {
            id
            createdOn
          }
        }
      `

      const variables = {
        orderId: this.order.id
      }

      try {
        const res = await this.queryApi(query, variables)
        this.pauseOrderModal = false
        this.orderPausedModal = true
      } catch (err) {
        this.pauseOrderModal = false
        this.toastInstance().get().showToast({
          type: 'error',
          content: err.message
        })
      } finally {
        this.loading = false
      }
    },
    // method to execute the update trade-in mutation
    async onEditTradeIn(tradeIn) {
      this.loading = true
      const tradeInInput = this.formatTradeInInput(tradeIn)
      const query = `
        mutation updateTradeIn($id: ID!, $input: TradeInInput!) {
          updateTradeIn(id: $id, input: $input) {
            ${this.tradeInQuery}
          }
        }
      `

      const variables = {
        id: tradeIn.id,
        input: tradeInInput
      }

      try {
        const { updateTradeIn } = await this.queryApi(query, variables)
        if (updateTradeIn) {
          await this.loadTradeIns()
        }
      } catch (e) {
        console.log('There was an error on editing trade-in', e)
      } finally {
        this.loading = false
        this.showTradeInModal = false
      }
    },
    parseCustomAttributes(tradeIns) {
      return tradeIns.map(tradeIn => {
        const customAttributesObject = {}

        if (tradeIn.customAttributes) {
          tradeIn.customAttributes.forEach(attribute => {
            customAttributesObject[attribute.key] = attribute.value
          })
        }

        // Only add customAttributesObject fields if they do not already exist in tradeIn
        const mergedTradeIn = { ...tradeIn }
        for (const key in customAttributesObject) {
          if (!(key in mergedTradeIn)) {
            mergedTradeIn[key] = customAttributesObject[key]
          }
        }

        return mergedTradeIn
      })
    },
    // method to load all trade-ins
    async loadTradeIns() {
      this.tradeIns = null
      const currentTargetSiteId = this.order.listing.targetSite.id

      try {
        const currentUser = await window.seezSdk.getCurrentUser()
        const query = `{user(id: ${currentUser.id}){ tradeIns { ${this.tradeInQuery} }}}`
        const results = await this.queryApi(query)

        const tradeInFilter = ti => {
          return ((ti?.make !== null && ti?.pictures?.length >= 4) || ti?.state === 'pending' || ti?.state === 'offerSent' || ti?.state === 'accepted') && ti?.targetSite?.id === currentTargetSiteId
        }

        let filteredResults = results.user.tradeIns.filter(ti => tradeInFilter(ti))

        const assignedTradeIn = filteredResults.find(ti => ti.orderId === this.order.id)
        if (assignedTradeIn) {
          this.order.tradeIn = assignedTradeIn
          this.tradeInInput = this.formatTradeInInput(assignedTradeIn)
          filteredResults.splice(filteredResults.indexOf(assignedTradeIn), 1)
          filteredResults = [this.order.tradeIn, ...filteredResults]
        }
        this.tradeIns = this.parseCustomAttributes(filteredResults)
      } catch (e) {
        console.error(e)
      }
    },
    async loadAddons() {
      this.addons = null
      try {
        const query = `{addonsByListingId(listingId: ${this.order.listing.id}) {nodes {id name description financeable price category { name }}}}`
        const results = await this.queryApi(query)
        this.addons = results.addonsByListingId.nodes
      } catch (e) {
        console.error(e)
      }
    },
    // Data model methods
    persistCustomerInfoInput(formInput) {
      this.customerInfoInput = formInput.customerInfo
      if (formInput.numberOfOwners === 'dualOwner') {
        this.coOwnerInfoInput = formInput.coOwnerInfo
      } else {
        this.coOwnerInfoInput = null
      }
    },
    persistTradeInInput(formInput) {
      this.willTradeIn = formInput.willTradeIn ?? false
      if (this.willTradeIn) {
        this.tradeInInput = this.formatTradeInInput(formInput.tradeIn)
      } else {
        this.tradeInInput = {}
      }
    },
    formatTradeInInput(tradeIn) {
      const tradeInInput = {
        customerId: tradeIn?.customer?.id,
        orderId: tradeIn?.orderId,
        vin: tradeIn?.vin,
        licensePlate: tradeIn?.licensePlate,
        make: tradeIn?.make,
        model: tradeIn?.model,
        year: tradeIn?.year,
        variant: tradeIn?.variant,
        registrationDate: tradeIn?.registrationDate,
        kilometrage: tradeIn?.kilometrage,
        outstandingFinanceAmount: tradeIn?.outstandingFinanceAmount,
        customerComment: tradeIn?.customerComment,
        fuelType: tradeIn?.fuelType,
        carCondition: tradeIn?.carCondition,
        registrationType: tradeIn?.registrationType,
        color: tradeIn?.color,
        dealerComment: tradeIn?.dealerComment,
        numberOfKeys: tradeIn?.numberOfKeys,
        accident: tradeIn?.accident,
        serviceType: tradeIn?.serviceType,
        hasFinancing: tradeIn?.hasFinancing,
        listingId: tradeIn?.listingId,
        email: tradeIn?.email,
        name: tradeIn?.name,
        firstName: tradeIn?.firstName,
        middleName: tradeIn?.middleName,
        lastName: tradeIn?.lastName,
        phone: tradeIn?.phone,
        pictures: tradeIn?.pictures,
        targetSiteId: tradeIn?.targetSite?.id
      }

      const customAttributeKeys = ['lastServiceMileage', 'lastServiceDate', 'numberOfComplexAccidents', 'numberOfSimpleAccidents']

      const customAttributes = []

      customAttributeKeys.forEach(key => {
        if (tradeIn?.[key] !== undefined && tradeIn?.[key] !== null) {
          customAttributes.push({ key, value: String() })
        }
      })

      tradeInInput.customAttributes = customAttributes

      return tradeInInput
    },
    persistDeliveryInput(formInput) {
      this.deliveryInput = formInput.delivery
    },
    persistCustomizationInput(formInput) {
      this.customizationInput = formInput.addonIds
    },
    // Sales components methods
    onAddonsUpdated(customization, loanCalculations) {
      this.order.customization.addons = customization.addons
      this.order.pricing.addonsPrice = customization.pricing.addonsPrice
      this.order.pricing.financing = customization.pricing.financing
      this.order.pricing.cashPayment = customization.pricing.cashPayment
    },
    async onCreateTradeIn(tradeIn) {
      this.selectedTradeIn = tradeIn
      this.showTradeInModal = false
      this.newTradeIn = true
      await this.loadTradeIns()
    },
    paymentUpdated(paymentUpdateResult) {
      this.order.payment = paymentUpdateResult.payment
      this.order.appState = paymentUpdateResult.appState
      if (paymentUpdateResult.insuranceData) {
        this.order.insuranceData = paymentUpdateResult.insuranceData
      }
    },
    insuranceUpdated(insuranceUpdateResult) {
      this.order.pricing = insuranceUpdateResult.pricing
      this.order.insuranceData = insuranceUpdateResult.insuranceData
      this.order.appState = insuranceUpdateResult.appState
    },
    tradeInAssigned(tradeIn, pricing) {
      this.order.tradeIn = tradeIn
      this.order.pricing.financing = pricing.financing
      this.order.pricing.cashPayment = pricing.cashPayment
    },
    isCurrent(salesComponentName) {
      return salesComponentName === this.currentStep
    },
    isUpdating(salesComponentName) {
      return salesComponentName === this.componentUnderReview
    },
    async editComponent(salesComponentName) {
      const input = {
        id: this.order.id,
        appState: salesComponentName
      }
      await this.updateOrder(input)
    },
    async reviewOrder() {
      const input = {
        id: this.order.id,
        validateSteps: this.order.appState.steps.map(sc => sc.name),
        customerInfo: this.customerInfoInput ?? this.order.customerInfo,
        coOwnerInfo: this.coOwnerInfoInput ?? this.order.coOwnerInfo,
        tradeIn: this.tradeInInput,
        willTradeIn: this.willTradeIn,
        // eslint-disable-next-line no-unused-vars
        delivery: this.deliveryInput ?? (({ deliveryTypes, ...o }) => o)(this.order.delivery),
        customization: { addonIds: this.customizationInput ?? this.order.customization.addons.map(ad => Number(ad.id)) },
        appState: 'summary'
      }

      this.componentUnderReview = 'summary'

      await this.updateOrder(input)
    },
    async updateSalesComponent(salesComponentName, newStep, triggerBySubmit = true) {
      const input = {
        id: this.order.id,
        appState: newStep ?? salesComponentName,
        validateSteps: [salesComponentName]
      }

      if (salesComponentName === 'customerInfo' && this.customerInfoInput) {
        input.customerInfo = this.customerInfoInput
        input.coOwnerInfo = this.coOwnerInfoInput
        this.customerInfoInput = null
        this.coOwnerInfoInput = null
      } else if (salesComponentName === 'tradeIn' && this.willTradeIn !== null) {
        input.tradeIn = this.tradeInInput ?? this.formatTradeInInput(this.order.tradeIn)
        input.willTradeIn = this.willTradeIn
        input.validateSteps.push('payment')
        this.tradeInInput = null
      } else if (salesComponentName === 'delivery' && this.deliveryInput) {
        input.delivery = this.deliveryInput
        this.deliveryInput = null
      } else if (salesComponentName === 'customization') {
        input.customization = { addonIds: this.customizationInput ?? this.order.customization.addons.map(ad => Number(ad.id)) }
        this.customizationInput = null
      } else if (salesComponentName !== 'payment') {
        return
      }

      this.componentUnderReview = salesComponentName
      await this.updateOrder(input)
      if (salesComponentName === 'tradeIn' && input.willTradeIn) {
        const orderTradeIn = this.tradeIns.find(ti => ti.id === this.order.tradeIn?.id)
        if (orderTradeIn) {
          this.tradeIns.splice(this.tradeIns.indexOf(orderTradeIn), 1)
          this.tradeIns = [orderTradeIn, ...this.tradeIns]
        }
      }

      if (triggerBySubmit && this.order.appState.steps.find(s => s.name === salesComponentName) && this.order.appState.steps.find(s => s.name === salesComponentName)?.state === 'complete') {
        const currentIndex = this.order.appState.steps.findIndex(step => step.name === salesComponentName)

        if (currentIndex < this.order.appState.steps.length - 1) {
          if (this.order.appState.steps[currentIndex + 1].name !== 'summary') {
            this.order.appState.current = this.order.appState.steps[currentIndex + 1].name
          } else {
            this.order.appState.current = ''
          }
        }
      }
    },
    handleLocation(payload) {
      let inputOrder = { ...payload }
      if (inputOrder?.delivery?.location && inputOrder.delivery.type !== 'home') {
        inputOrder.locationId = inputOrder.delivery.location.id
      }

      if (inputOrder.delivery && 'location' in inputOrder.delivery) {
        delete inputOrder.delivery.location
      }

      return inputOrder
    },
    // Mutations
    async updateOrder(input) {
      let inputOrder = { ...input }
      if (this.order?.payment?.type === 'financing') {
        const financing = this.order?.payment?.financing ?? null
        const insuranceData = this.order?.insuranceData ?? null
        if (financing) {
          inputOrder = {
            ...inputOrder,
            insurance: {
              optionIds: insuranceData?.selectedOptionIds
            },
            payment: {
              type: 'financing',
              financing: {
                selectedProvider: financing?.config?.provider?.name,
                downPayment: Number(financing?.calculation?.downPayment),
                paymentTerms: financing?.calculation?.paymentTerms
              }
            }
          }
        }
      }

      const payload = this.handleLocation(inputOrder)

      try {
        const mutation = `
          mutation updateOrder(
            $id: ID!,
            $customerInfo: CustomerInfoInput,
            $coOwnerInfo: CustomerInfoInput,
            $payment: UpdatePaymentInput,
            $delivery: DeliveryInput,
            $customization: OrderCustomizationInput,
            $willTradeIn: Boolean,
            $tradeIn: TradeInInput,
            $appState: AppStateValue,
            $validateSteps: [AppStateValue],
            $insurance: InsuranceInput
            ) {
            updateOrder(
              orderId: $id,
              customerInfo: $customerInfo,
              coOwnerInfo: $coOwnerInfo,
              payment: $payment,
              delivery: $delivery,
              customization: $customization,
              willTradeIn: $willTradeIn,
              tradeIn: $tradeIn,
              appState: $appState,
              validateSteps: $validateSteps,
              insurance: $insurance) {
                ${this.orderQuery}
            }
          }
        `
        const { updateOrder } = await this.queryApi(mutation, payload)
        this.order = updateOrder
        this.order.pricing = updateOrder.pricing

        if (this.componentUnderReview === 'tradeIn') {
          await this.loadTradeIns()
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.componentUnderReview = null
      }
    },
    async submitOrder() {
      if (this.showExternalPaymentResultModal) {
        this.setExternalPaymentResult(null)
      }
      try {
        this.componentUnderReview = 'summary'
        const { submitOrder } = await this.queryApi(`mutation {submitOrder(orderId: ${this.order.id}) {${this.orderQuery}}}`)
        this.order = submitOrder
      } catch (e) {
        console.error(e)
      } finally {
        this.componentUnderReview = null
      }
    },
    onTradeInOfferUpdate(e) {
      this.order.tradeIn.state = e.newState
    },
    async cancelOrder() {
      try {
        this.cancellingOrder = true
        const { cancelOrder } = await this.queryApi(`mutation {cancelOrder(orderId: ${this.order.id}) {${this.orderQuery}} }`)
        this.order = cancelOrder
      } catch (e) {
        console.error(e)
      } finally {
        this.cancellingOrder = false
      }
    },
    // other
    onTradeInModalClose() {
      this.selectedTradeIn = null
      this.showTradeInModal = false
      this.loadTradeIns()
    },
    onClickTryAgain() {
      if (this.externalPaymentResult === '#externalpending') {
        this.showExtensionModal = true
        this.externalPaymentResult = null
      } else {
        this.showDepositModal = true
      }

      this.externalPaymentError = null
    },
    onClickSubmit() {
      if (this.order.depositConfig.orderDepositAmount > 0 && !this.order.deposits?.length) {
        this.showDepositModal = true
      } else {
        this.submitOrder()
      }
    },
    reservationStarted() {
      if (this.order.reservation) {
        const difference = (new Date() - new Date(this.order?.reservation?.startDate)) / 1000
        // give it a threshhold of 15 seconds between now and reservation start date
        if (difference < 15) {
          this.showExtensionModal = true
        }
      }
    },
    reservationWarning() {
      if (this.disableReservationWarning === false) {
        this.showExtensionModal = true
        this.disableReservationWarning = true
      }
    },
    handleAuthorized() {
      this.externalPaymentId = ''
      this.clearPaymentInterval()
      this.setExternalPaymentResult(this.externalPaymentResult)
    },
    async getExternalPaymentStatus() {
      this.externalPaymentStatusLoading = true

      try {
        const { order } = await this.queryApi('query getOrder($id: ID!) { order (id: $id) { externalPayments { id status sourceType providerId } } }', { id: this.id })

        const deposit = order.externalPayments.find(externalPayment => externalPayment.id === this.externalPaymentId)

        if (deposit) {
          // case extend order
          if (deposit.sourceType === 'orderVehicleReservationExtension') {
            switch (deposit.status) {
              case 'pending':
              case 'processing':
                if (this.externalPaymentResult !== '#externalpending') {
                  this.externalPaymentResult = '#externalpending'
                }

                break
              case 'authorized':
                this.externalPaymentId = ''
                this.clearPaymentInterval()
                this.externalPaymentResult = '#ok'
                this.showExternalPaymentResultModal = true
                break
              case 'rejected':
              case 'failed':
              case 'cancelled':
              case 'expired':
                this.externalPaymentId = ''
                this.externalPaymentResult = '#extensionrejected'
                this.showExternalPaymentResultModal = true
                this.clearPaymentInterval()
                break
            }
            return
          }
          //case deposit
          switch (deposit.status) {
            case 'completed':
            case 'approved':
            case 'authorized':
              this.handleAuthorized()
              break
            case 'failed':
            case 'cancelled':
            case 'rejected':
            case 'expired':
              this.externalPaymentError = true
              this.clearPaymentInterval()
              break
            case 'pending':
            case 'processing':
              break
            default:
              console.log('Unhandled status:', deposit.status)
          }
        }
      } catch (e) {
        console.error(e)
        this.externalPaymentError = true
        this.clearPaymentInterval()
      } finally {
        this.externalPaymentStatusLoading = false
      }
    },
    startPaymentInterval() {
      this.clearPaymentInterval()

      this.getExternalPaymentStatus()
      this.intervalId = setInterval(this.getExternalPaymentStatus, 3000)

      this.timeoutId = setTimeout(() => {
        if (this.intervalId) {
          console.log('Timeout reached without a change in status.')
          this.externalPaymentId = ''
          this.externalPaymentError = true
          this.clearPaymentInterval()
        }
      }, 120000) // = 2 minutes
    },

    clearPaymentInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId)
        this.intervalId = null
      }
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
        this.timeoutId = null
      }
    },
    setExternalPaymentResult(hash) {
      this.externalPaymentResult = hash
      this.checkAndSaveExternalPaymentId()

      if (this.externalPaymentId) {
        return this.startPaymentInterval()
      }

      if (this.externalPaymentResult && this.order.depositConfig.orderDepositAmount > 0) {
        this.showExternalPaymentResultModal = true
        history.pushState('', document.title, window.location.pathname + window.location.search)
      } else {
        this.showExternalPaymentResultModal = false
      }
    },
    goToHome() {
      window.location = this.homeUrl
    },
    redirect(e) {
      window.location = e
    },
    setStyles() {
      const ts = this.order.listing.targetSite
      const domElement = document.querySelector('seez-sdk-buying-flow').shadowRoot.querySelector('.buyingFlow')
      if (domElement && ts.brandingHighlightColor) domElement.style.setProperty('--highlight', ts.brandingHighlightColor)
    }
  }
}
</script>

<style lang="scss">
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  animation: spin 2s linear infinite;
  transform-origin: center;
}
.buyingFlow {
  display: grid;
  grid-template-columns: 8fr 4fr;
  margin: auto;
  background-color: var(--background);
  color: var(--text-color);

  .deliveryInfo {
    .formTemplate {
      .dateTimeSlot {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0.4rem;
      }
    }
  }

  > div {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 36rem) {
      padding-bottom: 4rem;
    }

    > .loader {
      display: flex;
      justify-content: center;
      position: absolute;
      z-index: 1;
    }

    > .salesComponents {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      > div {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .tradeIn {
          .seezModalBackground {
            .seezModal {
              padding: 2rem 2rem 0 2rem;
            }
          }
        }
      }

      > .actionButtons {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .pauseOrderBtn {
          display: none;
        }
      }

      .header {
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        .left {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 0.375rem;
          width: 100%;

          .order {
            display: flex;
            gap: 0.25rem;
            font-size: 24px;
            font-weight: 700;
            color: black;
          }

          .pending {
            color: #2e2e2e;
            font-size: 16px;
            font-weight: 600;
          }
        }
      }

      .saveOrderInfo {
        border: 1px solid black;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
        padding: 11px 12px;
        display: none;

        p {
          margin: 0;
          padding: 0;
          font-size: 14px;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;

          button {
            border: none;
            background-color: unset;
            text-decoration: underline;
            font-family: inherit;
            cursor: pointer;
            font-family: inherit;
            font-size: 14px;
            font-weight: 600;
            display: flex;
            gap: 0.2rem;
            justify-content: center;
            align-items: center;

            &:hover {
              opacity: 0.7;
            }
          }
        }
      }

      .status {
        display: flex;
        gap: 6px;
        border-radius: 20px;
        display: inline-block;
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 34px;
        padding: 0 14px 0 10px;
        > span:first-child {
          border-radius: 50%;
          width: 0.75rem;
          height: 0.75rem;
        }

        &.orange {
          color: #cc8200;
          background-color: #ffa20014;
          > span:first-child {
            background-color: #ff9900;
          }
        }

        &.green {
          background-color: #2f850b1a;
          color: #009246;
          > span:first-child {
            background-color: #2f850b;
          }
        }
      }

      .tradeInInfoTitle {
        display: flex;
        align-items: center;
        h3 {
          font-size: var(--title);
          line-height: 0;
        }
        .tradeInInfoTitleTooltip {
          display: none;
        }
      }
    }
  }

  // Collapsables
  details {
    transition: 50ms;

    &[open] {
      padding-bottom: 1em;
    }
  }

  details {
    border: 1px solid;
    background: white;
  }
  details + details {
    border-top: none;
  }

  summary {
    position: relative;
  }

  summary {
    display: grid;
    grid-template-columns: 1fr 4fr auto;
    align-items: center;

    .state {
      padding: 0.375rem 0.625rem;
      border-radius: 1.25rem;
      @media screen and (max-width: 36rem) {
        display: none;
      }

      &.incomplete {
        color: #cc8200;
        background-color: #ffa20014;
      }

      &.complete {
        background-color: #2f850b1a;
        color: #009246;
      }
    }
  }

  .tradeInDes {
    display: flex;
    align-items: center;
    .tradeInDesTooltip {
      display: none;
    }
  }
}
</style>
