<template>
  <Loader v-if="loading" class="miniListingLoader" />
  <div class="mini-row mini-info--header">
    <div class="mini-info--image"><img :src="imageUrl" :alt="listingDetail?.name" @error="imageError = true" loading="lazy" /></div>
    <div class="mini-info--content">
      <h3>{{ listingDetail?.name }}</h3>
      <p>{{ listingDetail?.variant }}</p>
    </div>
    <div class="mini-info--icon" @click="copyLink">
      <OpenIcon />
    </div>
  </div>
  <div class="mini-body">
    <section class="mini-row mini-info--cash">
      <div class="mini-columm-title mini-bold">{{ t.cash ?? 't.cash' }}</div>
      <div class="mini-columm-content">
        <p class="mini-bold">{{ formatPrice(listingDetail?.retailPrice ?? 0) }}</p>
        <p>{{ t.includeDelivery ?? 't.includeDelivery' }}</p>
      </div>
    </section>
    <section class="mini-row mini-info--finacing">
      <div class="mini-columm-title mini-bold">{{ t.financing ?? 't.financing' }}</div>
      <div class="mini-columm-content mini-bold">
        <p>{{ defaultFinancing }}</p>
      </div>
    </section>
    <div>
      <section :class="{ collapsablePanel: true, expanded: isExpanded.financing }">
        <h3 class="mini-title-collapse" @click="toggle('financing')">
          {{ t.customFinancing ?? 't.customFinancing' }}
          <DownIcon />
        </h3>
        <template v-if="listingDetail">
          <LoanCalculator :listing-id="id" :currency="listingDetail?.currency" @defaultFinancing="setDefaultFinancing" />
        </template>
      </section>
      <section :class="{ collapsablePanel: true, expanded: isExpanded.quickSpecs }">
        <h3 class="mini-title-collapse" @click="toggle('quickSpecs')">
          {{ t.quickSpecs ?? 't.quickSpecs' }}
          <DownIcon />
        </h3>
        <div>
          <ul class="mini-list">
            <li v-if="hasValue(listingDetail?.registrationDate) != null">
              <div class="mini-list--icon"><CalendarIcon /></div>
              <div class="mini-list--content">
                <span>{{ t.matriculation ?? 't.Matriculation' }}</span>
                <span class="mini-bold">{{ formatDate(listingDetail?.registrationDate) }}</span>
              </div>
            </li>
            <li v-if="hasValue(listingDetail?.kilometrage) != null">
              <div class="mini-list--icon"><RoadIcon /></div>
              <div class="mini-list--content">
                <span>{{ t.kilometrage ?? 't.kilometrage' }}</span>
                <span class="mini-bold">{{ formatSpec(listingDetail?.kilometrage ?? 0, 'Km') }}</span>
              </div>
            </li>
            <li v-if="hasValue(listingDetail?.fuelType?.name)">
              <div class="mini-list--icon"><ColorIcon /></div>
              <div class="mini-list--content">
                <span>{{ t.fuel_type ?? 't.fuel_type' }}</span>
                <span class="mini-bold">{{ languageResources?.Common?.FuelTypes?.[listingDetail.fuelType?.name] || listingDetail.fuelType?.name }}</span>
              </div>
            </li>
            <li v-if="hasValue(listingDetail?.transmission?.name)">
              <div class="mini-list--icon"><GearBoxIcon /></div>
              <div class="mini-list--content">
                <span>{{ t.transmission ?? 't.transmission' }}</span>
                <span class="mini-bold">{{ languageResources?.Common?.Transmissions?.[listingDetail.transmission?.name] || listingDetail.transmission?.name }}</span>
              </div>
            </li>
            <li v-if="hasValue(listingDetail?.engineSize)">
              <div class="mini-list--icon"><CilindrataIcon /></div>
              <div class="mini-list--content">
                <span>{{ t.engineCapacity ?? 't.engineCapacity' }}</span>
                <span class="mini-bold">{{ formatSpec(listingDetail?.numCylinders, 'cc') }}</span>
              </div>
            </li>
            <li v-if="hasValue(listingDetail?.hp)">
              <div class="mini-list--icon"><PowerIcon /></div>
              <div class="mini-list--content">
                <span>{{ t.horsepower ?? 't.horsepower' }}</span>
                <span class="mini-bold">{{ formatSpec(listingDetail?.hp, 'KW') }}</span>
              </div>
            </li>
            <li v-if="hasValue(listingDetail?.numSeats)">
              <div class="mini-list--icon"><SeatIcon /></div>
              <div class="mini-list--content">
                <span>{{ t.seatMaterial ?? 't.seatMaterial' }}</span>
                <span class="mini-bold">{{ listingDetail?.numSeats }}</span>
              </div>
            </li>
            <li v-if="hasValue(listingDetail?.fullColor) || hasValue(listingDetail?.color)">
              <div class="mini-list--icon"><ColoreIcon /></div>
              <div class="mini-list--content">
                <span>{{ t.color ?? 't.color' }}</span>
                <span class="mini-bold">{{ listingDetail?.fullColor ?? listingDetail?.color }}</span>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <template v-for="name in ['standard', 'comfort', 'design', 'safety', 'optional', 'uncategorized']" :key="name">
        <section v-if="listingDetail?.equipment?.[name]?.length > 0" :class="{ collapsablePanel: true, expanded: isExpanded[name] }">
          <h3 class="mini-title-collapse" @click="toggle(name)">
            {{ t.EquipmentCategories?.[name] ?? `t.EquipmentCategories.${name}` }}
            <DownIcon />
          </h3>
          <div>
            <ul class="mini-check">
              <li v-for="e in listingDetail?.equipment?.[name]" :key="e">
                <span><CheckIcon /></span>
                <p class="mini-bold">{{ e }}</p>
              </li>
            </ul>
          </div>
        </section>
      </template>
      <section :class="{ collapsablePanel: true, expanded: isExpanded.specifications }">
        <h3 class="mini-title-collapse" @click="toggle('specifications')">
          {{ t.specifications ?? 't.specifications' }}
          <DownIcon />
        </h3>
        <div>
          <ul class="mini-spec">
            <li v-for="(item, i) in dataSpec" :key="i">
              <span class="mini-label">{{ item.label }}</span>
              <span class="mini-bold">{{ item.value }}</span>
            </li>
          </ul>
          <p class="mini-spec--note">{{ t.note ?? 't.note' }}</p>
        </div>
      </section>
    </div>
  </div>
  <div class="mini-action">
    <button @click="$emit('buy', { listingId: id })">{{ t.startOrder ?? 't.startOrder' }}</button>
    <button @click="popupTestdriveForm"><DataIcon />{{ t.startTestDrive ?? 't.startTestDrive' }}</button>
  </div>
  <ToastComponent :ref="instance => toastInstance().set(instance)" />
</template>

<script>
import SeezSdk from '@/sdk.js'
import LoanCalculator from '../FinancingProvider/LoanCalculator.ce.vue'
import OpenIcon from '../../assets/open-link.svg'
import DownIcon from '@/assets/down.svg'
import CalendarIcon from '@/assets/minilisting/mini-calendar.svg'
import RoadIcon from '@/assets/road.svg'
import ColorIcon from '@/assets/color.svg'
import CilindrataIcon from '@/assets/minilisting/mini-cilindrata.svg'
import GearBoxIcon from '@/assets/gear.svg'
import PowerIcon from '@/assets/power.svg'
import SeatIcon from '@/assets/interior2.svg'
import ColoreIcon from '@/assets/minilisting/mini-colore.svg'
import CheckIcon from '@/assets/minilisting/mini-check.svg'
import DataIcon from '@/assets/minilisting/mini-date.svg'
import { langMixin } from '../lang'
import Loader from '@/components/Loader.ce.vue'
import ToastComponent from '@/components/Toast.vue'
import toastInstance from '@/components/toastInstance.js'
export default {
  name: 'MiniListing',
  components: {
    ToastComponent,
    Loader,
    LoanCalculator,
    OpenIcon,
    DownIcon,
    CalendarIcon,
    RoadIcon,
    ColorIcon,
    GearBoxIcon,
    CilindrataIcon,
    PowerIcon,
    SeatIcon,
    ColoreIcon,
    CheckIcon,
    DataIcon
  },
  mixins: [langMixin('MINI_LISTING_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin],
  props: {
    id: { type: String, required: true },
    expanded: { type: Boolean, default: false }
  },
  emits: ['buy', 'testDriveClick', 'loaded'],
  data() {
    return {
      listingDetail: null,
      showTestDriveModal: false,
      loading: false,
      details: null,
      imageError: false,
      isExpanded: {
        financing: false,
        quickSpecs: false,
        standard: false,
        comfort: false,
        design: false,
        safety: false,
        optional: false,
        uncategorized: false,
        specifications: false
      },
      defaultFinancing: null,
      dataSpecMap: {
        year: 'year',
        color: 'color',
        registrationDate: () => this.formatDate(this.listingDetail?.['registrationDate']),
        kilometrage: () => this.formatSpec(this.listingDetail?.['kilometrage'], 'km'),
        acceleration: () => this.formatSpec(this.listingDetail?.['acceleration'], 'sek'),
        topSpeed: 'topSpeed',
        numDoors: 'numDoors',
        numGears: () => this.languageResources?.Common?.Transmissions?.[this.listingDetail?.transmission?.name] || this.listingDetail?.transmission?.name,
        fuelType: () => this.languageResources?.Common?.FuelTypes?.[this.listingDetail?.fuelType?.name] ?? this.listingDetail?.fuelType?.name,
        hp: 'hp',
        torque: 'torque',
        fuelConsumption: 'fuelConsumption',
        driveType: 'driveType',
        length: 'length',
        width: 'width',
        height: 'height',
        maxAttachment: () => this.formatSpec(this.listingDetail?.['maxAttachment'], 'kg')
      }
    }
  },
  computed: {
    imageUrl() {
      if (this.imageError) return this.placeholder ?? `${import.meta.env.VITE_SEEZ_BASE_URL}/sdk/assets/car-placeholder.webp`
      if (this.listingDetail) {
        const d = this.listingDetail
        if (d.images && d.images.length > 0) return `${import.meta.env.VITE_IMAGES_URL}/image/0x0/${d.images[0]}.webp`
      }
      return this.placeholder ?? `${import.meta.env.VITE_SEEZ_BASE_URL}/sdk/assets/car-placeholder.webp`
    },
    registrationDate() {
      if (this.listingDetail?.registrationDate == null) return null
      const d = new Date(this.listingDetail.registrationDate)
      return Intl.DateTimeFormat(this.language, { year: 'numeric', month: 'long' }).format(d)
    },
    dataSpec() {
      if (this.listingDetail == null) return []
      return Object.keys(this.dataSpecMap)
        .map(value => {
          if (this.hasValue(this.listingDetail[value])) {
            return {
              label: `${this.t[value] ?? `t.${value}`}`,
              value: typeof this.dataSpecMap[value] === 'string' ? this.listingDetail[value] : this.dataSpecMap[value]()
            }
          } else return null
        })
        .filter(e => e !== null)
    }
  },
  mounted() {
    this.loadListing()
  },
  methods: {
    toastInstance() {
      return toastInstance
    },
    copyLink() {
      navigator.clipboard.writeText(window.location.href)
      this.toastInstance()
        .get()
        .showToast({
          type: 'success',
          content: this.t.copy_sucessfully ?? 't.copy_sucessfully'
        })
    },
    toggle(name) {
      this.isExpanded[name] = !this.isExpanded[name]
    },
    hasValue(v) {
      return v != null && v !== '' && v !== 0
    },
    formatSpec(value, unit) {
      if (value == null) return '-'
      if (unit == null) unit = ''
      const stringValue = typeof value == 'number' ? this.formatNumber(value) : value
      return stringValue + ' ' + unit
    },
    popupTestdriveForm() {
      window.seezSdk.showModal('seez-sdk-dynamic-inquiry-form', { id: this.listingDetail.externalId, ['lead-type']: 'testDriveInquiry' })
    },
    setDefaultFinancing(data) {
      if (!this.defaultFinancing) this.defaultFinancing = `${this.formatPrice({ value: data?.monthlyPayment, currency: this.listingDetail.currency })} x ${data?.paymentTerms} ${this.t.months ?? 't.months'}`
    },
    async loadListing() {
      this.loading = true

      const listingQuery = `
        {
          listing(id: ${this.id}) {
            inspectionReport { url label }
            id name model { id name family { id name brand { id name }}} bodyType { name } targetSite { name logo brandingHighlightColor }
            variant year currency kilometrage owner { id name description logoUrl financing { logo }}
            images color hp transmission {name} fuelType { id name } registrationDate state reservedBy
            equipment { comfort design optional safety standard uncategorized } referenceNumber acceleration numAirbags numGears
            numCylinders weight loadCapacity maxAttachment numDoors fuelConsumption range description locatedAt { plainAddress }
            customBadge isNoviceDrivable
            fullColor image360 { exteriorClosedDoors interiorPanorama }
            listPrice { value currency }
            usage
            typeName leasePrice { value currency type lease { duration downPayment residualValue type audience }}
            wholesaleLeasePrice { value currency type lease { duration downPayment residualValue type audience }}
            retailPrice { value currency type disclaimer } wholesalePrice { value currency type }
            emiPrice { value currency type emi { loanAmount apr disclaimer paymentTerms }}
            videos greenTax batteryConsumption certified interiorMaterial interiorColor rimSize warranty engineSize numSeats
            promotions { title description priceB2c disclaimer discount discounted_price start_date end_date}
            reservation {id isActive reservedFor { id }} reservationConfig { requiresPaymentIdToReserve enabledTestDriveReservations }
            externalId
            referenceNumber
            driveType
            bodyType { id name }
            nextInspectionDate
            registrationDate
            registrationType
            topSpeed
            length
            width
            height
            torque
          }
        }
      `

      try {
        const { listing } = await window.seezSdk.queryApi(listingQuery)
        if (listing) this.listingDetail = listing
        this.$emit('loaded', { listingDetails: listing })
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.mini {
  &-action {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    box-sizing: border-box;

    button:last-child {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      svg {
        margin-top: -3px;
      }
    }
  }

  &-body {
    padding: 0 16px;
    box-sizing: border-box;
  }

  &-info {
    &--header {
      background-color: #fafafa;
      display: flex;
      gap: 16px;
      margin-bottom: 40px;
    }

    &--image {
      width: 30%;
      max-width: 30%;
      img {
        width: 100%;
      }
    }

    &--content {
      text-transform: uppercase;
      flex: 1;

      h3 {
        padding: 0;
        margin: 0;
        line-height: 1.4;
      }
      p {
        padding: 0;
        margin: 0;
      }
    }

    &--icon {
      margin-right: 16px;
    }

    &--cash {
      padding-bottom: 13px;
      border-bottom: 1px solid #e6e6e6;
    }

    &--finacing {
      padding: 13px 0 16px;
    }
  }
  &-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-columm {
    &-content {
      text-align: right;

      p {
        margin: 0;
        padding: 0;
      }
    }
    &-title {
      font-weight: 700;
    }
  }

  &-bold {
    font-weight: 700;
  }

  &-label {
    text-transform: uppercase;
  }

  &-title-collapse {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;
    gap: 10px;

    &--icon {
      width: 24px;
      min-width: 24px;
    }

    &--content {
      display: flex;
      flex-direction: column;
    }

    li {
      width: calc(50% - 5px);
      padding: 12px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      gap: 10px;
      background-color: #f5f6f7;
    }
  }

  &-check {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;
    gap: 10px;

    li {
      width: calc(50% - 5px);
      gap: 10px;
      display: flex;
      align-items: center;

      p {
        padding: 0;
        margin: 0;
      }

      span {
        width: 26px;
        min-width: 26px;
      }
    }
  }

  &-spec {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;

    &--note {
      padding: 0;
      margin: 12px 0;
      color: #26262680;
    }

    li {
      display: flex;
      background-color: #f5f6f7;
      padding: 12px 8px;

      &:nth-child(2n) {
        background-color: #fff;
      }

      span {
        &:first-child {
          width: 60%;
          min-width: 60%;
        }
        &:last-child {
          flex: 1;
          text-align: right;
        }
      }
    }
  }
}
.collapsablePanel {
  padding: 18px 0;
  border-bottom: 1px solid #e6e6e6;

  &:last-child {
    border-bottom: 0;
  }

  svg {
    max-width: 20px;
  }

  > div {
    max-height: 0;
    transition: 0.4s ease-in-out;
    overflow: hidden;
  }

  &.expanded {
    > div {
      max-height: 1500px;
      margin-top: 22px;
    }

    > h3 > svg {
      transform: rotateZ(180deg);
    }
  }
  .miniListingLoader {
    min-height: 100vh;

    > svg {
      max-width: 8rem;
    }
  }
}
</style>
