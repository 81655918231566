import { toIsoString, sha256 } from './logic.js'

const jsonHeaders = { 'Accept': '*/*', 'Content-Type': 'application/json' }

export default class SeezSdk {
  #handoutToken = null
  #accessToken = null
  #refreshPromise = null
  #alreadyChecked = false
  #baseHeaders = { ...jsonHeaders }
  #favorites = JSON.parse(localStorage.getItem('favorites')) ?? []
  #savedSearches = JSON.parse(localStorage.getItem('searches')) ?? []
  #lastUserId = null // to deduplicate analytics identify tracking
  #userSubscriptionHandlers = []
  #buyButtonsObserver = new MutationObserver(mutations => {
    if (mutations.some(m => m.attributeName === 'data-listing-id')) this.injectSeezOnlineBuying(true)
  })
  #userPIITracking = false

  constructor() {
    this.clientId = document?.querySelector('[data-seez-client-id]')?.getAttribute('data-seez-client-id')
    if (this.clientId) this.#baseHeaders['Client-Id'] = this.clientId
    this.#parseHandoutFromUrl()
    let anonymousId = localStorage.getItem('Seez-Anonymous-Id')
    if (anonymousId == null) {
      anonymousId = crypto.randomUUID()
      localStorage.setItem('Seez-Anonymous-Id', anonymousId)
    }
    this.#baseHeaders['Seez-Anonymous-Id'] = anonymousId
    let sessionId = sessionStorage.getItem('Seez-Session-Id')
    if (sessionId == null) {
      const cookies =
        document.cookie
          ?.split('; ')
          .map(p => p.split('='))
          .reduce((t, c) => ({ ...t, [c[0]]: c[1] }), {}) ?? {}
      sessionId = cookies['seez-session-id'] ?? crypto.randomUUID()
      sessionStorage.setItem('Seez-Session-Id', sessionId)
    }
    this.#baseHeaders['Seez-Session-Id'] = sessionId
    this.#baseHeaders['Client-Lang'] = document.querySelector('html')?.getAttribute('lang')
    this.#getCustomizations()
    this.targetSite = null
  }

  //#region language
  #languageResources = {}
  loadLanguageResources(language) {
    if (!(language in this.#languageResources)) {
      const url = `${import.meta.env.VITE_SEEZ_BASE_URL}/cms/${language}/sdk` + (this.clientId ? `/${this.clientId}` : '')
      this.#languageResources[language] = fetch(url)
        .then(r => r.json())
        .catch(e => console.error(e))
    }
    return this.#languageResources[language]
  }
  //#endregion

  //#region modals
  showMessage(content, closable = true) {
    let promiseResolve = null

    function closed() {
      document.body.querySelectorAll('seez-sdk-modal').forEach(x => x.parentNode.removeChild(x))
      if (promiseResolve) promiseResolve()
    }

    const modalComponent = document.createElement('seez-sdk-modal')
    modalComponent.setAttribute('closable', closable)
    modalComponent.innerHTML = content
    document.body.appendChild(modalComponent)
    modalComponent.addEventListener('close', closed)

    // eslint-disable-next-line no-unused-vars
    return new Promise(function (resolve, reject) {
      promiseResolve = resolve
    }, false)
  }

  showModal(tag, props = {}, closable = true, slots = {}) {
    let promiseResolve = null

    function closed(result) {
      document.body.removeChild(result.target)
      if (promiseResolve) promiseResolve(result.detail[0])
    }

    const modalComponent = document.createElement('seez-sdk-modal')
    modalComponent.setAttribute('closable', closable)
    const component = document.createElement(tag)
    for (const key in props) component.setAttribute(key, props[key])
    for (const key in slots) {
      const s = document.createElement('div')
      s.setAttribute('slot', key)
      s.innerHTML = slots[key].startsWith('#') ? document.querySelector(slots[key])?.innerHTML : slots[key]
      component.appendChild(s)
    }
    modalComponent.appendChild(component)

    document.body.appendChild(modalComponent)
    modalComponent.addEventListener('close', closed.bind(this))

    // eslint-disable-next-line no-unused-vars
    return new Promise(function (resolve, reject) {
      promiseResolve = resolve
    }, false)
  }

  async #loadModuleFor(componentName) {
    if (!componentName.startsWith('seez-sdk-')) return

    const modules = {
      seezar: ['wizard', 'loader', 'seezar-modal', 'contact-button', 'carousel', 'clear-chat', 'dynamic-inquiry-form', 'comparison-table', 'lead-form', 'test-drive-form', 'contact-point', 'location-cards', 'seezar-chat'],
      enterprise: ['modal', 'form-template', 'form-template-address', 'login-form', 'listing-card', 'trade-in', 'tradein-help', 'loan-calculator', 'financing-provider', 'carousel', 'favorites', 'orders', 'active-order-cancellation', 'active-order-pause', 'unavailable-listing-modal', 'cancel-order-modal', 'saved-searches', 'search', 'logout', 'user-profile', 'listing-details', 'trade-in-form', 'lead-form', 'test-drive-modal', 'test-drive-form', 'dynamic-inquiry-form', 'loader', 'tradein-offer', 'tradeins', 'buying-flow', 'delete-user', 'mini-listing']
    }

    const moduleName = Object.entries(modules).find(([, components]) => components.includes(componentName.substring(9)))?.[0]
    if (moduleName == null) return
    await this.#injectScriptTag(`${import.meta.env.VITE_SEEZ_BASE_URL}/sdk/seez-sdk-${moduleName}.js`)
  }

  async showComponent(componentName, options = {}) {
    await this.#loadModuleFor(componentName)
    const c = document.createElement(componentName)
    const dialog = document.createElement('dialog')
    const closeFunction = () => {
      dialog.close()
      document.body.removeChild(dialog)
    }

    dialog.className = 'seezDialog'
    if (options.modal) dialog.classList.add('seezModal')
    if (options.styles) Object.assign(dialog.style, options.styles)
    dialog.appendChild(c)
    // const x = document.createElement('button')
    // x.textContent = '×'
    // x.onclick = closeFunction
    // dialog.appendChild(x)
    document.body.appendChild(dialog)

    options.modal ? dialog.showModal() : dialog.show()
    return { dialog, component: c, closeFunction }
  }
  //#endregion

  //#region Buy Button
  async getLinksForListings(externalIds) {
    const uniqueExternalIds = [...new Set(externalIds)]
    let seezLinks = []
    try {
      const response = await this.queryApi('mutation l($ids: [String]){generateLinksFromExternalIds(externalId: $ids)}', { ids: uniqueExternalIds })
      seezLinks = response.generateLinksFromExternalIds
    } catch (error) {
      console.error(error)
    }
    return externalIds.reduce((t, c, i) => {
      if (seezLinks[i]) t[c] = seezLinks[i]
      return t
    }, {})
  }

  async injectSeezOnlineBuying(forceRefresh = false, callback) {
    const selector = '[data-listing-id]:not(.statusReady):not(.statusError)'
    const tags = [...document.querySelectorAll(forceRefresh ? '[data-listing-id]' : selector)]
    if (tags.length > 0) {
      tags.forEach(t => t.classList.remove('statusReady', 'statusError'))
      tags.filter(t => !t.classList.contains('customStyles')).forEach(t => (t.style.visibility = 'hidden'))

      const links = await this.getLinksForListings(tags.map(t => t.getAttribute('data-listing-id')))
      for (const tag of tags) {
        const externalId = tag.getAttribute('data-listing-id')
        tag.classList.add(externalId in links ? 'statusReady' : 'statusError')
        if (externalId in links) {
          if (tag.tagName === 'A') {
            tag.href = links[externalId]
          } else {
            tag.onclick = () => (window.location = links[externalId])
          }
          if (!tag.classList.contains('customStyles')) tag.style.visibility = null
        }
      }
    }
    this.#buyButtonsObserver.disconnect()
    tags.forEach(t => this.#buyButtonsObserver.observe(t, { attributes: true }))
    if (callback) callback(tags)
  }
  //#endregion

  //#region Session
  async requestCode(email, language) {
    const url = `${import.meta.env.VITE_AUTH_URL}/otp?email=${encodeURIComponent(email)}${language ? `&language=${language}` : ''}`
    const requestOTPResponse = await fetch(url)
    return requestOTPResponse.json()
  }

  #navigateToPost(url, payload) {
    var form = document.createElement('form')
    form.style.visibility = 'hidden'
    form.method = 'POST'
    form.action = url
    for (const key in payload) {
      var input = document.createElement('input')
      input.name = key
      input.value = payload[key]
      form.appendChild(input)
    }
    document.body.appendChild(form)
    form.submit()
  }

  async loginWithOTP(email, otp, acceptsMarketing, redirectUrl) {
    const payload = {
      method: 'POST',
      headers: { ...jsonHeaders },
      body: JSON.stringify({ email: email, otp: otp })
    }
    const response = await fetch(import.meta.env.VITE_AUTH_URL + '/validate', payload)
    const { valid } = await response.json()
    if (!valid) throw new Error('Invalid email/OTP')
    const url = import.meta.env.VITE_AUTH_URL + '/login?redirect=' + encodeURIComponent(redirectUrl ?? window.location)
    this.#navigateToPost(url, { email: email, otp: otp, marketing: acceptsMarketing })
  }

  async loginWithOTPAjax(email, otp, acceptsMarketing) {
    const payload = {
      method: 'POST',
      headers: { ...jsonHeaders },
      body: JSON.stringify({ email: email, otp: otp, marketing: acceptsMarketing })
    }
    const response = await fetch(import.meta.env.VITE_AUTH_URL + '/authenticate', payload)
    const { valid, refreshToken } = await response.json()
    if (!valid) throw new Error('Invalid email/OTP')
    localStorage.setItem('refresh_token', refreshToken)
    await this.#refreshAccessToken()
  }

  #parseHandoutFromUrl() {
    var url = new URL(window.location)
    const ht = url.searchParams.get('ht')
    if (ht && this.#isTokenValid(ht)) {
      this.#handoutToken = ht
      url.searchParams.delete('ht')
      if (url.hash === '#_=_') url.hash = ''
      window.history.replaceState({}, '', url.toString())
    }
  }

  async logout(redirectUrl) {
    let trackingPromise = this.track('logout')
    this.#cleanUserDetails()
    localStorage.removeItem('refresh_token')
    console.log('waiting track')
    await trackingPromise
    console.log('track completed')
    if (redirectUrl) window.location = redirectUrl
    else window.location.reload()
    // window.location = import.meta.env.VITE_AUTH_URL + '/logout?redirect=' + encodeURIComponent(window.location)
  }

  showLogin(bannerState, closable = true, slots) {
    return this.showModal('seez-sdk-login-form', null, closable, slots)
  }

  closeLogin() {
    document.body.querySelectorAll('seez-sdk-login').forEach(x => x.parentNode.removeChild(x))
  }

  showLogout(redirectUrl) {
    let promiseResolve = null

    function closed(result) {
      document.body.querySelectorAll('seez-sdk-logout').forEach(x => x.parentNode.removeChild(x))
      if (result.detail[0] === true)
        this.logout(redirectUrl).then(() => {
          if (promiseResolve) promiseResolve(result.detail[0])
        })
      else if (promiseResolve) promiseResolve(result.detail[0])
    }

    const logoutComponent = document.createElement('seez-sdk-logout')
    document.body.appendChild(logoutComponent)
    logoutComponent.addEventListener('close', closed.bind(this))

    // eslint-disable-next-line no-unused-vars
    return new Promise(function (resolve, reject) {
      promiseResolve = resolve
    }, false)
  }

  async #refreshAccessToken() {
    try {
      // const refreshResponse = await fetch(import.meta.env.VITE_AUTH_URL + '/refresh', { credentials: 'include', mode: 'cors' })
      if (this.#handoutToken) {
        const payload = { method: 'POST', headers: jsonHeaders, body: JSON.stringify({ handoutToken: this.#handoutToken }) }
        const response = await fetch(import.meta.env.VITE_AUTH_URL + '/handout', payload)
        const { refreshToken } = await response.json()
        localStorage.setItem('refresh_token', refreshToken)
      }
      const rt = localStorage.getItem('refresh_token')
      if (rt) {
        const payload = { method: 'POST', headers: jsonHeaders, body: JSON.stringify({ refreshToken: rt }) }
        const refreshResponse = await fetch(import.meta.env.VITE_AUTH_URL + '/refresh', payload)
        const accessTokenRes = await refreshResponse.text()
        if (accessTokenRes.trim().startsWith('{')) {
          // Response body is JSON (NEW /refresh response)
          const { accessToken, refreshToken } = JSON.parse(accessTokenRes)
          if (refreshToken != null) localStorage.setItem('refresh_token', refreshToken)
          this.#accessToken = this.#isTokenValid(accessToken) ? accessToken : null
        } else this.#accessToken = this.#isTokenValid(accessTokenRes) ? accessTokenRes : null // rsponse body is raw accessBody as string (OUTDATED /refresh response)
        this.#invokeHandlers()
      }
      if (this.#handoutToken) {
        await this.#retrieveUserDetails()
        this.#handoutToken = null
      }
    } catch (error) {
      this.#accessToken = null
      this.#invokeHandlers()
      console.error(error)
    }
    this.#alreadyChecked = true
    return this.#accessToken
  }

  #parseJwt(jwt) {
    try {
      const base64Url = jwt.split('.')[1]
      const base64 = base64Url.replace('-', '+').replace('_', '/')
      const claimsString = atob(base64)
      return JSON.parse(claimsString)
    } catch {
      return null
    }
  }

  #isTokenValid(token) {
    try {
      const jwtRegEx = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/
      if (token == null || !jwtRegEx.test(token)) return false
      const { exp } = this.#parseJwt(token)
      const now = Math.floor((new Date().getTime() + 1) / 1000)
      if (exp - now < 30) return false
      return true
    } catch {
      return false
    }
  }

  getAccessToken(forceRefresh) {
    // unify promise
    if (forceRefresh) {
      this.#alreadyChecked = false
      this.#accessToken = null
    }

    if (this.#alreadyChecked && this.#accessToken == null) return null
    if (this.#isTokenValid(this.#accessToken)) return this.#accessToken
    if (this.#refreshPromise == null) {
      this.#refreshPromise = this.#refreshAccessToken()
      this.#refreshPromise.then(() => (this.#refreshPromise = null))
    }
    return this.#refreshPromise
  }

  async getCurrentUser(forceRefresh, track = true) {
    const at = await this.getAccessToken(forceRefresh)
    const user = this.#parseJwt(at)
    if (track) {
      if (user) this.identify({ ...user, loginStatus: true, userId: user.id, phone: user.phone ?? null })
      else if (this.analytics?.()?.user?.()?.id?.() != null) this.analytics?.().reset?.()
    }
    return user
  }

  #getCurrentUserSync() {
    //gets the user without fetching (returns null if it's expired)
    if (this.#isTokenValid(this.#accessToken)) return this.#parseJwt(this.#accessToken)
    return null
  }

  userSubscribe(callback) {
    this.#userSubscriptionHandlers.push(callback)
    const user = this.#getCurrentUserSync()
    callback(user)
    this.getAccessToken()
    return user
  }

  userUnsubscribe(callback) {
    const index = this.#userSubscriptionHandlers.indexOf(callback)
    if (index > -1) this.#userSubscriptionHandlers.splice(index, 1)
  }

  async #invokeHandlers() {
    if (this.#userSubscriptionHandlers.length === 0) return
    const user = this.#getCurrentUserSync()
    await Promise.allSettled(this.#userSubscriptionHandlers.map(h => h(user)))
  }
  //#endregion

  //#region api
  async queryApi(query, variables, signal) {
    const at = await this.getAccessToken()
    const body = { query: query.replace(/[\n,\s]\s*/gm, ' ') }
    if (variables) body.variables = variables
    const request = {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        ...this.#baseHeaders,
        'Local-Time': toIsoString(new Date())
      }
    }
    if (at) request.headers.authorization = `Bearer ${at}`
    if (signal) request.signal = signal

    const response = await fetch(import.meta.env.VITE_API_URL, request)
    const result = await response.json()
    if (result.errors) throw new Error(result.errors.map(e => e.message).join('\r\n'))
    return result.data
  }
  //#endregion

  //#region Favorites
  getFavorites() {
    return this.#favorites
  }

  async #persistFavorites() {
    localStorage.setItem('favorites', JSON.stringify(this.#favorites))
    if (await this.getCurrentUser(null, false)) {
      const result = await this.queryApi('mutation sf($ids: [ID]) {saveAllFavorites(listingIds: $ids)}', { ids: this.#favorites })
      const savedIds = result.saveAllFavorites.map(x => parseInt(x))
      localStorage.setItem('favorites', JSON.stringify(savedIds))
    }
  }

  setFavorite(listingId, favorited) {
    //if not favorited is specified 'toggle' is assumed
    const id = parseInt(listingId)
    const isFavorited = typeof favorited === 'boolean' ? favorited : !this.#favorites.includes(id)
    if (isFavorited === false) {
      this.#favorites = this.#favorites.filter(x => x !== id)
      this.#persistFavorites()
    } else if (!this.#favorites.includes(id)) {
      const newList = [...this.#favorites, id]
      newList.sort()
      this.#favorites = newList
      this.#persistFavorites()
    }
    return isFavorited
  }
  //#endregion

  //#region Saved Searches
  getSavedSearches() {
    return this.#savedSearches
  }

  #persistsSavedSearches() {
    localStorage.setItem('searches', JSON.stringify(this.#savedSearches))
    window.dispatchEvent(
      new CustomEvent('saved-searches-changed', {
        detail: {
          numSearch: this.#savedSearches?.length
        }
      })
    )
  }

  async addSearchInServer(name, filter) {
    const query = 'mutation saveSearch($name: String!, $filter: ListingFiltersInput!) { saveSearch(searchName: $name, filterParameters: $filter) {id} }'
    const { saveSearch } = await window.seezSdk.queryApi(query, { name: name, filter: filter })
    const newId = saveSearch[saveSearch.length - 1].id
    return newId
  }

  async addSearch(name, filter) {
    const minId = this.#savedSearches.reduce((t, c) => Math.min(c.id, t), 0)
    const newList = [...this.#savedSearches, { id: minId - 1, name: name, filterParameters: filter }] // creates negative index so the server knows it should be 'seq'
    this.#savedSearches = newList
    this.#persistsSavedSearches()
    const track = false
    if (await this.getCurrentUser(null, track)) {
      newList[newList.length - 1].id = await this.addSearchInServer(name, filter)
      this.#savedSearches = newList
      this.#persistsSavedSearches()
    }
  }

  async removeSearch(id) {
    this.#savedSearches = this.#savedSearches.filter(x => parseInt(x.id) !== parseInt(id))
    this.#persistsSavedSearches()
    if ((await this.getCurrentUser()) && id > 0) {
      const { removeSearch } = await window.seezSdk.queryApi(`mutation {removeSearch(id: ${id}) {id}}`)
      const serverIds = removeSearch.map(r => parseInt(r.id))
      this.#savedSearches = this.#savedSearches.filter(x => serverIds.includes(parseInt(x.id)))
      this.#persistsSavedSearches()
    }
  }

  static vueSavedSearchesMixin = {
    data() {
      return {
        savedSearches: window.seezSdk.getSavedSearches()
      }
    },
    methods: {
      addSearch(name, filter) {
        window.seezSdk.addSearch(name, filter)
        this.savedSearches = window.seezSdk.getSavedSearches()
      },
      removeSearch(id) {
        window.seezSdk.removeSearch(id)
        this.savedSearches = window.seezSdk.getSavedSearches()
      }
    }
  }

  async #retrieveUserDetails() {
    if ((await this.getCurrentUser()) == null) return
    const { user } = await this.queryApi('{user{favorites {listingId},savedSearches{id,name,filterParameters{bodyTypes,brands,colors,driveTypes,engineSizeMax,freeText,fuelTypes,kilometrageMin,kilometrageMax,models,numberOfDoorsMax,numberOfDoorsMin,priceMin,priceMax,priceType,sort,transmissions,yearMin,yearMax}}}}')

    const userFavorites = user.favorites.map(l => parseInt(l.listingId))
    const newLocalFavorites = this.getFavorites().filter(f => !userFavorites.includes(f))
    if (newLocalFavorites.length > 0) {
      userFavorites.push(...newLocalFavorites)
      userFavorites.sort()
      this.#favorites = userFavorites
      this.#persistFavorites()
    } else {
      this.#favorites = userFavorites
      localStorage.setItem('favorites', JSON.stringify(this.#favorites))
    }

    const searches = user.savedSearches
    const newLocalSearches = this.getSavedSearches().filter(s => !searches.some(x => x.name === s.name) && s.id < 0)
    this.#savedSearches = searches
    for (const newSearch of newLocalSearches) {
      this.addSearch(newSearch.name, newSearch.filterParameters)
    }
    this.#persistsSavedSearches()
  }

  #cleanUserDetails() {
    this.#favorites = []
    localStorage.removeItem('favorites')
    this.#savedSearches = []
    localStorage.removeItem('searches')
  }
  //#endregion

  //#region Analytics
  enableTracking(segmentID) {
    this.#userPIITracking = true
    if (segmentID) this.loadSegmentTracking(segmentID)
  }

  track(eventKey, properties, vData) {
    return Promise.allSettled([this.#internalTrack('track', eventKey, properties, vData?.id), this.#segmentTracking(eventKey, properties, vData), this.#ga4Track(eventKey, properties)])
  }

  trackNavigation(url) {
    this.#internalTrack('page', 'navigation', { url: url ?? window.location.href })
  }

  async #internalTrack(eventType, eventName, details, listingId) {
    const messageBody = {
      eventType,
      eventName,
      clientId: this.clientId,
      url: window?.location?.href,
      occuredAt: toIsoString(new Date()),
      details: Object.fromEntries(Object.entries(details).filter(p => p[1] != null && p[1] !== '')), // remove null or empty values
      listingId,
      anonymousId: this.#baseHeaders?.['Seez-Anonymous-Id'],
      sessionId: this.#baseHeaders?.['Seez-Session-Id'],
      userAgent: navigator?.userAgent,
      languages: navigator?.languages
    }

    if (this.#userPIITracking) messageBody.userId = await this.getCurrentUser().then(u => u?.id)

    const payload = {
      'Action': 'SendMessage',
      'MessageAttributes.1.Name': 'operation',
      'MessageAttributes.1.Value.DataType': 'String',
      'MessageAttributes.1.Value.StringValue': 'activityTrack',
      'MessageBody': JSON.stringify(messageBody)
    }

    const body = new URLSearchParams(payload)
    // navigator.sendBeacon(import.meta.env.VITE_SQS_ANALYTICS, body)
    await fetch(import.meta.env.VITE_SQS_ANALYTICS, { method: 'POST', body })
  }

  async #ga4Track(eventName, details) {
    if (typeof window.gtag !== 'function') return
    window.gtag('event', eventName, { details })
  }

  //#region Segment
  analytics() {
    return typeof window === 'object' && typeof window?.analytics === 'object' ? window.analytics : Object.assign({}, ...Object.entries({ ...['track', 'identify', 'page', 'user'] }).map(([, b]) => ({ [b]: e => e })))
  }

  loadSegmentTracking(segmentID) {
    this.#userPIITracking = true
    let analytics = (window.analytics = window.analytics || [])

    if (!analytics.initialize)
      if (analytics.invoked) window.console && console.error && console.error('Segment snippet included twice.')
      else {
        analytics.invoked = !0
        analytics.methods = ['identify', 'track', 'page', 'setAnonymousId']
        analytics.factory = function (e) {
          return function () {
            const t = Array.prototype.slice.call(arguments)
            t.unshift(e)
            analytics.push(t)
            return analytics
          }
        }
        for (let e = 0; e < analytics.methods.length; e++) {
          let key = analytics.methods[e]
          analytics[key] = analytics.factory(key)
        }
        analytics.load = function (key, e) {
          let t = document.createElement('script')
          t.type = 'text/javascript'
          t.async = !0
          t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js'
          let n = document.getElementsByTagName('script')[0]
          n.parentNode.insertBefore(t, n)
          analytics._loadOptions = e
        }
        analytics._writeKey = segmentID
        analytics.SNIPPET_VERSION = '4.15.3'
        analytics.load(segmentID)
        analytics.page()
      }
  }

  async identify({ userId, name, email, marketing, loginStatus, phone }) {
    if (this.#lastUserId === userId || !this.#userPIITracking) return

    this.#lastUserId = userId
    const payload = {
      userId,
      name,
      email,
      hashedEmail: await sha256(email),
      loginStatus,
      marketing_consent: marketing === 1,
      phone
    }

    return Promise.allSettled([this.#internalTrack('identify', 'identify', payload), this.analytics().identify(userId, payload)])
  }

  async #segmentTracking(eventKey, properties, vData) {
    await this.getCurrentUser()

    const genCarData = ({ id, brand, kilometrage, year, color, variant, registrationDate, model, fuelType, transmission, bodyType, dealership }) => {
      return {
        vehicle_id: id,
        kmtrage: kilometrage,
        vehicle_year: year,
        vehicle_color: color,
        vehicle_variant: variant,
        vehicle_first_registration_date: registrationDate,
        vehicle_model_name: model?.name,
        vehicle_brand_name: brand?.name,
        vehicle_fuel_type: fuelType?.name,
        vehicle_transmission_type: transmission?.name,
        vehicle_body_type: bodyType?.name,
        vehicle_dealer_id: dealership?.id,
        vehicle_dealer_name: dealership?.name
      }
    }

    const vehicleData = vData?.vehicle && genCarData(vData.vehicle)
    const storage_anonymousId = localStorage.getItem('ajs_anonymous_id')
    const anonymousId = (storage_anonymousId && storage_anonymousId.replace('"', '').replace('"', '')) || null

    return new Promise(resolve => {
      this.analytics().track(eventKey, { ...properties, vehicleData, anonymousId, clientId: this.clientId }, { traits: this.#userPIITracking ? this.analytics()?.user?.()?.traits() : null }, resolve)
      if (this.analytics().initialized !== true) resolve()
    })
  }
  //#endregion
  //#endregion

  //#region customizations
  async #getCustomizations() {
    try {
      this.targetSite = await fetch(`${import.meta.env.VITE_SEEZ_BASE_URL}/tsconfig/${this.clientId}`).then(r => r.json())

      if (this.targetSite == null) {
        if (this.clientId == 'pad') this.targetSite = { sdkModules: ['seezar', 'enterprise'] }
        else return
      }

      if (this.targetSite.customCss?.length > 0) document.dispatchEvent(new CustomEvent('customStylesUpdated')) //emit event so the stylerMixin can update the styles

      if (this.targetSite.sdkModules?.length > 0) {
        const skip =
          document
            ?.querySelector('[data-skip-modules]')
            ?.getAttribute('data-skip-modules')
            ?.split(/[,;\s]/g) ?? [] // this is to avoid loading server modules when debugging locally
        const modules = this.targetSite.sdkModules.filter(m => !skip.includes(m))
        await Promise.all(modules.map(m => this.#injectScriptTag(`${import.meta.env.VITE_SEEZ_BASE_URL}/sdk/seez-sdk-${m}.js`)))
      }
    } catch (error) {
      console.error('Failed to load customizations:', error)
    }
  }
  //#endregion

  //#region modules
  #injectScriptTag(url) {
    return new Promise((resolve, reject) => {
      // Check if the script is already present in the document
      if (document.querySelector(`script[src="${url}"]`)) {
        resolve() // If it exists, simply resolve and don't add it again
        return
      }

      const script = document.createElement('script')
      script.onload = resolve
      script.onerror = reject
      script.type = 'module'
      script.defer = true
      script.src = url
      document.head.appendChild(script)
    })
  }
  //#endregion
}
