<template>
  <div :class="['modalHeader', textDirection]">
    <div class="modalHeaderIcon">
      <div class="headerIcon">
        <img id="headerBrandIcon" alt="Seezar icon" :src="headerIcon" />
      </div>
    </div>

    <div class="headerButtonsWrapper" :class="{ mobileButtonSize: !isDesktopScreen }">
      <button :disabled="disableClearChat" id="clearChatButton" :class="{ disabled: disableClearChat }" @click="onClickResetMessages">
        <TrashIcon />
      </button>
    
      <button v-if="isDesktopScreen" id="expandButton" class="chatSizeMenuWrapper" @click.stop="onClickExpand" aria-label="Expand Chat">
        <ExpandIcon aria-label="Expand Chat" />
      </button>
      <div v-if="displayChatSizeOptions" :class="['chatSizeMenu', textDirection]">
        <div :class="[textDirection]" />
        <ul class="sizeOptionList">
          <li class="sizeOption" v-for="size in showChatSizeOptions" :key="size" @click="onChangeChatSize(size)" :aria-label="`Chat size - ${size}`">
            <div class="mainScreen">
              <div :class="chatSizes[size].icon" class="commonScreen" />
            </div>
            {{ chatSizeDisplayNames[size] }}
          </li>
        </ul>
      </div>

      <button id="closeButton" @click="closeChat" aria-label="Close Chat">
        <CloseIcon aria-label="Close Chat" />
      </button>

      <button id="chatRefButton" v-if="chatReferenceId" aria-label="Chat Reference" @click="showChatRef = !showChatRef">
        <HashtagIcon aria-label="Chat Reference" />
      </button>

      <span tabIndex="0" @click="copyChatRef" class="chatRef" id="chatRef" v-show="showChatRef && chatReferenceId" @keyup.enter="copyChatRef" @keyup.space.prevent="copyChatRef">{{ chatRefCopied ? t.copied : `#${chatReferenceId}` }}</span>
    </div>
  </div>
</template>

<script>
import TrashIcon from '@/assets/trashIcon.svg'
import ExpandIcon from '@/assets/expand.svg'
import CloseIcon from '@/assets/close.svg'
import HashtagIcon from '@/assets/hashtag.svg'
import { langMixin } from '@/components/lang.js'
import { chatSizeKeys } from './constants.js'

export default {
  name: 'ChatHeader',
  components: { TrashIcon, ExpandIcon, CloseIcon, HashtagIcon },
  mixins: [langMixin('CHAT_COMPONENT_TRANSLATIONS')],
  props: {
    disableClearChat: { type: Boolean, default: false },
    chatReferenceId: { type: String, default: '' },
    headerIcon: { type: String, default: '' },
    showChatSizeOptions: { type: Array, default: () => [] },
    chatSizes: { type: Object, default: () => ({}) },
    isDesktopScreen: { type: Boolean, default: true }
  },
  emits: ['change-chat-size', 'close-chat', 'reset-messages'],
  data() {
    return {
      showChatRef: false,
      chatRefCopied: false,
      displayChatSizeOptions: false
    }
  },
  computed: {
    chatSizeDisplayNames() {
      return {
        [chatSizeKeys.full]: this.t.fullSize,
        [chatSizeKeys.small]: this.t?.smallSize,
        [chatSizeKeys.original]: this.t?.originalSize
      }
    }
  },
  watch: {
    displayChatSizeOptions(newValue) {
      if (newValue) document.addEventListener('click', this.onClickOutsideChatSizeoptions)
      else document.removeEventListener('click', this.onClickOutsideChatSizeoptions)
    }
  },
  methods: {
    copyChatRef() {
      navigator.clipboard.writeText(this.chatReferenceId).then(() => {
        this.chatRefCopied = true
        setTimeout(() => this.chatRefCopied = false, 2500)
      }).catch(err => {
        console.error('Failed to copy chatRef,', err)
      })
    },
    onClickOutsideChatSizeoptions() {
      this.displayChatSizeOptions = false
    },
    onClickExpand() {
      this.displayChatSizeOptions = !this.displayChatSizeOptions
    },
    onChangeChatSize(size) {
      this.$emit('change-chat-size', size)
      this.displayChatSizeOptions = false
    },
    closeChat() {
      this.$emit('close-chat')
    },
    onClickResetMessages() {
      this.$emit('reset-messages')
    }
  }
}
</script>

<style>
.modalHeader {
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
  color: var(--chat-modal-header-text-color);
  background-color: var(--modal-bg-color);
}

.modalHeader.rtl {
  direction: rtl;
}

.modalHeader.ltr {
  direction: ltr;
}

.headerIcon {
  width: 120px;
  height: 30px;

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.modalHeaderIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
  text-align: center;

  > img {
    width: 8.875em;
    max-height: 2.438em;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
  }
}

.headerButtonsWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #F5F5F5;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.10);

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    width: 12px;
    height: 12px;
  }

  &.mobileButtonSize {
    button {
      width: 16px;
      height: 16px;
    }

    .chatRef {
      font-size: 14px;
    }  
  }

  .chatRef {
    font-size: 12px;
    cursor: pointer;
  }
}

.chatSizeMenuWrapper {
  position: relative;
}

.chatSizeMenu {
  position: absolute;
  top: 52px;
  z-index: 5000;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  background: #F5F5F5;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.10);

  padding: 1em;
  width: 120px;
  direction: var(--text-direction);
}

.chatSizeMenu.ltr {
  direction: ltr;
  right: 8px;
}

.chatSizeMenu.rtl {
  direction: rtl;
  left: 8px;
}

.sizeOptionList {
  margin: 0;
  list-style-type: none;
  padding: 0;
  text-align: start;

  :not(:first-child) {
    margin-top: 0.5em;
  }
}

.sizeOption {
  font-size: 0.75em;
  cursor: pointer;
  display: flex;
  gap: 0.5em;
}

.mainScreen {
  min-width: 20px;
  height: 15px;
  border-radius: 4px;
  position: relative;

  background-color: var(--chat-size-background);
  border: 1px solid var(--chat-size-border);
}

.originalScreen {
  width: 7px;
  height: 13px;
  top: 1px;
}

.smallScreen {
  width: 7px;
  height: 7px;
  bottom: 1px;
}

.fullScreen {
  width: 18px;
  height: 13px;
  top: 1px;
}

.commonScreen {
  background: #afafaf;
  position: absolute;
  border-radius: 2px;
}



</style>
