<template>
  <div class="seezLogin">
    <slot name="branding" />
    <div class="loginForm">
      <template v-if="!remaining">
        <h1>
          <slot name="title">{{ t['welcome'] }}</slot>
        </h1>
        <p>
          <slot name="subtitle">{{ t['please_login'] }}</slot>
        </p>
        <input :class="[errorMessage ? 'errorEmail' : '']" ref="email" v-model="email" type="email" name="email" autocomplete="email" data-test-id="login_email_field" :placeholder="[[t['email_address']]]" @keyup.enter="requestCode" />
      </template>
      <template v-if="remaining">
        <div class="otp">
          <a v-if="remaining" @click="endTimer"><LeftIcon /> {{ t['go_back'] }}</a>
          <h1>{{ t['please_check_email'] }}</h1>
          <p>{{ t['we_sent_to'] }} {{ email }}</p>
          <div class="inputOtpWrapper">
            <InputOTP :length="6" :error="errorMessage" @value="handleOtpValue" />
          </div>
          <time>{{ remaining }}</time>
          <p class="resend">
            {{ t['did_not_get_code'] }}<a @click="requestCode">{{ t['click_to_resend'] }}</a>
          </p>
        </div>
      </template>
      <button v-if="!remaining" :class="{ requested: remaining != null }" data-test-id="login_request_code_button" @click="requestCode">
        {{ t['get_otp'] }}
      </button>
      <button v-if="remaining" :disabled="!validCode" data-test-id="login_authenticate_button" @click="authenticate">{{ t['confirm'] }}</button>
      <template v-if="!remaining">
        <div class="socialWrapper">
          <h5>{{ t['sign_in_using'] }}</h5>
          <div>
            <button v-if="googleLogin" @click="onGoogleLogin" class="social" data-test-id="login_google">
              <GoogleIcon />
              {{ t['google'] }}
            </button>
            <button v-if="facebookLogin" @click="onFacebookLogin" class="social" data-test-id="login_facebook">
              <FacebookIcon />
              {{ t['facebook'] }}
            </button>
            <button v-if="microsoftLogin" @click="onMicrosoftLogin" class="social" data-test-id="login_microsoft">
              <MicrosoftIcon />
              {{ t['microsoft'] }}
            </button>
          </div>
        </div>
        <div class="checkboxWrapper">
          <label>
            <input v-model="acceptedConditions" :class="{ selectionRequired }" data-test-id="terms_of_use" type="checkbox" />
            <span>
              <slot name="terms">
                {{ t['i_agree'] }} <a href="/terms" target="_blank" rel="noreferrer"> {{ t['terms_conditions'] }}</a> & <a href="/privacy" target="_blank" rel="noreferrer">{{ t['privacy'] }}</a>
              </slot>
            </span>
          </label>
          <label>
            <input v-model="acceptedMarketingTerms" data-test-id="marketing_terms" type="checkbox" />
            <span>
              <slot name="marketing">
                {{ t['marketing_terms'] }}
              </slot>
            </span>
          </label>
        </div>
      </template>
      <p v-if="errorMessage" class="error" data-test-id="login_error_message">
        <slot name="error">
          {{ errorMessage }}
        </slot>
      </p>
    </div>
  </div>
</template>
<script>
import InputOTP from './InputCode.ce.vue'
import { isValidEmail } from '@/logic'
import { analyticsMixin } from '@/analytics.js'
import GoogleIcon from '@/assets/google.svg'
import FacebookIcon from '@/assets/facebook.svg'
import MicrosoftIcon from '@/assets/microsoft.svg'
import LeftIcon from '@/assets/left.svg'
import { langMixin } from '@/components/lang'

export default {
  name: 'SeezLogin',
  components: { InputOTP, LeftIcon, GoogleIcon, FacebookIcon, MicrosoftIcon },
  mixins: [langMixin('LOGIN_COMPONENT_TRANSLATIONS'), analyticsMixin],
  inheritAttrs: false,
  props: {
    closable: { type: [Boolean, String], default: true },
    position: { type: String, default: 'center' }
  },
  emits: ['close'],
  data() {
    return {
      expiration: null,
      remaining: null,
      interval: null,
      email: '',
      code: '',
      errorMessage: null,
      loading: false,
      acceptedConditions: false,
      acceptedMarketingTerms: false,
      selectionRequired: false
    }
  },
  computed: {
    googleLogin() {
      return !!import.meta.env.VITE_GOOGLE_CLIENT_ID
    },
    facebookLogin() {
      return !!import.meta.env.VITE_FACEBOOK_CLIENT_ID && !window.seezSdk.clientId
    },
    microsoftLogin() {
      return !!import.meta.env.VITE_MICROSOFT_CLIENT_ID
    },
    eula() {
      return this.t.accept_eula.replace('{tu}', `<a href="/terms" target="_blank" rel="noreferrer">${this.t.terms_use}</a>`).replace('{pp}', `<a href="/privacy" target="_blank" rel="noreferrer">${this.t.privacy_policy}</a>`)
    },
    validEmail() {
      return isValidEmail(this.email)
    },
    validCode() {
      return this.code != null && this.code.length === 6
    }
  },
  watch: {
    email() {
      this.errorMessage = null
      this.selectionRequired = false
    }
  },
  beforeUnmount() {
    this.endTimer()
  },
  mounted() {
    this.$nextTick(() => {
      this.track('asc_cta_interaction', { old_name: 'login_modal_open', element_text: 'login modal open', element_subtype: 'cta_button', element_type: 'button' })
    })
  },
  methods: {
    onGoogleLogin() {
      if (import.meta.env.VITE_GOOGLE_CLIENT_ID == null) return

      const isTermsSelected = this.checkAcceptedConditions()
      if (!isTermsSelected) return

      this.loading = true

      const params = {
        response_type: 'code',
        client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
        redirect_uri: import.meta.env.VITE_AUTH_URL + '/google',
        state: btoa(JSON.stringify({ url: window.location.href, marketing: this.acceptedMarketingTerms })),
        // login_hint: "jsmith@example.com"
        scope: ['email', 'profile', 'openid']
      }
      localStorage.setItem('social_login', 'google')
      window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?${this.objectToQueryString(params)}`
    },
    onFacebookLogin() {
      if (import.meta.env.VITE_FACEBOOK_CLIENT_ID == null) return

      const isTermsSelected = this.checkAcceptedConditions()
      if (!isTermsSelected) return

      this.loading = true

      const params = {
        client_id: import.meta.env.VITE_FACEBOOK_CLIENT_ID,
        redirect_uri: import.meta.env.VITE_AUTH_URL + '/facebook',
        state: btoa(JSON.stringify({ url: window.location.href, marketing: this.acceptedMarketingTerms })),
        scope: ['email']
      }
      localStorage.setItem('social_login', 'facebook')

      window.location.href = `https://www.facebook.com/v13.0/dialog/oauth?${this.objectToQueryString(params)}`
    },
    onMicrosoftLogin() {
      if (import.meta.env.VITE_MICROSOFT_CLIENT_ID == null) return

      const isTermsSelected = this.checkAcceptedConditions()
      if (!isTermsSelected) return

      const params = {
        response_type: 'code',
        client_id: import.meta.env.VITE_MICROSOFT_CLIENT_ID,
        redirect_uri: import.meta.env.VITE_AUTH_URL + '/microsoft',
        state: btoa(JSON.stringify({ url: window.location.href, marketing: this.acceptedMarketingTerms })),
        scope: ['email', 'profile', 'openid', 'user.read']
      }
      localStorage.setItem('social_login', 'microsoft')

      window.location.href = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${this.objectToQueryString(params)}`
    },
    objectToQueryString(obj) {
      const encoded_params = Object.keys(obj).map(k => `${k}=${Array.isArray(obj[k]) ? obj[k].join(' ') : encodeURIComponent(obj[k])}`)
      return encoded_params.join('&')
    },
    checkAcceptedConditions() {
      if (!this.acceptedConditions) {
        this.selectionRequired = true
        return false
      }
      return true
    },
    async requestCode() {
      if (this.loading) return

      if (!this.validEmail) {
        this.errorMessage = 'Ugyldig Email'
        return
      }

      const isTermsSelected = this.checkAcceptedConditions()

      if (!isTermsSelected) return

      this.errorMessage = null
      this.loading = true
      try {
        const { expiration } = await window.seezSdk.requestCode(this.email, this.language)
        this.endTimer()
        this.expiration = new Date(expiration)
        this.recalcRemaining()
        this.interval = setInterval(this.recalcRemaining, 1000)
      } catch (error) {
        console.error(error)
        this.errorMessage = 'Error requesting code'
      }
      this.track('asc_cta_interaction', { old_name: 'otp_open', element_text: 'otp open', element_subtype: 'cta_button', element_type: 'button' })
      this.loading = false
    },
    async authenticate() {
      if (this.loading) return
      if (!this.validEmail) {
        this.errorMessage = 'Invalid Email'
        return
      }
      if (!this.validCode) {
        this.track('asc_cta_interaction', { old_name: 'otp_error', element_text: 'otp error', element_subtype: 'cta_button', element_type: 'button' })
        this.errorMessage = 'Invalid Code'
        return
      }

      this.errorMessage = null
      this.loading = true
      try {
        await window.seezSdk.loginWithOTP(this.email, this.code, this.acceptedMarketingTerms)
        this.endTimer()
        this.trackLoginSuccess('login_otp')
        // this.close()
      } catch (error) {
        console.error(error)
        this.errorMessage = 'Invalid username or code'
        this.loading = false
      }
    },
    recalcRemaining() {
      if (this.expiration) {
        const remaining = Math.floor((this.expiration - new Date()) / 1000)
        if (remaining > 0) {
          const sec = remaining % 60
          const min = ((remaining - sec) / 60) % 60
          this.remaining = `${min.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}`
        } else {
          this.endTimer()
        }
      } else {
        this.remaining = null
      }
    },
    endTimer() {
      if (this.interval) clearInterval(this.interval)
      this.interval = null
      this.remaining = null
      this.expiration = null
    },
    close() {
      this.track('asc_cta_interaction', { old_name: 'login_modal_close', element_text: 'modal close', element_subtype: 'cta_button', element_type: 'button' })
      this.$emit('close')
    },
    handleOtpValue(value) {
      this.code = value
      this.authenticate()
      if (this.errorMessage) this.errorMessage = !this.errorMessage
    },
    trackLoginSuccess(type) {
      this.track('asc_cta_interaction', { old_name: 'login_success', element_text: 'login success', event_type: type })
    }
  }
}
</script>
<style>
.seezLogin {
  --highlight: #0068ff;
  --text-color: black;
  --background: white;
  --accented-background: color-mix(in oklab, var(--highlight) 5%, var(--background));
  --base-font: Biennale, Verdana, Geneva, Tahoma, sans-serif;
  --base-shadow: 0 0 0.25em rgba(0, 0, 0, 0.1);
  --border-color: #ccc;

  display: grid;
  grid-template-columns: auto auto;

  > .loginForm {
    background-color: var(--background);
    position: relative;
    z-index: 2;
    text-align: start;
    padding: 2rem 3rem;
    max-width: 26rem;

    @media screen and (max-width: 36rem) {
      padding: 1rem;
    }

    > input {
      width: calc(100% - 1rem);
      background-color: #f6f6f6;
      border-radius: 24px;
      padding: 1.025rem 0;
      border: none;
      padding-inline-start: 1rem;
      font-size: 1rem;
      font-family: var(--base-font);
    }

    > input.errorEmail {
      outline: 1px solid red;
    }

    > button {
      width: 100%;
      background-color: var(--highlight);
      border-radius: 24px;
      padding: 0.875em 0;
      color: white;
      font-size: 1rem;
      border: none;
      margin-block-start: 1rem;
      cursor: pointer;
      font-family: var(--base-font);
    }

    > .socialWrapper {
      > div {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        gap: 0.5rem;

        .social {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #e2e2e2;
          text-decoration: none;
          color: black;
          font-size: 0.8rem;
          font-weight: 700;
          border-radius: 24px;
          padding: 0.5rem 1.5rem;
          flex: 1 1 0%;
          background-color: white;
          cursor: pointer;
          font-family: var(--base-font);

          > svg {
            padding-inline-end: 0.4rem;
            height: 1.5em;
          }
        }
      }
    }

    > .checkboxWrapper {
      margin-block-start: 2rem;
      text-align: start;

      label {
        display: block;
        color: #757575;
        font-size: 0.8rem;
        margin: 0.5rem 0;

        input {
          margin-inline-end: 0.5rem;
          cursor: pointer;
          min-width: 0.8125rem;
        }

        input.selectionRequired {
          outline: 1px solid red;
          border: none;
        }

        span {
          > a {
            color: black;
            font-weight: 700;
          }
        }
      }
    }

    > .otp {
      margin: 2em 0;

      > a {
        background-color: white;
        border: none;
        font-weight: 700;
        font-size: 0.8rem;
        cursor: pointer;
        > svg {
          height: 1em;
          stroke: black;
          padding-inline-end: 0.5rem;
        }
      }

      h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 2rem;
        white-space: nowrap;
        margin-inline-start: 1rem;
        margin: 1rem 0 0.75rem 0rem;
        padding: 0;
      }

      p {
        font-weight: 400;
        font-size: 0.875rem;
        margin: 0;
        padding: 0;
      }

      .inputOtpWrapper {
        margin: 2rem 0;
      }

      time {
        color: #1a2634;
        font-weight: bold;
        display: block;
        margin: 0rem 1.3rem;
        text-align: center;
      }

      .resend {
        margin: 1rem 0 2rem 0;
        text-align: center;

        > a {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    .error {
      color: red;
      text-align: center;
      font-size: 12px;
    }
  }

  @media screen and (max-width: 60rem) {
    > slot[name='branding'] {
      display: none;
    }
  }
}
</style>
