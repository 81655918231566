<template>
  <article class="listingContainer" v-if="details">
    <section class="header">
      <h2>
        {{ details.model.family.brand.name }}
        {{ details.model.name }}
      </h2>
      <p>{{ subInfo }}</p>
      <button class="inspectionReport-mobile" v-if="details.inspectionReport?.url" @click="$emit('onInspectionReportView')">
        <DocumentIcon />
        {{ t.inspectionReport ?? 'Inspection Report' }}
      </button>
      <div class="favorite">
        <button class="compareIcon" id="compare-button" @click="handleCompare">
          <CompareIcon :class="{ on: isCompared }" />
        </button>
        <button class="favoriteIcon" @click="toggleFavorite">
          <FavoriteIcon :class="{ on: favorited }" />
        </button>
        <button class="inspectionReport" v-if="details.inspectionReport?.url" @click="$emit('onInspectionReportView')">
          <DocumentIcon />
          {{ t.inspectionReport ?? 'Inspection Report' }}
        </button>
      </div>
      <div :class="['wrapper-tag', ['reserved', 'reservedOnSeez'].includes(details?.state) ? '' : 'only']">
        <span v-if="['reserved', 'reservedOnSeez'].includes(details?.state)" class="reservation-tag">
          <LockIcon />
          {{ t.reservationTag }}
        </span>
        <span v-if="details?.isNoviceDrivable" class="neopatentati">
          <div class="icon" />
          {{ t.neopatentati }}
        </span>
      </div>
      <button v-if="details?.image360?.exteriorClosedDoors || details?.image360?.interiorPanorama" @click="view360Image" class="image360">
        <Image360Icon />
      </button>
    </section>
    <ImagesCarousel class="imagesCarousel" :images="details.images" />
    <aside class="actions">
      <section v-if="source === sourceBarchetti" class="purchase">
        <div class="price">
          <p>{{ t.choosePaymentMethod || 'Scegli la formula di pagamento' }}</p>
          <ul>
            <li v-for="p in prices" :key="p.type" :class="{ selected: selectedPrice === p }" @click="selectedPrice = p">{{ t.priceTypes?.[p.type] }}</li>
          </ul>
          <div class="selectedPrice" v-if="selectedPrice && !hasPromotion && !hasListPrice">
            <p>{{ formatNumber(selectedPrice.value, 2, selectedPrice.currency) }}</p>
          </div>
          <DiscountedSection v-else-if="selectedPrice && hasPromotion" :old-price="formatPrice(selectedPrice)" :new-price="formatPrice({ value: details.promotions?.[0].discounted_price, currency: details.currency })" :discounted-amount="formatPrice({ value: Math.abs(selectedPrice?.value - details.promotions?.[0].discounted_price), currency: details.currency })" />
          <DiscountedSection v-else-if="hasListPrice" :new-price="formatPrice(details.retailPrice)" :old-price="formatPrice(details.listPrice)" :discounted-amount="formatPrice({ value: Math.abs(details.listPrice.value - details.retailPrice.value), currency: details.currency })" />
        </div>
        <div class="buttons">
          <button class="buy" @click="$emit('buy')">{{ t.configureQuote || 'Configura Preventivo' }}</button>
          <div v-if="details?.reservationConfig?.enabledTestDriveReservations && details?.reservation?.isActive" class="subTitle">{{ t.carReserveTitle }}</div>
          <button v-else class="testDrive" @click="popupTestdriveForm">
            <CalendarIcon />
            {{ t.lookAtVehicle || 'Guarda il veicolo' }}
          </button>
        </div>
        <div class="financing" v-if="details.recommendedLoan">
          <p>{{ t.financingHypothesis || 'Ipotesi di finanziamento' }}</p>
          <div class="financingDetails">
            <p>{{ t.PaymentInInstallment2 || 'Rata a partire da' }}</p>
            <div class="financing-terms">
              <span class="emi">
                {{ details.recommendedLoan?.monthlyPayment && details.recommendedLoan?.paymentTerms ? `${formatNumber(details.recommendedLoan.monthlyPayment, 2, details.currency)} x ${details.recommendedLoan.paymentTerms} ${t.months ?? 't.months'}` : t.unavailable ?? 't.unavailable' }}
              </span>
            </div>
            <div class="financingTags">
              <div class="hasIcon">
                <span class="loanAmount">
                  {{ 'TAN' }}
                  <b>{{ details.recommendedLoan?.aopBeforeTax }}%</b>
                </span>
                <span class="apr">
                  {{ 'TAEG' }}
                  <b>{{ details.recommendedLoan?.apr }}%</b>
                </span>
                <TooltipComponent placement="bottomLeft" :message="details.recommendedLoan?.disclaimer">
                  <QuestionIcon class="help-icon" />
                </TooltipComponent>
              </div>
            </div>
            <div class="financing-terms">
              <div class="custom-calculator" @click="e => $emit('click-payment', { listing: { id: listing } })">{{ t.customCalculator2 || 'Calcola la tua rata senza impegno' }}</div>
            </div>
          </div>
        </div>
        <div :class="{ badge: true, promotion: hasPromotion, customBadge: !!details.customBadge }" v-if="details.customBadge || hasPromotion">{{ hasPromotion ? t.promotionBadge : details.customBadge ? details.customBadge : '' }}</div>
      </section>
      <section v-else class="purchase bcd">
        <div class="price">
          <p>{{ t.paymentMethod }}</p>
          <ul>
            <li v-for="p in prices" :key="p.type" :class="{ selected: selectedPrice === p }" @click="selectedPrice = p">{{ t.priceTypes?.[p.type] }}</li>
          </ul>
          <div class="selectedPrice" v-if="selectedPrice">
            <p class="fullPrice" v-if="selectedPrice.type === 'retail'">{{ t.fullPrice }}</p>
            <p>{{ formatPrice(selectedPrice) }}</p>
          </div>
        </div>
        <div class="buttons">
          <button class="buy" @click="$emit('buy')">{{ t.buyNow }}</button>
          <button class="contact" @click="popupLeadForm"><EmailIcon />{{ t.contactUs }}</button>
          <button :disabled="stringToBoolean(disableTestDriveButton)" :class="['testDrive', { testDriveButtonDisabled: stringToBoolean(disableTestDriveButton) }]" @click="popupTestdriveForm"><DrivingWheelIcon />{{ t.testDrive }}</button>
        </div>
        <div class="financing" v-if="details.emiPrice?.value">
          <p>{{ t.financing }}</p>
          <div class="financingDetails">
            <p>{{ t.installmentsFrom }}</p>
            <span class="emi">{{ emiByMonths }}</span>
            <div class="financingTags">
              <span class="loanAmount">
                {{ t.loanAmount }}
                <b>{{ formatPrice({ value: details.emiPrice.emi.loanAmount, currency: selectedPrice.currency }) }}</b>
              </span>
              <span class="apr">
                APR
                <b>{{ formatNumber(details.emiPrice.emi.apr, 2) }}%</b>
              </span>
            </div>
            <span class="provider">
              {{ t.financingProvidedBy }}
              <img :src="financingLogo" alt="Provider Logo" loading="lazy" />
            </span>
          </div>
        </div>
      </section>
      <section class="contactUs">
        <p>{{ t.needClarification }}</p>
        <slot name="contactSubtitle" />
        <button class="contact" @click="popupLeadForm"><EmailIcon />{{ t.contactUs }}</button>
      </section>
    </aside>
    <slot name="promoBarchetti" />
    <section class="mainDetails">
      <h3>{{ t.vehicleDetails }}</h3>
      <ul v-if="source !== sourceAam">
        <li id="kilometrage" v-if="hasValue(details.kilometrage) != null">
          <RoadIcon />
          <span>{{ t.kilometrage }}</span>
          <span>{{ formatSpec(details.kilometrage, 'Km') }}</span>
        </li>
        <li id="fuelType" v-if="hasValue(details.fuelType?.name)">
          <FuelIcon />
          <span>{{ t.fuel_type }}</span>
          <span>{{ languageResources?.Common?.FuelTypes?.[details.fuelType?.name] || details.fuelType?.name }}</span>
        </li>
        <li id="engineSize" v-if="hasValue(details.engineSize)">
          <EngineIcon />
          <span>{{ t.engineCapacity }}</span>
          <span>{{ t.litres?.replace('{l}', formatNumber(details.engineSize)) }}</span>
        </li>
        <li id="transmission" v-if="hasValue(details.transmission?.name)">
          <GearBoxIcon />
          <span>{{ t.transmission }}</span>
          <span>{{ languageResources?.Common?.Transmissions?.[details.transmission?.name] || details.transmission?.name }}</span>
        </li>
        <li id="topSpeed" v-if="hasValue(details.topSpeed)">
          <MileageIcon />
          <span>{{ t.top_speed }}</span>
          <span>{{ formatSpec(details.topSpeed, 'km/t') }}</span>
        </li>
        <li id="horsePower" v-if="hasValue(details.hp)">
          <PowerIcon />
          <span>{{ t.horsepower }}</span>
          <span>{{ details.hp }}</span>
        </li>
        <li id="carColor" v-if="hasValue(details.color)">
          <ColorIcon />
          <span>{{ t.color }}</span>
          <span>{{ languageResources?.Common?.Colors?.[details.color] || details.color }}</span>
        </li>

        <li id="warranty" v-if="hasValue(details.warranty)">
          <WarrantyIcon />
          <span>{{ t.warranty }}</span>
          <span>{{ details.warranty }}</span>
        </li>
        <li id="seatMaterial" v-if="hasValue(details.interiorMaterial)">
          <SeatIcon />
          <span>{{ t.seatMaterial }}</span>
          <span>{{ details.interiorMaterial }}</span>
        </li>
        <li id="interiorColor" v-if="hasValue(details.interiorColor)">
          <ColorBoardIcon />
          <span>{{ t.interiorColor }}</span>
          <span>{{ details.interiorColor }}</span>
        </li>
        <li id="referenceNumber" v-if="hasValue(details.externalId)">
          <HashIcon />
          <span>{{ t.referenceNo ?? 't.referenceNo' }}</span>
          <span>{{ details.externalId }}</span>
        </li>
      </ul>
      <ul v-if="source === sourceAam">
        <li id="kilometrage" v-if="hasValue(details.kilometrage) != null">
          <RoadIcon />
          <span>{{ t.kilometrage }}</span>
          <span>{{ formatSpec(details.kilometrage, 'Km') }}</span>
        </li>
        <li id="fuelType" v-if="hasValue(details.fuelType?.name)">
          <FuelIcon />
          <span>{{ t.fuel_type }}</span>
          <span>{{ details.fuelType?.name }}</span>
        </li>
        <li id="engineSize" v-if="hasValue(details.engineSize)">
          <EngineIcon />
          <span>{{ t.engineCapacity }}</span>
          <span>{{ t.litres?.replace('{l}', formatNumber(details.engineSize)) }}</span>
        </li>
        <li id="transmission" v-if="hasValue(details.transmission?.name)">
          <GearBoxIcon />
          <span>{{ t.transmission }}</span>
          <span>{{ details.transmission?.name }}</span>
        </li>
        <li id="bodyType" v-if="hasValue(details.bodyType?.name)">
          <CarLateralIcon />
          <span>{{ t.bodyType ?? 't.bodyType' }}</span>
          <span>{{ languageResources?.Common?.BodyTypes?.[details.bodyType?.name] || details.bodyType?.name }}</span>
        </li>
        <li id="carColor" v-if="hasValue(details.color)">
          <ColorIcon />
          <span>{{ t.color }}</span>
          <span>{{ details.fullColor ?? details.color }}</span>
        </li>
        <li id="interiorColor" v-if="hasValue(details.interiorColor)">
          <ColorBoardIcon />
          <span>{{ t.interiorColor }}</span>
          <span>{{ details.interiorColor }}</span>
        </li>
        <li id="seatMaterial" v-if="hasValue(details.interiorMaterial)">
          <SeatIcon />
          <span>{{ t.seatMaterial }}</span>
          <span>{{ details.interiorMaterial }}</span>
        </li>
        <li id="warranty" v-if="hasValue(details.warranty)">
          <WarrantyIcon />
          <span>{{ t.warranty }}</span>
          <span>{{ details.warranty }}</span>
        </li>
        <li id="referenceNumber">
          <HashIcon />
          <span>{{ t.referenceNo ?? 't.referenceNo' }}</span>
          <span>{{ details.referenceNumber }}</span>
        </li>
      </ul>
    </section>
    <Collapsable class="description" :title="t.description" :expanded="true" v-if="details.description">
      <ul v-if="source === sourceAam && descriptionList.length > 0" class="descriptionList">
        <li v-for="(item, index) in descriptionList" :key="index">{{ item }}</li>
      </ul>
      <span v-else>{{ details.description }}</span>
    </Collapsable>
    <Collapsable class="inspectionRegistration" :title="t.inspectionRegistration" :expanded="true">
      <SpecsGrid :values="inspectionRegistrationData" />
    </Collapsable>
    <Collapsable class="techSpecs" :title="t.technical_specs" :expanded="true" v-if="Object.keys(techSpec ?? {}).length > 0">
      <SpecsGrid :values="techSpec" />
    </Collapsable>
    <Collapsable class="dimensions" :title="t.dimension_capacity" :expanded="true" v-if="Object.keys(dimensions ?? {}).length > 0">
      <SpecsGrid :values="dimensions" />
    </Collapsable>
    <Collapsable class="environment" :title="t.economyEnvironment" :expanded="true" v-if="Object.keys(economy ?? {}).length > 0">
      <SpecsGrid :values="economy" />
    </Collapsable>
    <template v-for="name in ['standard', 'comfort', 'design', 'safety', 'optional', 'uncategorized']" :key="name">
      <Collapsable v-if="details?.equipment?.[name]?.length > 0" class="equipment" :title="t.EquipmentCategories?.[name]" :expanded="true">
        <ul class="equipmentList">
          <li v-for="e in details?.equipment?.[name]" :key="e">{{ e }}</li>
        </ul>
      </Collapsable>
    </template>
    <section class="calculator" id="calculator">
      <LoanCalculator :listing-id="details.id" @start-order="handleStartOrderFromCalculator" :currency="details.currency" :require-nationality="requireNationalityOnCalculator" :show-calculation-details="showCalculationDetails" />
    </section>
    <slot name="benefits" />
    <section class="similar">
      <h3>{{ t.similar }}</h3>
      <Carousel :to="to" :filters="relatedFilter" @loaded="relatedLoaded" @select="relatedClick" @compare="e => $emit('compare', e)" :compare-ids="compareIds" @click-payment="e => $emit('click-payment', e)">
        <template #financingTerms>
          <slot name="financingTerms" />
        </template>
      </Carousel>
    </section>
    <slot name="faq" />
    <span class="certified" v-if="details.certified">
      <div class="certified-badge" />
    </span>
  </article>
  <Loader v-if="loading" class="listingLoader" />
  <p v-if="error">{{ error }}</p>

  <Modal v-if="image360Modal" @close="view360Image" class="image360Modal">
    <iframe :src="image360Source" class="image360Frame" />
    <div class="controlBar">
      <span>360° View</span>
      <div class="separator" />
      <button @click="() => (image360Mode = 'outside')" :class="{ outside: true, isActive: image360Mode === 'outside' }" v-if="details?.image360?.exteriorClosedDoors">
        <CarOutsideIcon />
      </button>
      <button @click="() => (image360Mode = 'inside')" :class="{ inside: true, isActive: image360Mode === 'inside' }" v-if="details?.image360?.interiorPanorama">
        <CarInsideIcon />
      </button>
      <div class="separator" />
      <button @click="() => (image360Modal = false)" class="close">
        <CrossIcon />
      </button>
    </div>
  </Modal>
  <nav class="stickyActionBar">
    <button class="buyButton" data-test-id="buyButtonStickyNavBar" @click="$emit('buy')">{{ t.buyNow || 't.buyNow' }}</button>
    <button :disabled="stringToBoolean(disableTestDriveButton)" class="testDriveButton" data-test-id="testDriveButtonStickyNavBar" @click="popupTestdriveForm">
      <div class="testDriveIcon" />
    </button>
    <button class="leadButton" data-test-id="contactUsButtonStickyNavBar" @click="popupLeadForm">
      <div class="contactUsIcon" />
    </button>
  </nav>
</template>

<script>
import { analyticsMixin, analyticsEvents } from '@/analytics.js'
import SeezSdk from '@/sdk.js'
import { langMixin } from '../lang'
import Loader from '../Loader.ce.vue'
import ImagesCarousel from './ImagesCarousel.ce.vue'
import Collapsable from './Collapsable.ce.vue'
import SpecsGrid from './SpecsGrid.ce.vue'
import LoanCalculator from '../FinancingProvider/LoanCalculator.ce.vue'
import Carousel from '../Carousel/Carousel2.ce.vue'
import MileageIcon from '@/assets/mileage.svg'
import FuelIcon from '@/assets/fueltype.svg'
import CrossIcon from '@/assets/cross.svg'
import EngineIcon from '@/assets/engine.svg'
import SeatIcon from '@/assets/interior2.svg'
import WarrantyIcon from '@/assets/warranty.svg'
import GearBoxIcon from '@/assets/gear.svg'
import CarLateralIcon from '@/assets/car-lateral.svg'
import ColorIcon from '@/assets/color.svg'
import ColorBoardIcon from '@/assets/colorBoard.svg'
import CarOutsideIcon from '@/assets/car.svg'
import CarInsideIcon from '@/assets/car-inside.svg'
import PowerIcon from '@/assets/power.svg'
import RoadIcon from '@/assets/road.svg'
import EmailIcon from '@/assets/envelope.svg'
import DrivingWheelIcon from '@/assets/drivingWheel.svg'
import CalendarIcon from '@/assets/calendar-bucket.svg'
import FavoriteIcon from '@/assets/favorite.svg'
import CompareIcon from '@/assets/compare.svg'
import LockIcon from '@/assets/lock.svg'
import DiscountedSection from './DiscountedSection.vue'
import QuestionIcon from '@/assets/question.svg'
import DocumentIcon from '@/assets/document.svg'
import Modal from '../Modal.ce.vue'
import Image360Icon from '@/assets/360.svg'
import HashIcon from '@/assets/hash.svg'
import TooltipComponent from '../Tooltip.ce.vue'

const sourceAam = 'aam'
const sourceBarchetti = 'barchetti'
export default {
  name: 'ListingDetails',
  components: {
    DocumentIcon,
    CrossIcon,
    CarOutsideIcon,
    CarInsideIcon,
    Loader,
    LockIcon,
    Modal,
    DiscountedSection,
    ImagesCarousel,
    MileageIcon,
    FuelIcon,
    EngineIcon,
    GearBoxIcon,
    CarLateralIcon,
    ColorIcon,
    PowerIcon,
    Collapsable,
    SpecsGrid,
    LoanCalculator,
    Carousel,
    FavoriteIcon,
    CompareIcon,
    RoadIcon,
    EmailIcon,
    DrivingWheelIcon,
    SeatIcon,
    WarrantyIcon,
    ColorBoardIcon,
    CalendarIcon,
    QuestionIcon,
    Image360Icon,
    HashIcon,
    TooltipComponent,
  },
  mixins: [langMixin('LISTING_DETAILS_COMPONENT_TRANSLATIONS'), SeezSdk.vueQueryMixin, SeezSdk.vueFavoritesMixin, analyticsMixin],
  props: {
    listing: { type: String, required: true },
    to: { type: String, default: null },
    target: { type: String, default: null },
    source: { type: String, default: '' },
    isShowLoadDetail: { type: Boolean, default: true },
    compareIds: { type: String, default: '' },
    redirectUrl: { type: String, default: '' },
    disableTestDriveButton: { type: Boolean, default: false },
    requireNationalityOnCalculator: { type: Boolean, default: false },
    showCalculationDetails: { type: Boolean, default: false }
  },
  emits: ['loaded', 'buy', 'relatedClick', 'contactClick', 'compare', 'click-payment', 'testDriveClick', 'on-toggle-favorite', 'onInspectionReportView'],
  data() {
    return {
      loading: false,
      details: null,
      error: null,
      user: null,
      relatedTotal: null,
      selectedPrice: null,
      sourceAam,
      sourceBarchetti,
      image360Modal: false,
      inspectionReport: false,
      image360Mode: 'outside'
    }
  },
  computed: {
    descriptionList() {
      const list = this.details.description.split(',').map(item => item.trim())
      return list ?? []
    },
    subInfo() {
      if (!this.details) return null
      return [this.details.year, this.details.variant].filter(detail => detail).join(' • ')
    },
    image360Source() {
      return this.image360Mode === 'outside' ? this.details?.image360?.exteriorClosedDoors : this.details?.image360?.interiorPanorama
    },
    hasListPrice() {
      return this.details.usage === 'km0' && this.details?.listPrice?.value && this.details?.retailPrice?.value
    },
    hasPromotion() {
      if (this.details?.promotions?.[0]?.discounted_price !== this.selectedPrice.value && this.details?.promotions?.[0]?.discounted_price && this.details?.promotions?.[0]?.start_date && this.details?.promotions?.[0]?.end_date) {
        const today = new Date()
        const startDate = new Date(this.details?.promotions[0]?.start_date)
        const endDate = new Date(this.details?.promotions[0]?.end_date)
        return today >= startDate && today <= endDate
      }
      return false
    },
    registrationDate() {
      if (this.details?.registrationDate == null) return null
      const d = new Date(this.details.registrationDate)
      return Intl.DateTimeFormat(this.language, { year: 'numeric', month: 'long' }).format(d)
    },
    serviceHistory() {
      if (this.details == null) return {}
      return {
        'Km at last service': '24,000',
        'Last service date': '01/01/2000',
        'Next service date': '01/01/20001'
      }
    },
    inspectionRegistrationData() {
      return {
        ...(this.hasValue(this.details.nextInspectionDate) && { [this.t.nextInspectionDate || 't.nextInspectionDate']: this.formatDate(this.details.nextInspectionDate) }),
        ...(this.hasValue(this.details.registrationDate) && { [this.t.registrationDate || 't.registrationDate']: this.formatDate(this.details.registrationDate) }),
        ...(this.hasValue(this.details.registrationType) && { [this.t.registrationType || 't.registrationType']: this.t[this.details.registrationType] ?? this.details.registrationType })
      }
    },
    techSpec() {
      if (this.details == null) return {}
      return {
        ...(this.hasValue(this.details.hp) && { [this.t.hk]: this.details.hp }),
        ...(this.hasValue(this.details.acceleration) && { [this.t.acceleration]: this.formatSpec(this.details.acceleration, 'sek') }),
        ...(this.hasValue(this.details.topSpeed) && { [this.t.top_speed]: this.formatSpec(this.details.topSpeed, 'km/t') }),
        ...(this.hasValue(this.details.numAirbags) && { [this.t.airbags]: this.formatSpec(this.details.numAirbags) }),
        ...(this.hasValue(this.details.numGears) && { [this.t.number_of_gears]: this.formatSpec(this.details.numGears) }),
        ...(this.hasValue(this.details.numCylinders) && { [this.t.cylinders]: this.formatSpec(this.details.numCylinders) }),
        ...(this.hasValue(this.details.driveType) && { [this.t.driveType]: this.t[this.details.driveType] ?? this.details.driveType })
      }
    },
    dimensions() {
      if (this.details == null) return {}
      return {
        ...(this.hasValue(this.details.weight) && { [this.t.weight]: this.formatSpec(this.details.weight, 'kg') }),
        ...(this.hasValue(this.details.maxAttachment) && { [this.t.max_attachment]: this.formatSpec(this.details.maxAttachment, 'kg') }),
        ...(this.hasValue(this.details.loadCapacity) && { [this.t.load_capacity]: this.formatSpec(this.details.loadCapacity, 'kg') }),
        ...(this.hasValue(this.details.numDoors) && { [this.t.number_of_doors]: this.formatSpec(this.details.numDoors) }),
        ...(this.hasValue(this.details.numSeats) && { [this.t.numberOfSeats]: this.formatSpec(this.details.numSeats) }),
        ...(this.hasValue(this.details.rimSize) && { [this.t.rimSize]: this.formatSpec(this.details.rimSize) })
      }
    },
    economy() {
      if (this.details == null) return {}
      return {
        ...(this.hasValue(this.details.safetyRating) && { [this.t.authority_safety_rating]: this.formatSpec(this.details.safetyRating, 'stjerner i EURO N-cap') }),
        ...(this.hasValue(this.details.noxEmission) && { [this.t.nox_emissions]: this.formatSpec(this.details.noxEmission, 'mg/km') }),
        ...(this.hasValue(this.details.co2Emission) && { [this.t.co2_emissions]: this.formatSpec(this.details.co2Emission, 'g/km') }),
        ...(this.hasValue(this.details.greenTax) && { [this.t.anual_green_tax]: this.formatPrice({ value: this.details.greenTax }, this.details.retailPrice?.currency) }),
        ...(this.hasValue(this.details.greenTax) && { [this.t.half_anual_green_tax]: this.formatPrice({ value: this.details.greenTax / 2 }, this.details.retailPrice?.currency) }),
        ...(this.hasValue(this.details.fuelConsumption) && { [this.t.fuel_consumption ?? 'this.t.fuel_consumption']: this.formatSpec(this.details.fuelConsumption, 'l/100km') })
      }
    },
    relatedFilter() {
      if (this.relatedTotal === 0) return `?models=${this.details?.model?.id}`
      return `?related-to=${this.listing}`
    },
    favorited() {
      return this.favorites.includes(parseInt(this.details.id))
    },
    financingLogo() {
      return `${import.meta.env.VITE_SEEZ_BASE_URL}${this.details.owner.financing.logo}`
    },
    prices() {
      const result = []
      if (this.details) {
        if (this.details.retailPrice?.value) result.push(this.details.retailPrice)
        if (this.details.leasePrice?.value) result.push(this.details.leasePrice)
        if (this.details.wholesaleLeasePrice?.value) result.push(this.details.wholesaleLeasePrice)
        if (this.details.wholesalePrice?.value) result.push(this.details.wholesalePrice)
      }
      return result
    },
    emiByMonths() {
      return this.t.emiTimesMonths?.replace('{emi}', this.formatPrice(this.details.emiPrice)).replace('{months}', this.details.emiPrice.emi.paymentTerms)
    },
    isCompared() {
      return this.compareIds?.includes(this.details?.id)
    }
  },
  watch: {
    prices(v) {
      if (this.selectedPrice == null && v.length > 0) this.selectedPrice = v[0]
    }
  },
  mounted() {
    this.loadDetails()
    window.seezSdk.getCurrentUser().then(r => {
      this.user = r
    })
  },
  methods: {
    stringToBoolean(str) {
      if (!str) return
      return str.toLowerCase() === 'true' ? true : false
    },
    handleStartOrderFromCalculator(calculationData) {
      this.$emit('buy', calculationData)
    },
    async downloadItem() {
      const response = await fetch(this.details.inspectionReport?.url)
      const blob = await response.blob()
      const newBlob = new Blob([blob])
      const objUrl = window.URL.createObjectURL(newBlob)
      const link = document.createElement('a')
      link.href = objUrl
      link.download = this.details.inspectionReport?.label
      link.click()
      window.URL.revokeObjectURL(objUrl)
    },

    toggleInspectionReportModal() {
      this.inspectionReport = !this.inspectionReport
    },

    handleCompare() {
      this.$emit('compare', this.details?.id)
    },
    hasValue(v) {
      return v != null && v !== '' && v !== 0
    },
    onClickBrochure() {
      window.print()
      this.track('asc_cta_interaction', { old_name: analyticsEvents.CTA_CLICK, element_text: 'download brochure', element_subtype: 'cta_button', element_type: 'button', name: 'Download Brochure' })
    },
    view360Image() {
      this.image360Modal = !this.image360Modal
    },
    async loadDetails() {
      this.loading = true
      this.error = null
      try {
        const query = `{ listing(id: ${this.listing}) {
            inspectionReport { url label }
            id name model { id name family { id name brand { id name }}} bodyType { name } targetSite { name logo brandingHighlightColor }
            variant year currency kilometrage owner { id name description logoUrl financing { logo }}
            images color hp transmission {name} fuelType { id name } registrationDate state reservedBy
            equipment { comfort design optional safety standard uncategorized } referenceNumber acceleration numAirbags numGears
            numCylinders weight loadCapacity maxAttachment numDoors fuelConsumption range description locatedAt { plainAddress }
            customBadge isNoviceDrivable
            fullColor image360 { exteriorClosedDoors interiorPanorama }
            listPrice { value currency }
            usage
            typeName leasePrice { value currency type lease { duration downPayment residualValue type audience }}
            wholesaleLeasePrice { value currency type lease { duration downPayment residualValue type audience }}
            retailPrice { value currency type disclaimer } wholesalePrice { value currency type }
            emiPrice { value currency type emi { loanAmount apr disclaimer paymentTerms }}
            videos greenTax batteryConsumption certified interiorMaterial interiorColor rimSize warranty engineSize numSeats
            promotions { title description priceB2c disclaimer discount discounted_price start_date end_date}
            reservation {id isActive reservedFor { id }} reservationConfig { requiresPaymentIdToReserve enabledTestDriveReservations }
            externalId
            referenceNumber
            driveType
            bodyType { id name }
            nextInspectionDate
            registrationDate
            registrationType
          }
        }`
        const result = await this.queryApi(query)
        this.details = result.listing
        this.selectedImage = this.details.images?.length > 0 ? `${import.meta.env.VITE_IMAGES_URL}/image/${800}x0/${this.details.images[0]}.webp` : `${import.meta.env.VITE_SEEZ_BASE_URL}/sdk/assets/car-placeholder.webp`
        this.$emit('loaded', this.details)
        const loanCalculationsQuery = `{
          loanCalculations(listingId: ${this.listing}) {
            interestType
            apr
            aopBeforeTax
            interestRate
            recommended
            monthlyPayment
            paymentTerms
            disclaimer
          }
        }`
        const loanCalculationsResult = await this.queryApi(loanCalculationsQuery)
        const recommendedLoan = loanCalculationsResult.loanCalculations.find(l => l.recommended)
        this.details.recommendedLoan = recommendedLoan
      } catch (error) {
        console.error(error)
        this.error = 'Error loading listing details'
      } finally {
        this.loading = false
      }
    },
    formatSpec(value, unit) {
      if (value == null) return '-'
      if (unit == null) unit = ''
      const stringValue = typeof value == 'number' ? this.formatNumber(value) : value
      return stringValue + ' ' + unit
    },
    formatDate(dateString) {
      if (dateString == null || !dateString) return null
      const date = new Date(dateString)
      return Intl.DateTimeFormat(this.language, { day: '2-digit', month: '2-digit', year: 'numeric' }).format(date)
    },
    popupLeadForm(e) {
      this.$emit('contactClick', { e, listing: this.listing, details: this.details })
      if (e.defaultPrevented) return
      return window.seezSdk.showModal('seez-sdk-lead-form', { listing: this.listing, source: this.source, lg: this.language })
    },
    popupTestdriveForm(e) {
      this.$emit('testDriveClick', { e, listing: this.listing, details: this.details })
      document.body.style.paddingRight = this.source === sourceAam ? '0.75rem' : 0
      return window.seezSdk.showModal('seez-sdk-test-drive-form', { 'listing-id': this.listing, source: this.source, lg: this.language, 'redirect-domain': this.redirectUrl })
    },
    relatedLoaded(e) {
      if (this.relatedTotal === null) this.relatedTotal = e.total
    },
    relatedClick(e, details) {
      this.$emit('relatedClick', e, details)
    },
    toggleFavorite() {
      const favourited = this.setFavorite(this.details.id)
      this.track('asc_cta_interaction', { old_name: analyticsEvents.FAVOURITE_CAR, element_text: 'toggle favorite', listingId: this.details.id, favourited, listing_id: parseInt(this.details.id) })
      this.$emit('on-toggle-favorite', { favourited, listing: this.details })
    }
  }
}
</script>

<style lang="scss">
@import '@/base.scss';

.stickyActionBar {
  position: sticky;
  bottom: 4.5rem;
  left: 0;
  right: 0;
  background-color: var(--background);
  z-index: 2;
  padding: 20px 1rem;
  display: grid;
  grid-template-columns: 7fr 1fr 1fr;
  align-items: center;
  gap: 6px;

  @media screen and (min-width: 50rem) {
    display: none;
  }

  button {
    cursor: pointer;
    border: none;
    font-weight: 700;

    &active {
      opacity: 0.7;
    }
  }

  .buyButton {
    margin: 0 2rem;
    padding: 1rem;
    background-color: var(--highlight);
  }

  .leadButton,
  .testDriveButton {
    padding: 1rem;

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
}

.listingContainer > * {
  max-width: 100%;
  overflow: hidden;
}

.listingContainer {
  @include theme;
  position: relative;
  background-color: var(--background);
  display: grid;
  gap: 2rem;

  .description > div > ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .description > div > ul > li {
    position: relative;
    padding-left: 20px;
  }

  .description > div > ul > li::before {
    content: '-';
    position: absolute;
    left: 0;
    color: black;
  }

  .inspectionReport-mobile {
    display: none;
  }

  .favorite {
    border: none;
    background: none;

    svg {
      height: 2rem;

      &.on {
        fill: var(--highlight);
      }
    }

    .inspectionReport {
      display: none;
    }

    .compareIcon {
      display: none;
      border: none;
      background: none;
    }

    .favoriteIcon {
      border: none;
      background: none;
    }
  }

  .compare {
    position: absolute;
    right: 80px;
  }

  .header {
    display: grid;
    grid-template:
      'title favorite' auto
      'subtitle favorite' auto / 1fr auto;

    gap: 1rem;

    .wrapper-tag {
      .reservation-tag {
        display: none;
      }
      .neopatentati {
        display: none;
      }
    }

    > h2,
    > p {
      grid-column: 1;
      margin: 0;
    }

    .image360 {
      display: none;
    }

    > .favorite {
      grid-area: favorite;
      place-self: center;
    }
  }

  .purchase {
    background-color: var(--highlight);
    color: var(--background);
    align-self: start;
    padding: 1rem;
    position: relative;

    > .price {
      > p {
        color: var(--background);
      }

      > ul {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        padding: 4px 0px;
        margin: 0;
        gap: 0.5rem;

        > li {
          border-radius: 0.25em;
          padding: 0.25em 1em;
          cursor: pointer;
          border: 1px solid var(--background);

          &.selected {
            background-color: var(--background);
            color: var(--highlight);
            cursor: default;
          }
        }
      }
      > .discountedSelectedPrice {
        background-color: #2a3032;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 1rem 1rem 0 1rem;
        .originalPrice {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .discountTag {
            padding: 0.5rem 0.75rem;
            border-radius: 52px;
            background-color: #c6c9ca;
            span {
              margin-left: 0.5rem;
              font-family: Raleway;
              color: #1b2021;
              font-weight: 700;
              font-size: 1rem;
            }
          }

          p {
            font-family: Raleway;
            font-weight: 600;
            font-size: 1.625rem;
            margin: 0;
            text-decoration: line-through;
            color: #acafb0;
          }
        }

        .newPrice {
          font-family: Raleway;
          font-weight: 700;
          font-size: 3.5rem;
          color: white;
          margin: 0;
          padding-bottom: 0.75rem;
        }
      }

      > .selectedPrice {
        background-color: var(--background);
        color: var(--highlight);
        font-size: 2rem;
        padding: 1rem;

        > p {
          margin: 0;
        }

        > .fullPrice {
          font-size: 1rem;
          color: var(--text-color);
        }
      }
    }

    > .buttons {
      background-color: var(--background);
      color: var(--highlight);
      display: grid;
      gap: 1rem;
      padding: 1rem;

      > button {
        font-size: 1em;
        padding-block: 0.5em;
        border: 1px solid var(--highlight);
        background: none;

        > svg {
          height: 1em;
          margin-inline-end: 0.75em;
        }
      }

      .testDriveButtonDisabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    > .financing {
      > .financingDetails {
        background-color: var(--background);
        color: var(--highlight);
        padding: 1rem;
        place-items: center;

        > p {
          color: var(--text-color);
          margin: 0;
          font-size: 1rem;
        }

        > .emi {
          font-size: 2rem;
          text-align: center;
        }

        > .financingTags {
          display: flex;
          justify-content: space-evenly;

          > span {
            white-space: nowrap;
            background-color: var(--accented-background);
            padding: 0.5em 1em;
          }

          .help-icon {
            width: 1.125rem;
            cursor: pointer;
          }

          .hasIcon {
            align-items: center;

            svg {
              margin-top: 4px;
            }
          }
        }

        > .provider {
          grid-column: 1 / -1;
          white-space: nowrap;
          display: flex;
          justify-content: space-between;
          align-items: center;

          > img {
            max-width: 50%;
            max-height: 2rem;
          }
        }
      }
    }

    .customBadge {
      background-color: #2f850b;
    }

    .promotion {
      background-color: #cc0605;
    }

    .badge {
      position: absolute;
      top: -1rem;
      right: 0;
      padding: 0.375rem 1.5rem;
      border-radius: 0 1.25rem 0 1.25rem;
      line-height: 1.25rem;
      font-weight: 600;
    }
  }

  .contactUs {
    margin-top: 1rem;
    background-color: var(--highlight);
    color: var(--background);
    align-self: start;
    padding: 1rem;

    > button {
      font-size: 1em;
      width: 100%;
      background: none;
      border: 1px solid var(--background);
      color: var(--background);
      padding-block: 0.5em;

      > svg {
        height: 1em;
        margin-inline-end: 0.75em;
        fill: var(--background);
      }
    }
  }

  .mainDetails {
    > ul {
      list-style-type: none;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
      gap: 1em;

      > li {
        display: grid;
        grid-template-columns: auto 1fr;
        background-color: var(--accented-background);
        align-items: center;
        gap: 0 1em;
        padding: 1em;

        > svg {
          height: 1.25em;
          grid-row-end: span 2;
          fill: var(--highlight);
        }

        > span:last-child {
          font-weight: bold;
        }
      }
    }
  }

  .equipmentList {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
    list-style-type: none;

    > li::before {
      content: '✓';
      margin-inline-end: 0.5em;
    }
  }

  .certified > svg {
    height: 2rem;
    margin: 1rem;
  }

  @media screen and (min-width: 60rem) {
    grid-template-columns: 2fr 1fr;

    > .header,
    > .imagesCarousel,
    > .calculator,
    > .similar {
      grid-column: 1 / -1;
    }

    .actions {
      grid-column: 2;
      grid-row-end: span 6;
    }

    > .mainDetails,
    > .description,
    > .serviceHistory,
    > .techSpecs,
    > .dimensions,
    > .environment,
    > .equipment {
      grid-column: 1;
    }

    > .imagesCarousel {
      grid-row: 2;
    }

    > .mainDetails {
      grid-row: 3;
    }

    > .certified {
      grid-row: 2;
      grid-column: 1 / -1;
      place-self: end start;
    }
  }
}

.listingLoader {
  min-height: 100vh;

  > svg {
    max-width: 8rem;
  }
}
</style>
