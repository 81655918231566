<template>
  <div class="filtersPanel">
    <h2 class="fixedHeading">
      <div>
        <span>{{ `${t['advanced_filters']} ${selectedFilterCount}` }}</span>
        <span v-if="isFilterSelected" class="clearFilters" @click="clearFilters">
          <TrashBinIcon />
          {{ t['clearFilters'] }}
        </span>
      </div>
      <button id="close-btn" aria-label="close button" class="closeBtn" @click="$emit('close')"><CloseIcon /></button>
    </h2>
    <div v-if="hasTagSelected" class="filterTags">
      <span v-for="tag in tags.filter(t => t.label)" :key="tag.id + '_' + tag.value" @click="removeFilter(tag)">
        {{ tag.label }}
        <i>&times;</i>
        <span class="closeBtn" />
      </span>
    </div>
    <div class="headingWrapper first">
      <SearchIcon />
      <h3>{{ t['search'] }}</h3>
    </div>
    <input v-model.trim="freeTextFilter" type="search" :placeholder="t['search_vehicles']" data-test-id="filter_freetext_field" @search="searchText" />

    <details open class="filtersAccordion category">
      <summary class="headingWrapper filtersAccordion--summary">
        <span>
          <DoorIcon />
          <h3>{{ t['category'] }}</h3>
        </span>
        <ChevronIcon />
      </summary>
      <div class="container">
        <div class="category-item">
          <input v-model="selectedFilters.isNoviceDrivable" id="neopatentati" type="checkbox" />
          <label for="neopatentati">{{ t.neopatentati }}</label>
        </div>
        <div class="category-item">
          <input id="promozione" type="checkbox" v-model="selectedFilters.hasPromotion" />
          <label for="promozione">{{ t.promozione }}</label>
        </div>
      </div>
    </details>

    <details open class="filtersAccordion">
      <summary class="headingWrapper filtersAccordion--summary">
        <span>
          <CarIcon />
          <h3>{{ t['make'] }} &amp; {{ t['model'] }}</h3>
        </span>
        <ChevronIcon />
      </summary>
      <multi-select v-model.number="selectedFilters.brands" :multi="false" :clear-placeholder="t['all_makes']" :options="options.brands" :placeholder="t['select_make']" test-id="car_make" />
      <multi-select v-if="selectedFilters.brands" :collapsible="true" v-model="selectedFilters.models" class="modelFamilySelect" :multi="true" :options="modelFamilyOptions" :placeholder="t['select_model']" test-id="car_model" />
      <multi-select v-if="selectedFilters.brands" v-model="selectedFilters.models" class="modelSelect" :multi="true" :options="modelOptions" :placeholder="t['select_model']" test-id="car_model" />
    </details>

    <details open class="filtersAccordion">
      <summary class="headingWrapper filtersAccordion--summary">
        <span>
          <BodyTypeIcon />
          <h3>{{ t['body_type'] }}</h3>
        </span>
        <ChevronIcon />
      </summary>
      <div class="bodies">
        <button v-for="(c, i) in options.bodyTypes" :key="c.id" :class="{ selected: selectedFilters.bodyTypes.includes(c.id), [c.name.toLowerCase()]: true }" :data-test-id="'filter_body_option_' + (i + 1)" @click="toggleBody(c.id)">
          <img :src="getBodyTypeAsset(c.name)" :alt="c.name" loading="lazy" />
          <span>{{ t[c.name] ?? c.name }}</span>
        </button>
      </div>
    </details>

    <details open class="filtersAccordion">
      <summary class="headingWrapper filtersAccordion--summary">
        <span>
          <PriceIcon />
          <h3>{{ t.price }}</h3>
        </span>
        <ChevronIcon />
      </summary>
      <div class="priceWrapper">
        <div class="purchaseOptions">
          <button id="retail-btn" @click="onClickPurchase(priceTypes.RETAIL, true)" :class="{ active: selectedRetail }">{{ t.purchase }}</button>
          <button id="lease-btn" @click="onClickPurchase(priceTypes.LEASING, true)" :class="{ active: selectedLease }">{{ t.lease }}</button>
        </div>
        <div v-if="selectedFilters.priceType" class="paymentTypes">
          <h4>{{ purchaseQuestion }}</h4>
          <div v-if="selectedRetail">
            <div class="paymentType">
              <button @click="onClickPurchase(priceTypes.RETAIL, false)" :class="{ active: selectedFilters.priceType !== priceTypes.FINANCING }" for="full-price">{{ t.fullPrice }}</button>
            </div>
            <div class="paymentType">
              <button :disabled="selectedFilters._isWholesale" @click="onClickPurchase(priceTypes.FINANCING, false)" :class="{ active: selectedFilters.priceType === priceTypes.FINANCING }" for="financing-price">{{ t.financing }}</button>
            </div>
          </div>
        </div>
        <div v-if="selectedFilters.priceType" class="prices">
          <div>
            <label for="minPriceInput">{{ t.minPrice }}</label>
            <input id="minPriceInput" v-model="minPriceString" :placeholder="t.from" pattern="\d*" type="text" test-id="min_budget" />
            <label v-if="priceError && priceError.type === 'priceMin'" class="error" for="minPriceInput">{{ priceError.message }}</label>
          </div>
          <div>
            <label for="maxPriceInput">{{ t.maxPrice }}</label>
            <input id="maxPriceInput" v-model="maxPriceString" :placeholder="t.to" pattern="\d*" type="text" test-id="max_budget" />
            <label v-if="priceError && priceError.type === 'priceMax'" class="error" for="minPriceInput">{{ priceError.message }}</label>
          </div>
        </div>
      </div>
    </details>

    <details open class="filtersAccordion">
      <summary class="headingWrapper filtersAccordion--summary">
        <span>
          <MileageIcon />
          <h3>{{ t['kilometrage'] }}</h3>
        </span>
        <ChevronIcon />
      </summary>
      <input v-model="kmMaxString" type="text" pattern="\d*" :placeholder="t['max_kilometrage']" data-test-id="milage" />
    </details>

    <details open class="filtersAccordion">
      <summary class="headingWrapper filtersAccordion--summary">
        <span>
          <CalendarIcon />
          <h3>{{ t['year'] }}</h3>
        </span>
        <ChevronIcon />
      </summary>
      <div class="yearWrapper">
        <div class="half">
          <label for="yearMinInput">{{ t.from }}</label>
          <multi-select v-model.number="selectedFilters.yearMin" :multi="false" :options="yearsFrom" :placeholder="t.from" test-id="year_from" />
        </div>
        <div class="half">
          <label for="yearMaxInput">{{ t.to }}</label>
          <multi-select v-model.number="selectedFilters.yearMax" :multi="false" :options="yearsTo" :placeholder="t.to" :clear-placeholder="t.clear" test-id="year_to" />
        </div>
      </div>
    </details>

    <details open class="filtersAccordion">
      <summary class="headingWrapper filtersAccordion--summary">
        <span>
          <DriveTypeIcon />
          <h3>{{ t['drive_type'] }}</h3>
        </span>
        <ChevronIcon />
      </summary>
      <multi-select v-model="selectedFilters.driveTypes" :multi="true" :options="localizedDrives" :placeholder="t.all" :clear-placeholder="t.clear" test-id="drive_type" />
    </details>

    <details open class="filtersAccordion">
      <summary class="headingWrapper filtersAccordion--summary">
        <span>
          <FuelTypeIcon />
          <h3>{{ t['fuel_type'] }}</h3>
        </span>
        <ChevronIcon />
      </summary>
      <multi-select v-model="selectedFilters.fuelTypes" :multi="true" :options="options.fuelTypes" :placeholder="t.all" :clear-placeholder="t.clear" test-id="fuel_type" />
    </details>

    <details open class="filtersAccordion">
      <summary class="headingWrapper filtersAccordion--summary">
        <span>
          <GearIcon />
          <h3>{{ t['transmission'] }}</h3>
        </span>
        <ChevronIcon />
      </summary>
      <multi-select v-model.number="selectedFilters.transmissions" :multi="false" :options="options.transmissions" :placeholder="t.all" :clear-placeholder="t.clear" test-id="transmission" />
    </details>

    <details open class="filtersAccordion">
      <summary class="headingWrapper filtersAccordion--summary">
        <span>
          <EngineIcon />
          <h3>{{ t['engine_size'] }}</h3>
        </span>
        <ChevronIcon />
      </summary>
      <multi-select v-model="selectedFilters.engineSizeMax" :multi="false" :options="options.engines" :placeholder="t.all" :clear-placeholder="t.clear" test-id="engine_size" />
    </details>

    <details open class="filtersAccordion">
      <summary class="headingWrapper filtersAccordion--summary">
        <span>
          <ColorIcon />
          <h3>{{ t['color'] }}</h3>
        </span>
        <ChevronIcon />
      </summary>
      <div class="colors">
        <label v-for="(c, i) in options.colors" :key="c.id">
          <button :style="colorBackground(c.color)" :class="{ selected: selectedFilters.colors.includes(c.id) || selectedFilters.colors.includes(c.da) }" :data-test-id="'filter_color_option_' + (i + 1)" :aria-label="c.name" @click="toggleColor(c.id)">&nbsp;</button>
          {{ t[c.id] }}
        </label>
      </div>
    </details>

    <details open class="filtersAccordion">
      <summary class="headingWrapper filtersAccordion--summary">
        <span>
          <DoorIcon />
          <h3>{{ t['number_of_doors'] }}</h3>
        </span>
        <ChevronIcon />
      </summary>
      <multi-select v-model.number="selectedFilters.doors" :multi="false" :options="options.doors" :placeholder="t.all" :clear-placeholder="t.clear" test-id="number_of_doors" />
    </details>

    <details open class="filtersAccordion numberSeat">
      <summary class="headingWrapper filtersAccordion--summary">
        <span>
          <DoorIcon />
          <h3>{{ t['number_seat'] }}</h3>
        </span>
        <ChevronIcon />
      </summary>
      <multi-select v-model.number="selectedFilters.numberSeat" :multi="false" :options="localizeSeat" :placeholder="t.all" :clear-placeholder="t.clear" test-id="number_of_doors" />
    </details>

    <div class="separator" />

    <button class="saveSearch" :disabled="Object.keys(modelValue).length === 0" test-id="save_search" @click="saveSearch">
      <HeartIcon />
      {{ t['save_this_search'] }}
    </button>
    <div class="searchFooter">
      <button @click="$emit('close')">{{ `${t['search_vehicles_btn']} ${totalResult}` }}</button>
    </div>
    <div v-if="showSaveSearchModal" class="modalSearchName">
      <dialog open>
        <i test-id="cancel_save" @click="cancelSaveSearch">&times;</i>
        <h1>{{ t['save_search'] }}</h1>
        <p>{{ t['save_filter_find_later'] }}</p>
        <input ref="searchNameInput" v-model="searchName" type="text" maxlength="100" name="name" :placeholder="t.searchName" test-id="save_name" />
        <button test-id="save_this_search" :disabled="searchName === ''" @click="confirmSaveSearch">{{ t['save_this_search'] }}</button>
        <Loader v-if="saving" />
      </dialog>
    </div>
  </div>
</template>

<script>
import TrashBinIcon from '@/assets/trash-bin.svg'
import SearchIcon from '@/assets/search.svg'
import CarIcon from '@/assets/car.svg'
import ChevronIcon from '@/assets/right.svg'
import CalendarIcon from '@/assets/calendar.svg'
import MileageIcon from '@/assets/mileage.svg'
import PriceIcon from '@/assets/price.svg'
import EngineIcon from '@/assets/engine.svg'
import BodyTypeIcon from '@/assets/bodytype.svg'
import GearIcon from '@/assets/gear.svg'
import FuelTypeIcon from '@/assets/fueltype.svg'
import DriveTypeIcon from '@/assets/drivetype.svg'
import ColorIcon from '@/assets/color.svg'
import DoorIcon from '@/assets/door.svg'
import HeartIcon from '@/assets/favorite.svg'
import CloseIcon from '@/assets/cross.svg'
import Loader from '../Loader.ce.vue'
import { debounce, formatFilterForApi } from '@/logic.js'
import SeezSdk from '@/sdk.js'
import MultiSelect from '../MultiSelect.ce.vue'
import { langMixin } from '../lang'

const priceTypes = {
  LEASING: 'lease',
  RETAIL: 'retail',
  FINANCING: 'emi',
  WHOLESALE: 'wholesale',
  WHOLESALE_LEASING: 'wholesaleLease'
}

Object.freeze(priceTypes)

export default {
  name: 'SeezFilterPanel',
  components: {
    Loader,
    MultiSelect,
    TrashBinIcon,
    SearchIcon,
    CarIcon,
    ChevronIcon,
    CalendarIcon,
    MileageIcon,
    PriceIcon,
    EngineIcon,
    BodyTypeIcon,
    GearIcon,
    FuelTypeIcon,
    DriveTypeIcon,
    ColorIcon,
    DoorIcon,
    HeartIcon,
    CloseIcon
  },
  mixins: [langMixin('FILTER_PANEL_COMPONENT_TRANSLATIONS'), SeezSdk.vueSavedSearchesMixin],
  props: {
    modelValue: { type: Object, required: true },
    options: { type: Object, required: true },
    tags: { type: Array, required: true },
    preload: { type: Number, default: null }, // Saved search id
    totalResult: { type: String, default: '' },
    saveSearchClicked: { type: Boolean, default: false }
  },
  emits: ['close', 'remove', 'update:modelValue', 'change'],
  data() {
    return {
      freeTextFilter: this.modelValue.freeText ?? '',
      selectedFilters: {
        _isWholesale: this.modelValue._isWholesale,
        freeText: this.modelValue.freeText ?? '',
        brands: this.modelValue.brands ?? null,
        models: [...(this.modelValue.models ?? []).map(m => `M${m}`), ...(this.modelValue.families ?? []).map(m => `F${m}`)],
        kilometrageMax: this.modelValue.kilometrageMax ?? null,
        priceType: this.modelValue.priceType,
        priceMin: this.modelValue.priceMin,
        priceMax: this.modelValue.priceMax,
        yearMin: this.modelValue.yearMin ?? null,
        yearMax: this.modelValue.yearMax ?? null,
        bodyTypes: this.modelValue.bodyTypes ?? [],
        fuelTypes: this.modelValue.fuelTypes ?? [],
        transmissions: this.modelValue.transmissions ?? null,
        colors: [],
        doors: this.modelValue.doors ?? null,
        driveTypes: this.modelValue.driveTypes ?? [],
        engineSizeMax: this.modelValue.engineSizeMax ?? null,
        numberSeat: this.modelValue.numberSeat ?? null
      },
      kmMaxString: this.modelValue?.kilometrageMax?.toString() ?? '',
      // TODO: CHECK IF THIS IS NEEDED
      maxPriceString: this.modelValue?.priceMax?.toString() ?? '',
      minPriceString: this.modelValue?.priceMin?.toString() ?? '',
      searchName: '',
      showSaveSearchModal: false,
      saving: false,
      selectedSavedSearch: null,
      priceTypes,
      priceError: null
    }
  },
  computed: {
    purchaseQuestion() {
      return this.selectedFilters.priceType === 'leasing' ? this.t.leasingBudget : this.t.howWillYouBuy
    },
    modelFamilyOptions() {
      const families = this.options.families.filter(x => x.brandid === this.selectedFilters.brands)
      return families.map(f => ({ id: `F${f.id}`, name: f.name, count: f.count, children: f.models.map(m => ({ id: `M${m.id}`, name: m.name, count: m.count })) }))
    },
    modelOptions() {
      const families = this.options.families.filter(x => x.brandid === this.selectedFilters.brands)
      return families.flatMap(f => f.models).map(m => ({ id: `M${m.id}`, name: m.name, count: m.count }))
    },
    yearsFrom() {
      if (this.selectedFilters.yearMax) return this.options.years.filter(y => y.id <= this.selectedFilters.yearMax)
      else return this.options.years
    },
    yearsTo() {
      if (this.selectedFilters.yearMin) return this.options.years.filter(y => y.id >= this.selectedFilters.yearMin)
      else return this.options.years
    },
    selectedFilterCount() {
      return this.tags.length > 0 ? `(${this.tags.length})` : ''
    },
    isFilterSelected() {
      return this.tags.length > 0
    },
    localizedDrives() {
      if (this.options == null || this.options.drives == null) return []
      return this.options.drives.map(d => ({ id: d.id, name: this.t[`drive_type_${d.id}`], count: d.count }))
    },
    localizeSeat() {
      return Array(6)
        .fill(true)
        .map((_, index) => ({ id: index + 2, name: `${index + 2} ${this.t.seat}` }))
    },
    hasTagSelected() {
      return this.tags.length > 0
    },
    selectedRetail() {
      return [priceTypes.RETAIL, priceTypes.FINANCING, priceTypes.WHOLESALE].includes(this.selectedFilters.priceType)
    },
    selectedLease() {
      return [priceTypes.LEASING, priceTypes.WHOLESALE_LEASING].includes(this.selectedFilters.priceType)
    }
  },
  watch: {
    saveSearchClicked(v) {
      if (v) {
        this.saveSearch()
      }
    },
    selectedFilters: {
      deep: true,
      handler(newValue) {
        const entries = Object.entries(newValue).filter(e => e[1] != null && e[1] !== '' && (!Array.isArray(e[1]) || e[1].length > 0))
        entries.sort((a, b) => (a[0] > b[0] ? 1 : -1))
        for (const pair of entries) {
          if (Array.isArray(pair[1])) {
            pair[1] = [...pair[1]]
            pair[1].sort()
          }
        }
        const cleanFilters = Object.fromEntries(entries)
        if (cleanFilters.freeText === '') delete cleanFilters.freeText
        if (newValue.models && newValue.models.length > 0) {
          cleanFilters.families = newValue.models.filter(m => m.startsWith('F')).map(f => parseInt(f.substring(1)))
          if (cleanFilters.families.length === 0) delete cleanFilters.families
          cleanFilters.models = newValue.models.filter(m => m.startsWith('M')).map(f => parseInt(f.substring(1)))
          if (cleanFilters.models.length === 0) delete cleanFilters.models
        }

        const oldWithoutPage = Object.fromEntries(
          Object.entries(this.modelValue)
            .filter(x => x[0] !== 'page')
            .sort((x, y) => (x[0] > y[0] ? 1 : -1))
        )
        const newIthoutPage = Object.fromEntries(
          Object.entries(cleanFilters)
            .filter(x => x[0] !== 'page')
            .sort((x, y) => (x[0] > y[0] ? 1 : -1))
        )

        if (JSON.stringify(oldWithoutPage) !== JSON.stringify(newIthoutPage)) delete cleanFilters.page

        if (JSON.stringify(this.modelValue) !== JSON.stringify(cleanFilters)) this.$emit('update:modelValue', cleanFilters)
      }
    },
    'selectedFilters.priceType'() {
      this.minPriceString = ''
      this.minPriceString = ''
    },
    'selectedFilters.kilometrageMax'(v) {
      this.kmMaxString = v != null && v > 0 ? v.toString() : ''
    },
    'selectedFilters.freeText'(v) {
      const cleanValue = v?.trim().replace(/\s{2,}/, ' ') ?? ''
      const oldValue = this.freeTextFilter?.trim().replace(/\s{2,}/, ' ') ?? ''
      if (cleanValue !== oldValue) this.freeTextFilter = cleanValue
    },
    'selectedFilters.brands'(v, o) {
      if (v !== o) {
        this.selectedFilters.models = []
        this.$emit('change', { models: undefined, families: undefined })
      }
    },
    modelValue: {
      deep: true,
      handler(newValue) {
        this.compileFilters(newValue)
      }
    },
    selectedSavedSearch(v) {
      if (v) {
        this.applySearch(parseInt(v))
        this.$nextTick(() => (this.selectedSavedSearch = null))
      }
    },
    kmMaxString: debounce(function (v) {
      try {
        let newValue = v == null || v === '' ? null : parseInt(v)
        if (newValue === 0) newValue = null
        if (this.selectedFilters.kilometrageMax != newValue) this.selectedFilters.kilometrageMax = newValue
      } catch {
        //ignore
      }
    }, 750),
    maxPriceString: debounce(function (v) {
      if (this.priceError) this.priceError = null
      if (!this.selectedFilters.priceType) return

      try {
        let newValue = v == null || v === '' ? null : parseInt(v)

        const vl = {
          isSamePrice: newValue === +this.selectedFilters.priceMin,
          isSmallerThanMinPrice: newValue && this.selectedFilters.priceMin && newValue < this.selectedFilters.priceMin
        }

        if (!newValue) this.priceError = null
        if (newValue === 0) newValue = null
        if (isNaN(+newValue)) return (this.priceError = { type: 'priceMax', message: this.t.mustBeNumber })
        if (vl.isSamePrice) return (this.selectedFilters.priceMax = newValue)
        if (vl.isSmallerThanMinPrice) return (this.priceError = { type: 'priceMax', message: this.t.mustBeGreaterThanMinPrice })

        this.selectedFilters.priceMax = newValue
      } catch {
        //ignore
      }
    }, 750),
    minPriceString: debounce(function (v) {
      if (this.priceError) this.priceError = null
      if (!this.selectedFilters.priceType) return

      try {
        let newValue = v == null || v === '' ? null : parseInt(v)

        const vl = {
          isSamePrice: newValue === +this.selectedFilters.priceMax,
          isGreaterThanMaxPrice: newValue && this.selectedFilters.priceMax && newValue > this.selectedFilters.priceMax
        }

        if (!newValue) this.priceError = null
        if (newValue === 0) newValue = null
        if (isNaN(+newValue)) return (this.priceError = { type: 'priceMin', message: this.t.mustBeNumber })
        if (vl.isSamePrice) return (this.selectedFilters.priceMin = newValue)
        if (vl.isGreaterThanMaxPrice) return (this.priceError = { type: 'priceMin', message: this.t.mustBeLessThanMaxPrice })

        this.selectedFilters.priceMin = newValue
      } catch {
        //ignore
      }
    }, 750),
    freeTextFilter: debounce(function (v) {
      this.selectedFilters.freeText = v
    }, 750)
  },
  mounted() {
    this.compileFilters(this.modelValue)
    if (this.preload) this.applySearch(this.preload)
  },
  methods: {
    onClickPurchase(type, toggle) {
      let realType = type
      if (this.selectedFilters._isWholesale && type === priceTypes.RETAIL) realType = priceTypes.WHOLESALE
      else if (this.selectedFilters._isWholesale && type === priceTypes.LEASING) realType = priceTypes.WHOLESALE_LEASING

      if (this.selectedFilters.priceType === realType && !toggle) return

      this.selectedFilters.priceType = this.selectedFilters.priceType === realType ? null : realType
      this.maxPriceString = ''
      this.minPriceString = ''
    },
    async searchText() {
      this.selectedFilters.freeText = this.freeTextFilter
    },
    clearFilters() {
      this.selectedFilters.freeText = ''
      this.selectedFilters.brands = []
      this.selectedFilters.models = []
      this.selectedFilters.yearMin = null
      this.selectedFilters.yearMax = null
      this.selectedFilters.priceType = null
      this.selectedFilters.priceMin = null
      this.selectedFilters.priceMax = null
      this.selectedFilters.kilometrageMin = null
      this.selectedFilters.kilometrageMax = null
      this.selectedFilters.bodyTypes = []
      this.selectedFilters.fuelTypes = []
      this.selectedFilters.transmissions = null
      this.selectedFilters.colors = []
      this.selectedFilters.driveTypes = []
      this.selectedFilters.doors = null
      this.selectedFilters.engineSizeMax = null
      this.selectedFilters.isNoviceDrivable = ''
      this.selectedFilters.hasPromotion = ''
      this.selectedFilters.numberSeat = null
    },
    toggleColor(color) {
      if (this.selectedFilters.colors.includes(color)) this.selectedFilters.colors = this.selectedFilters.colors.filter(c => c != color)
      else {
        const newList = [...this.selectedFilters.colors, color]
        newList.sort()
        this.selectedFilters.colors = newList
      }
    },
    colorBackground(color) {
      return {
        //background: `radial-gradient(circle at 25% 25%, white 8%, ${color} 58%, black 100%)` // old 3d style :D
        background: color
      }
    },
    toggleBody(body) {
      if (this.selectedFilters.bodyTypes.includes(body)) this.selectedFilters.bodyTypes = this.selectedFilters.bodyTypes.filter(c => c != body)
      else {
        const newList = [...this.selectedFilters.bodyTypes, body]
        newList.sort()
        this.selectedFilters.bodyTypes = newList
      }
    },
    compileFilters(v) {
      const defaults = {
        freeText: '',
        brands: null,
        models: null,
        kilometrageMin: null,
        kilometrageMax: null,
        yearMin: null,
        yearMax: null,
        bodyTypes: [],
        priceType: null,
        priceMin: null,
        priceMax: null,
        transmissions: null,
        fuelTypes: [],
        colors: [],
        doors: null,
        driveTypes: [],
        engineSizeMax: null,
        isNoviceDrivable: '',
        hasPromotion: '',
        numberSeat: null
      }
      const compiled = Object.assign({}, defaults, v, { models: [...(v.models ?? []).map(m => `M${m}`), ...(v.families ?? []).map(m => `F${m}`)] })
      delete compiled.families
      for (const key in compiled) {
        if (this.selectedFilters[key] != compiled[key] && JSON.stringify(this.selectedFilters[key]) != JSON.stringify(compiled[key])) {
          this.selectedFilters[key] = compiled[key]
        }
      }
      this.maxPriceString = this.selectedFilters.priceMax?.toString() ?? ''
      this.minPriceString = this.selectedFilters.priceMin?.toString() ?? ''
    },
    getBodyTypeAsset(name) {
      try {
        const cleanName = name
          .trim()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .replace(/\s+/g, '')
        return `${import.meta.env.VITE_IMAGES_URL}/body-types/${cleanName}.webp`
      } catch {
        return null
      }
    },
    saveSearch() {
      this.showSaveSearchModal = true
      this.searchName = ''
      this.$nextTick(() => this.$refs.searchNameInput.focus())
    },
    async confirmSaveSearch() {
      this.saving = true
      this.addSearch(this.searchName, formatFilterForApi(this.modelValue))
      this.saving = false
      this.showSaveSearchModal = false
    },
    cancelSaveSearch() {
      this.showSaveSearchModal = false
    },
    removeFilter(tag) {
      return this.$emit('remove', { id: tag.id, keys: tag.keys, label: tag.label, value: tag?.value })
    },
    applySearch(id) {
      const search = this.savedSearches.find(s => parseInt(s.id) === id)
      if (search == null) return
      const f = search.filterParameters
      this.selectedFilters.brands = f.brands?.length > 0 ? f.brands[0] : null
      this.selectedFilters.models = f.models ?? []
      this.selectedFilters.yearMin = f.yearMin
      this.selectedFilters.yearMax = f.yearMax
      this.selectedFilters.kilometrageMin = f.kilometrageMin
      this.selectedFilters.kilometrageMax = f.kilometrageMax
      this.selectedFilters.bodyTypes = f.bodyTypes ?? []
      this.selectedFilters.fuelTypes = f.fuelTypes ?? []
      this.selectedFilters.priceType = f.priceType
      this.selectedFilters.priceMin = f.priceMin
      this.selectedFilters.priceMax = f.priceMax
      this.selectedFilters.transmissions = f.transmissions?.length > 0 ? f.transmissions[0] : null
      this.selectedFilters.colors = f.colors ?? []
      this.selectedFilters.driveTypes = f.driveTypes ?? []
      this.selectedFilters.doors = f.numberOfDoorsMin
      this.selectedFilters.engineSizeMax = f.engineSizeMax
    }
  }
}
</script>

<style lang="scss">
@import '../../base';

.filtersPanel {
  color: #545454;
  border-inline-end: 1px solid rgba(0, 0, 0, 0.2);
  align-self: stretch;
  overflow: hidden auto;
  padding: 0 1em 10.5rem 1em;
  background-color: var(--background);
  z-index: 1;
  position: fixed;
  height: calc(100vh - 18.5rem);
  inset-inline: 0;

  @media (min-width: 47.938rem) {
    padding-block-end: 1rem;
    position: initial;
    height: auto;
  }

  > .fixedHeading {
    font-size: 1.563rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333333;
    background-color: var(--background);
    position: sticky;
    top: 0;
    z-index: 1;
    margin: 0 -1em;
    padding: 1.6rem 1em 0.2rem 1em;

    > div {
      display: flex;
      gap: 1rem;
      width: 100%;

      @media screen and (min-width: 47.938rem) {
        justify-content: space-between;
      }
    }

    > .closeBtn {
      background-color: transparent;
      margin-block-start: 0.5rem;
      border: none;
      display: none;
      cursor: pointer;

      > svg {
        height: 2rem;
      }

      @media screen and (max-width: 47.938rem) {
        display: block;
      }
    }

    @media screen and (max-width: 47.938rem) {
      padding: 0.5rem 1em;
      border-bottom: 1px solid #cbcbcb;
    }
  }

  .numberSeat,
  .category {
    display: none;
  }

  .category {
    .container {
      display: flex;
      flex-direction: column;
      gap: 14px;
    }
  }

  .category-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    input {
      width: unset;
    }
  }

  .headingWrapper {
    display: flex;
    align-items: center;
    padding-block-start: 1.063rem;
    margin-block-start: 1.25rem;

    h3 {
      font-size: 0.875rem;
      color: #000000;
      margin-inline-start: 0.8rem;
      font-weight: 700;
    }

    svg {
      height: 1rem;
    }
  }

  details > .yearWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;

    > .half {
      label {
        font-size: 0.8rem;
        padding-inline-start: 0.5rem;
      }

      .multiselect {
        margin-block-start: 0.5rem;
      }
    }
  }

  details > .priceWrapper {
    > .purchaseOptions {
      display: flex;
      width: 100%;
      gap: 10px;

      > .active {
        background: var(--accented-background);
        border: 1px solid var(--highlight);
      }

      > button {
        width: 50%;
        cursor: pointer;
        padding: 0.7rem;
        background-color: transparent;
        border: 1px solid #afafaf;
        border-radius: 24px;
        color: black;
      }
    }

    > .paymentTypes {
      margin-block-start: 1.125rem;

      h4 {
        font-size: 14px;
        font-weight: 600;
        color: black;
      }

      > div {
        display: flex;
        border-radius: 46px;
        border: 1px solid #cbcbcb;
        margin-block-end: 0.5rem;

        .paymentType {
          display: flex;
          width: 100%;

          input {
            margin: 0;
            padding: 0;
            display: none;
          }

          button {
            white-space: nowrap;
            font-size: 0.8rem;
            padding: 8px 0;
            border-radius: 46px;
            width: 100%;
            text-align: center;
            cursor: pointer;
            background-color: transparent;
            border: none;
            font-family: var(--base-font);
            color: var(--text-color);

            &[disabled] {
              cursor: not-allowed;
            }

            &.active {
              background: var(--highlight);
              color: var(--background);
              border: 1px solid var(--background);
            }
          }
        }
      }
    }

    > .prices {
      display: flex;
      padding: 0;
      gap: 12px;

      .error {
        color: red;
        font-size: 0.5rem;
        width: 20px;
      }

      > div {
        margin-block-start: 0.5rem;

        label {
          font-size: 0.8rem;
          padding-inline-start: 0.5rem;
        }

        input {
          margin-block-start: 0.813rem;
        }
      }
    }
  }

  .headingWrapper.first {
    margin-block-start: 0.75rem;
    padding-block-start: 0.25rem;
  }

  .headingWrapper:not(.first) {
    border-top: 1.2px solid #eeeeee;
  }

  > .separator {
    border-top: 1.2px solid #eeeeee;
    width: 100%;
    margin: 1.25rem 0;
  }

  > .filterTags {
    display: none;
    flex-wrap: wrap;
    padding: 0.5rem 0 0.5rem 0;
    margin: 0;
    overflow: hidden;
    gap: 1em;

    @media screen and (max-width: 42rem) {
      overflow-x: auto;
      flex-wrap: nowrap;
      display: flex;
    }

    > span {
      border: 1px solid var(--highlight);
      border-radius: 1em;
      padding: 0.25em 0.75em;
      color: var(--highlight);
      height: fit-content;
      font-size: 0.9em;
      white-space: nowrap;
      position: relative;

      > i {
        display: inline-block;
        margin-inline-start: 0.5em;
        font-style: normal;
      }
    }
  }

  input,
  select {
    width: 100%;
    box-sizing: border-box;
    padding: 0.75em 0.75em 0.75em 1.75em;
    background: var(--background);
    border: 1px solid #cbcbcb;
    border-radius: 2em;
    font-family: var(--base-font);
    color: #757575;
    font-size: 0.75em;

    @media screen and (max-width: 42rem) {
      font-size: 1em;
    }

    &:focus {
      outline: 1px solid var(--highlight);
    }

    &[value=''] {
      color: var(--highlight);
    }
  }

  details > .modelFamilySelect {
    margin-block-start: 1rem;
  }
  // Model only drop down. Default is hidden. This dropdown options do not hold family and it is not a toggleble option
  details > .modelSelect {
    margin-block-start: 1rem;
    display: none;
  }

  .asymmetric {
    display: flex;
    gap: 0.5em;
    align-items: stretch;

    > input {
      flex: 1 1;
      margin: 0;
    }

    > button {
      background-color: var(--highlight);
      border: 1px solid var(--highlight);
      border-radius: 0.25em;
      font-size: 1em;
      padding: 0.25em 0.4em 0.125em;

      > img {
        display: block;
        height: 1em;
      }
    }
  }

  details > .bodies {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.25em;
    margin: 0.5em 0;

    > button {
      font-size: x-small;
      border: 1px solid #cbcbcb;
      background-color: var(--background);
      border-radius: 0.5rem;
      padding: 1em 0 1em 0;
      cursor: pointer;
      font-family: var(--base-font);
      line-height: 2em;
      display: grid;
      grid-template-rows: 1fr auto;
      place-items: end center;
      height: 7em;
      color: black;

      > img {
        display: block;
        width: 4rem;
        height: auto;
        margin: 0 auto 0.3em;

        path {
          fill: red;
        }
      }

      > span {
        white-space: nowrap;
        text-decoration: none;

        > html {
          * {
            color: #000000;
          }
        }

        > i {
          line-height: 0.2;
          display: block;
        }

        .inline-block {
          display: inline-block;
        }
      }

      &.selected {
        border: 1px solid var(--highlight);
        background-color: #cce1ff80;
      }

      @media screen and (min-width: 47.938rem) {
        &:hover {
          border: 1px solid var(--highlight);
        }
      }
    }
  }

  details > .colors {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(2.5em, 1fr));
    justify-items: center;

    gap: 0.75em;
    margin: 0.5em 0;

    > label {
      font-size: 0.7em;
      text-align: center;
      white-space: nowrap;
      width: 3em;
      cursor: pointer;

      > button {
        border: 1px solid #cbcbcb;
        border-radius: 2em;
        width: 2.5em;
        height: 2.5em;
        margin: auto auto 0.4em auto;
        display: block;

        &.selected {
          outline: 3px solid var(--highlight);
          outline-offset: 2px;
          font-weight: bold;
        }
      }
    }
  }

  > .saveSearch {
    width: 100%;
    box-sizing: border-box;
    padding: 0.75em;
    margin: 0.5em 0;
    background: var(--highlight);
    border: 1px solid var(--highlight);
    color: var(--background);
    border-radius: 2em;
    font-family: var(--base-font);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;

    &:disabled {
      border-color: #cbcbcb;
      color: #cbcbcb;
      cursor: not-allowed;

      > svg {
        filter: saturate(0);
      }
    }

    > svg {
      vertical-align: middle;
      padding-inline-end: 0.5rem;
      height: 1em;

      > path {
        stroke: var(--background);
      }
    }
  }

  > .searchFooter {
    background-color: var(--background);
    box-shadow: 0px -6px 10px rgba(0, 0, 0, 0.1);
    position: fixed;
    inset-inline: 0;
    inset-block-end: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    display: none;

    @media screen and (max-width: 47.938rem) {
      display: flex;
    }

    > button {
      font-size: 0.75em;
      border: none;
      background: var(--highlight);
      color: var(--background);
      border-radius: 2em;
      padding: 0.5em;
      cursor: pointer;
      font-family: var(--base-font);
      white-space: nowrap;
      min-height: 3rem;
      min-width: 90%;
      font-size: 1rem;
      font-weight: 700;
    }
  }

  > .modalSearchName {
    position: fixed;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px) brightness(60%);
    z-index: 3;

    > dialog {
      text-align: center;
      position: relative;
      padding: 2em 3em;
      border: none;
      border-radius: 0.25em;
      background-color: var(--background);

      > i {
        cursor: pointer;
        font-size: 2em;
        position: absolute;
        inset-block-start: 0.5em;
        inset-inline-end: 0.5em;
        line-height: 0.5em;
        font-style: normal;
      }

      > h1 {
        margin: 0;
      }

      > p {
        margin: 0.5em auto;
        width: 30ch;
      }

      > input {
        border: none;
        outline: none;
        background-color: #f6f6f6;
        border-radius: 2em;
        font-size: 1rem;
      }

      > button {
        width: 100%;
        outline: none;
        box-sizing: border-box;
        padding: 0.75em;
        margin: 1em 0 0;
        background: var(--background);
        border: 1px solid var(--highlight);
        color: var(--highlight);
        border-radius: 2em;
        font-family: var(--base-font);

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      > .loader {
        position: absolute;
        inset: 0;
      }
    }
  }

  .clearFilters {
    font-size: 0.8rem;
    font-weight: 600;
    cursor: pointer;
    color: var(--highlight);
    font-style: normal;
    background-color: transparent;
    border-radius: 0.875rem;
    padding: 0.4rem 0rem;
    display: flex;
    align-items: center;
    margin-inline-end: 0;
    text-decoration: underline;

    > svg {
      padding-inline-end: 0.5rem;
      color: var(--highlight);
    }
  }

  > .multiselect.mySearches {
    > button {
      border: none;
      color: var(--highlight);
      max-width: 100%;
      box-sizing: border-box;
      background: transparent;
      outline: none;
      padding: 0;
      margin-block-end: 1em;

      > svg {
        position: initial;
        font-size: 2em;
        margin-inline-start: 0.25em;

        > path {
          fill: var(--highlight);
        }
      }
    }
  }

  details.filtersAccordion {
    > summary.filtersAccordion--summary {
      cursor: pointer;
      list-style: none;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &::marker,
      &::-webkit-details-marker {
        display: none;
      }

      > svg {
        width: 0.813rem;
        height: auto;
        color: currentColor;
        transform: rotate(-90deg);
        transition: transform 0.25s ease-in-out;
      }

      span {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.75rem;

        > svg {
          height: 1rem;
        }
      }
    }

    &.filtersAccordion[open] summary.filtersAccordion--summary > svg {
      transform: rotate(90deg);
    }

    &.filtersAccordion h3 {
      margin: 0;
    }

    &.filtersAccordion summary.filtersAccordion--summary {
      margin: 1em 0;
    }

    &.filtersAccordion:not([open]) summary.filtersAccordion--summary {
      margin-block-end: 0;
    }
  }
}
</style>
