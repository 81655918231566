<template>
  <div @click.stop="" v-if="animatePopup" :class="{ tooltipMessageContainer: true, [`${widgetPosition}Tooltip`]: true, [t.textDirection]: true, rightPopup: animatePopup && widgetPosition.endsWith('Right'), leftPopup: animatePopup && widgetPosition.endsWith('Left') }">
    <Tooltip>
      <div class="tooltipItemWrapper">
        <slot />
      </div>
    </Tooltip>
    <Tooltip v-if="!isDesktopScreen">
      <div class="tooltipFaqWrapper">
        <div v-for="(faq, index) in faqs" :key="index" class="tooltipFaq" @click.stop="$emit('select-faq', faq)">
          {{ faq }}
          <svg class="faq-icon" width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.60156 6.14844C7.95703 6.47656 7.95703 7.05078 7.60156 7.37891L2.35156 12.6289C2.02344 12.9844 1.44922 12.9844 1.12109 12.6289C0.765625 12.3008 0.765625 11.7266 1.12109 11.3984L5.74219 6.75L1.12109 2.12891C0.765625 1.80078 0.765625 1.22656 1.12109 0.898438C1.44922 0.542969 2.02344 0.542969 2.35156 0.898438L7.60156 6.14844Z" fill="black"/>
          </svg>
        </div>
      </div>
    </Tooltip>
  </div>
</template>

<script>
import Tooltip from './Tooltip.ce.vue'
import { langMixin } from '@/components/lang.js'

export default {
  name: 'PopupMessage',
  components: { Tooltip },
  mixins: [langMixin('CHAT_COMPONENT_TRANSLATIONS')],
  props: {
    showPopupMessage: { type: Boolean, default: false },
    isDesktopScreen: { type: Boolean, default: true },
    faqs: { type: Array, default: () => [] },
    widgetPosition: { type: String, default: '' }
  },
  emits: ['select-faq'],
  data() {
    return {
      animatePopup: false
    }
  },
  watch: {
    showPopupMessage(v) {
      if (v) {
        setTimeout(() => {
          this.animatePopup = true
        }, 200)
      } else {
        this.animatePopup = false
      }
    }
  },
  mounted() {
    if (this.showPopupMessage) {
      setTimeout(() => {
        if (this.showPopupMessage)
          this.animatePopup = true
      }, 500)
    }
  }
}
</script>

<style>
@keyframes popupFromRight {
  0% {
    transform: scale(0.1);
    right: -150%;
  }

  100% {
    bottom: 100%;
    transform: scale(1);
    right: 0%;
  }
}

@keyframes popupFromLeft {
  0% {
    transform: scale(0.1);
    left: -100%;
  }

  100% {
    bottom: 100%;
    transform: scale(1);
    left: 0%;
  }
}

.rightPopup {
  animation: popupFromRight 0.4s ease-out;
}

.leftPopup {
  animation: popupFromLeft 0.4s ease-out;
}

.tooltipMessageContainer {
  position: absolute;
  border-radius: 8px;
  color: var(--chat-modal-header-text-color);
  font-size: 12.8px;
  line-height: 1.2em;
  padding: 1.5em 0;
  width: 20em;
  z-index: 999;
  overflow: visible;
  direction: v-bind(textDirection);

  display: flex;
  flex-direction: column;
  gap: 0.5em;
  text-align: start;

  bottom: 100%;
  transform: scale(1);
  right: 0%;


  &.topRightTooltip {
    top: 100%;
    left: auto;
    bottom: auto;
    transform: translateY(0);
  }

  &.topLeftTooltip {
    top: 100%;
    left: 0;
  }

  &.middleLeftTooltip,
  &.bottomLeftTooltip {
    left: 0;
  }

  transition: bottom 5s ease-out
    right 5s ease-out
    visibility 0.3s ease-out
    scale 5s ease-out;

  &.showPopup {
    bottom: 100%;
    transform: scale(1);
    right: 0%;
    visibility: visible;
  }

  .tooltipFaqWrapper {
    width: 100%
  }

  .tooltipFaq {
    padding: 0.75em;
    font-size: 14px;
    border-bottom: 1px solid #0000001A;
    display: flex;
    justify-content: space-between;
    gap: 1em;
    align-items: center;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.14);
    }
  }

  .tooltipItemWrapper {
    padding: 0.75em;
    cursor: default;
    width: -webkit-fill-available;
  }

  &.ltr {
    direction: ltr;
  }

  &.rtl {
    direction: rtl;
  }
}
</style>
