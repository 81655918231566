// Mixin to mark a webcomponent as read-only
// To use it in a component, import it and add it to the `mixins` array in the web component definition.
// In addition to that add the data-sdk-read-only-mode on the parent element that should be read-only.
export const readOnlyModeMixin = {
  data() {
    return {
      readOnlyMode: false
    }
  },
  mounted() {
    this.readOnlyMode = this.getReadOnlyModeParent()
  },
  methods: {
    getReadOnlyModeParent() {
      let currentElement = this.$el

      while (currentElement) {
        let parent = currentElement.parentElement

        // If there's no direct parent, check for Shadow DOM host
        if (!parent) {
          const root = currentElement.getRootNode()
          if (root instanceof ShadowRoot) {
            parent = root.host
          }
        }

        if (parent) {
          // Check if the parent has the 'sdk-read-only-mode' attribute
          if (parent.hasAttribute('data-sdk-read-only-mode')) {
            return true
          }

          // Move up to the next parent element
          currentElement = parent
        } else {
          // No more parents to check
          currentElement = null
        }
      }

      // Attribute not found in any parent elements
      return false
    }
  }
}
