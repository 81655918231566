<template>
  <button class="contactButton" @click="onClick">{{ buttonLabel || t.contactUs }}</button>
</template>
<script>
import { langMixin } from '@/components/lang.js'
import { analyticsMixin } from '@/analytics.js'

export default {
  name: 'ContactButton',
  mixins: [langMixin('general'), analyticsMixin],
  props: {
    id: { type: String, default: '' },
    url: { type: String, default: '' },
    type: { type: String, default: '' },
    label: { type: String, default: '' }
  },
  computed: {
    buttonLabel() {
      return this.label
    }
  },
  methods: {
    rgbToHex(rgb) {
      const [r, g, b] = rgb.match(/\d+/g).map(Number)
      return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`
    },
    getEventAttributes(event){
      const rgb = window.getComputedStyle(event.target).color
      const color = this.rgbToHex(rgb)
      const order = Array.from(event.currentTarget.parentNode.children).indexOf(event.currentTarget)
      const details = {
        element_color: color, 
        element_order: order, 
        event_action: event.type
      }
      return details
    },
    trackClick(e) {
      const details = this.getEventAttributes(e)
      if (this.id && this.type) {
        this.track('asc_cta_interaction',  { ...details, old_name: 'chat_contact_button_click', element_text: 'click on contact', element_subtype: 'cta_button', element_type: 'button', id: this.id, type: this.type })
      }
    },
    onClick(e) {
      if (this.url) {
        const link = document.createElement('a')
        link.href = this.url
        link.rel = 'noopener noreferrer'
        link.click()
        this.trackClick(e)
      }
    }
  }
}
</script>
<style>
.contactButton {
  padding: 0.8em 1.5em;
  border-radius: 1.5em;
  border-radius: var(--cta-border-radius);
  cursor: pointer;
  font-size: 0.8em;
  font-weight: 700;
  background-color: var(--cta-bg-color);
  color: var(--cta-text-color);
  opacity: 0.75;
  border: none;
  display: block;
  font-family: var(--font-family), sans-serif;
}

.contactButton:hover {
  opacity: 0.5;
  transition: opacity 1s;
}
</style>
